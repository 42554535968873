import React from "react";
import { createUseStyles } from "react-jss";
import PhoneViewer from "components/PhoneViewer";
import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import FullImageRow from "components/FullImageRow";
import { useHistory } from "react-router-dom";

const familyDescription =
  "Bundle discounts are available on qualified wireless, internet, TV and web hosting packages in select service areas.";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      // maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.containedComponent}>
        <PhoneViewer
          {...props.data.products[0]}
          specs={props.data.products[0].description.specs}
          variations={[{ images: [props.data.products[0].image_src] }]}
        />
      </div>
      {/* <div className={classes.containedComponent}> */}
      <PriceGrid hasLocation={false} plans={props.data.products[0].plans} />
      {/* </div> */}
      {props.data.products["0"].Full_Image_Row !== null && (
        <FullImageRow
          title={props.data.products["0"].Full_Image_Row.Title}
          imageDesktop={
            props.data.products["0"].Full_Image_Row.Image_desktop.url
          }
          imageMobile={props.data.products["0"].Full_Image_Row.Image_mobile.url}
          altText={props.data.products["0"].Full_Image_Row.alt_text}
        />
      )}{" "}
      <BannerImage
        topText={props.data.content.Ads[0] + "test"}
        header={true}
        markdown={props.data.content.Ads[0].body}
        headerText={props.data.content.Ads[0].title}
        description={familyDescription}
        imageUrl={"/assets/images/family-on-devices.png"}
        ctaButton
        ctaButtonProps={{
          children: "Get yours now",
          onClick: function() {
            history.push("/bundle-and-save");
          }
        }}
        gradient
        leftAlign={true}
      />{" "}
    </div>
  );
}
export default View;
