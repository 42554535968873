import { useQuery } from "@apollo/react-hooks";
import Skeleton from "components/Spinner";
import { motion } from "framer-motion";
import gql from "graphql-tag";
import useLocation from "hooks/useLocation";
import React from "react";
import View from "./view";

const QUERY = gql`
  query businessWirelessMobilities {
    content: businessWirelessMobilities {
      mainBanner: Banner {
        body
        button_text
        image {
          src {
            url
          }
          image_title
          alt_text
        }
      }

      Full_Text_Row: Full_text_row {
        title
        body
      }

      Right_Image_Row: Right_image_row {
        title
        body
        images {
          image_title
          alt_text
          src {
            url
          }
        }
      }
      Left_Image_Row: Left_image_row {
        title
        body
        images {
          image_title
          alt_text
          src {
            url
          }
        }
      }

      Ads {
        title
        body
        button_text
        images {
          title: image_title
          src {
            url
          }
          alt_text
          type
        }
      }
    }
  }
`;

function GraphContainer() {
  const { location, setLocation } = useLocation();
  const locationFilter = { locations: { name: location } };
  const { loading, error, data } = useQuery(QUERY, {
    variables: { where: locationFilter }
  });

  // //console.log(loading, error, data, location);

  //console.log("GQL", data);

  const res = (
    <>
      <motion.div variants={loading || error || !data ? "closed" : "open"}>
        {loading || error || !data ? (
          <Skeleton />
        ) : (
          <View
            location={location || ""}
            setLocation={setLocation}
            data={data.content[0]}
          ></View>
        )}
      </motion.div>
    </>
  );

  //console.log(res);

  return res;
}

export default GraphContainer;
