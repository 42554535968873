import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import BannerImage from "components/BannerImage";
import FullTextRow from "components/FullTextRow";
import { useHistory } from "react-router-dom";
import getStrapiURL from "utils/getStrapiURL";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data } = props;
  const history=useHistory();
  return (
    <div className={classes.root}>
      <BannerImage
        topText={data.mainBanner[0]?.breadcrumb}
        title={data.mainBanner[0]?.title}
        markdown={data.mainBanner[0]?.body}
        imageUrl={getStrapiURL(data.mainBanner[0]?.image.src[0].url)}
        gradient
      />

      <FullTextRow>{data.Full_Text_Row[0]?.body}</FullTextRow>
    </div>
  );
}
export default View;
