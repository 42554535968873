import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import Markdown from "components/Markdown";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: "#000000",
      "& p": {
        textAlign: "center",
        opacity: "1 !important",
        font: "400 14px/19px Avenir",
        color: "#000",
        opacity: "1",
        color: "rgb(64,64,64)",
        width: "188px",
        margin: "0 auto"
      },
      "& strong": {
        opacity: "1 !important",
        textAlign: "center",
        color: "rgb(0,0,0) !important",
        font: "700 18px/25px Avenir",
        marginTop: "36px",
        marginBottom: "16px"
      },

      "& a": {
        textAlign: "center",

        font: "500 16px/22px Avenir",
        color: "#007AB1",
        marginTop: "16px"
      }
    },
    markdownRoot: {
      marginTop: 24,
      "& p, & a": {
        opacity: "1 !important",

        marginBottom: 8
      }
    },
    productCardGrid: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      minWidth: "100%",
      overflowX: "auto",
      "& > div:first-child": {
        marginLeft: "55px"
      }
    },
    productCard: {
      textAlign: "center",
      width: "220px",
      marginRight: "55px",
      cursor: "pointer"
    },
    productImage: {
      height: "100px"
    },
    productTitle: {
      font: "700 18px/25px Avenir",
      marginTop: "36px",
      marginBottom: "16px"
    },
    productDescription: {
      font: "400 14px/19px Avenir",
      color: "#000",
      opacity: "0.75",
      width: "188px",
      margin: "0 auto"
    },
    linkText: {
      font: "500 16px/22px Avenir",
      color: "#007AB1",
      marginTop: "16px"
    },
    "@media (max-width: 1199px)": {
      productCardGrid: {
        justifyContent: "left"
      }
    },
    "@media (max-width: 768px)": {
      productCardGrid: {
        justifyContent: "left"
      },
      productCard: {
        marginLeft: "55px"
      },
      productDescription: {
        display: "none"
      },
      linkText: {},
      markdownRoot: {
        display: "none"
      }
    }
  };
});

function ProductCard(props) {
  const classes = useStyles(props);
  const { cards, body } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTabletOrDesktop = useMediaQuery({ query: "(min-width: 769px)" });

  //console.log(props, "ProductCard");
  return (
    <div className={`${classes.productCardGrid} ${classes.root}`}>
      {cards.map((card, index) => {
        return (
          <div className={classes.productCard} onClick={card.onClick}>
            <div>
              <img
                src={card.productImageUrl}
                className={classes.productImage}
              // width={card.productWidth}
              // height={card.productHeight}
              ></img>
            </div>
            {card.body ? (
              <div className={classes.markdownRoot}>
                <Markdown>{card.body}</Markdown>
              </div>
            ) : (
                <>
                  <div className={classes.productTitle}>{card.productTitle}</div>
                  <div className={classes.productDescription}>
                    {card.productDescription}
                  </div>
                  <div className={classes.linkText}>{card.linkText}</div>
                </>
              )}
          </div>
        );
      })}
    </div>
  );
}

ProductCard.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      productImageUrl: PropTypes.string,
      productTitle: PropTypes.string,
      productDescription: PropTypes.string,
      description: PropTypes.string,
      linkText: PropTypes.bool,
      onClick: PropTypes.func,
      productWidth: PropTypes.string,
      productHeight: PropTypes.string
    })
  )
};
ProductCard.defaultProps = {
  cards: [
    {
      productImageUrl: "/assets/images/productCards/wireless.svg",
      productTitle: "Mobile",
      productDescription:
        "Mornington offers all types of phone plans and phones - find yours now.",
      linkText: "Explore Phones",
      onClick: function () {
        // alert("click");
      },
      productWidth: "58px",
      productHeight: "100px"
    },
    {
      productImageUrl: "/assets/images/productCards/internet.svg",
      productTitle: "Internet",
      productDescription:
        "Bringing you the latest technology, fastest speeds and highest quality.",
      linkText: "Find Your Plan",
      onClick: function () {
        // alert("click");
      },
      productWidth: "100px",
      productHeight: "67px"
    },
    {
      productImageUrl: "/assets/images/productCards/tv.svg",
      productTitle: "TV",
      productDescription:
        "Watch TV everywhere for free with your Mornington TV account name and number.",
      linkText: "Discover Your Channels",
      onClick: function () {
        // alert("click");
      },
      productWidth: "100px",
      productHeight: "77px"
    },
    {
      productImageUrl: "/assets/images/productCards/home-phone.svg",
      productTitle: "Home Phone",
      productDescription:
        "Fully-customizable home phone packages to suit all of your telephoning needs.",
      linkText: "Choose Your Package",
      onClick: function () {
        // alert("click");
      },
      productWidth: "100px",
      productHeight: "77px"
    },
    {
      productImageUrl: "/assets/images/productCards/home-security.svg",
      productTitle: "Home Security",
      productDescription:
        "Mornington provides a complete solution to all of your security needs.",
      linkText: "Secure Your Home",
      onClick: function () {
        // alert("click");
      },
      productWidth: "100px",
      productHeight: "67px"
    }
  ]
};
export default ProductCard;
