import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import ImageRows from "components/ImageRows";
import Article from "components/Article";

import getStrapiURL from "utils/getStrapiURL";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data } = props;

  const history = useHistory();
  let path = history.location.pathname;
  let urlTail = path.split("/").filter(function (item) {
    return item;
  });

  let urlHead = urlTail.reverse().shift();
  urlTail.reverse();
  urlTail = urlTail.join(" > ");

  //console.log("PLANS", data);

  return (
    <div className={classes.root}>
      <BannerImage
        topText={data.mainBanner[0].breadcrumb}
        title={data.mainBanner[0].title}
        markdown={data.mainBanner[0].body}
        imageUrl={getStrapiURL(data.mainBanner[0].image.src[0].url)}
        gradient
      />
      <PriceGrid
        titleText={data.Plan_Details[0].title}
        plans={data.Plan_Details[0].plans}
        location={location}
        onSelection={setLocation}
        hasDisclaimer={true}
        //ctaText={data.Plan_Details[0].button_text}
        //BASED ON REQUEST RECEIVED JUNE 2 2020
        ctaText={ data.Plan_Details[0].plans.length===0 ?
        data.Plan_Details[0].button_text:'Sign Up Now'}
        //Redirect to smarthub based on feedback
        onClick={()=>data.Plan_Details[0].plans.length===0 ?
        history.push("/contact-us/get-in-touch"): window.open("https://mornington.smarthub.coop/Shop.html", "_blank")}
        disclaimer={data.Plan_Details[0].note}
      />
      <ImageRows
        rows={[
          {
            imageLeft: true,
            imageSrc: getStrapiURL(
              data.Left_Image_Row[0] ?.images[0].src[0].url
            ),
            description: "",
            linkText: "",
            conditions: "",
            content: data.Left_Image_Row[0] ?.body
          }
        ]}
        title={"Learn more about wireless"}
      />
      <BannerImage
        topText={"Mornington Deals"}
        header={true}
        headerText={data.Ads[0].title}
        markdown={data.Ads[0].body}
        description={
          "Stay connected wherever you are with our Mobile Internet Devices and Flex Plans."
        }
        imageUrl={getStrapiURL(data.Ads[0].images[0].src[0].url)}
        ctaButton
        ctaButtonProps={{
          children: data.Ads[0].button_text,
          onClick: function () {
            history.push("/bundle-and-save");
            
          }
        }}
        gradient
        leftAlign={true}
      />
    </div>
  );
}
export default View;
