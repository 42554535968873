import React from "react";
import { createUseStyles } from "react-jss";
import { Button as BsButton, ThemeProvider } from "react-bootstrap";
const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      backgroundColor: ({ variant }) =>
        variant === "primary"
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
      color: ({ variant }) =>
        variant === "primary"
          ? theme.palette.primary.contrast
          : theme.palette.secondary.contrast,
      textAlign: "left",
      font: "400 1rem/22px Avenir",
      letterSpacing: "0",
      opacity: "1",
      // display: "inline-block",
      padding: 16,
      background: ({ variant }) =>
        `${
          theme.palette[variant]
            ? theme.palette[variant].main
            : theme.palette.primary.main
        } 0% 0% no-repeat border-box`,
      borderRadius: "4px",
      borderStyle: "none",
      userSelect: "none",
      transition: "0.3s",
      "&:hover": {
        backgroundColor: ({ variant }) =>
          `${
            theme.palette[variant]
              ? theme.palette[variant].dark
              : theme.palette.primary.dark
          }`
      },
      "&:focus, &:focus-within": {
        boxShadow: `0 0 0 0.3rem ${theme.palette.gray.darker}`,
        outline: "none"
      },
      "&:active": {
        backgroundColor: ({ variant }) =>
          `${
            theme.palette[variant]
              ? theme.palette[variant].darker
              : theme.palette.primary.darker
          }`
      }
    }
  };
});

function Button(props) {
  const classes = useStyles(props);
  return (
    <button {...props} className={classes.root}>
      {props.children}
    </button>
  );
}
export default Button;
