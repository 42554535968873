import React from "react";
import { createUseStyles } from "react-jss";
import Article from "components/Article";
import Container from "components/Container";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  return (
    <Container>
      <Article {...props} escapeHtml={false}>
        {props.children}
      </Article>
    </Container>
  );
}
export default View;
