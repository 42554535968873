import { useQuery } from "@apollo/react-hooks";
import Skeleton from "components/Spinner";
import { motion } from "framer-motion";
import gql from "graphql-tag";
import React from "react";
import View from "./view";

const GET_HOME = gql`
  query getHome {
    homepages {
      mainBanner: Banner {
        body
        button_text
        image {
          src {
            url
          }
          image_title
          alt_text
        }
      }
      Left_Image_Row {
        title
        body
        images {
          image_title
          alt_text
          src {
            url
          }
        }
      }

      Right_Image_Row {
        title
        body
        images {
          image_title
          alt_text
          src {
            url
          }
        }
      }

      Ads: ads {
        title
        body
        button_text
        images {
          image_title
          src {
            url
          }
          alt_text
          type
        }
      }
    }
  }
`;

function GraphContainer() {
  const { loading, error, data } = useQuery(GET_HOME);

  //console.log("GQL", data);

  const res = (
    <>
      <motion.div variants={loading || error || !data ? "closed" : "open"}>
        {loading || error || !data ? (
          <Skeleton />
        ) : (
          <View data={data.homepages[0]}></View>
        )}
      </motion.div>
    </>
  );

  //console.log(res);

  return res;
}

export default GraphContainer;
