import React from "react";
import { createUseStyles } from "react-jss";

import BannerImage from "components/BannerImage";
import ImageRows from "components/ImageRows";
import FullTextRow from "components/FullTextRow";

import getStrapiURL from "utils/getStrapiURL";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data } = props;

  // const filtered = data.plans.filter(
  //   ob => ob.name !== "Command Centre" && ob.name !== "Command Centre Plus"
  // );

  // const commandCentre = data.plans.filter(ob => ob.name === "Command Centre");

  // const commandCentrePlus = data.plans.filter(
  //   ob => ob.name === "Command Centre Plus"
  // );

  //console.log("PLANS", data);
  return (
    <div className={classes.root}>
      <BannerImage
        topText={data.mainBanner.breadcrumb}
        title={data.mainBanner.title}
        markdown={data.mainBanner.body}
        imageUrl={getStrapiURL(data.mainBanner.image.src[0].url)}
        gradient
      />
      {/* <div className={classes.containedComponent}>
        <PriceGrid
          titleText={data.Plan_Details[0].title}
          plans={data.Plan_Details[0].plans}
          location={location}
          onSelection={setLocation}
          hasDisclaimer={true}
          ctaText={data.Plan_Details[0].button_text}
          // TODO add disclaimer to strapi
          disclaimer={data.Plan_Details[0].note}
        />
      </div> */}

      <ImageRows
        rows={[
          {
            imageLeft: true,
            imageSrc: getStrapiURL(
              data.Left_Image_Row[0]?.images[0].src[0].url
            ),
            description:
              "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
            linkText: "",
            conditions: "",
            content: data.Left_Image_Row[0]?.body,
            onClick: function() {
              // alert("click");
            }
          }
        ]}
        title={data.Left_Image_Row[0]?.title}
      />

      <FullTextRow>{data.Full_Text_Row[0]?.body}</FullTextRow>

      <ImageRows
        rows={[
          {
            imageLeft: false,
            imageSrc: getStrapiURL(
              data.Right_Image_Row[0]?.images[0].src[0].url
            ),
            description:
              "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
            linkText: "",
            conditions: "",
            content: data.Right_Image_Row[0]?.body,
            onClick: function() {
              // alert("click");
            }
          }
        ]}
        title={data.Right_Image_Row[0]?.title}
      />

      <FullTextRow>{data.Full_Text_Row[1]?.body}</FullTextRow>
      <BannerImage
        topText={"Mornington eDeals"}
        header={true}
        headerText={data.Ads.title}
        markdown={data.Ads.body}
        description={
          "Stay connected wherever you are with our Mobile Internet Devices and Flex Plans."
        }
        imageUrl={getStrapiURL(data.Ads.images[0].src[0].url)}
        ctaButton
        ctaButtonProps={{
          children: data.Ads.button_text,
          onClick: function() {
            // alert("click");
          }
        }}
        gradient
        leftAlign={true}
      />
    </div>
  );
}
export default View;
