import React, { useReducer, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import PaginationGridButtonBar from "./PaginationGridButtonBar";
import useIEGridStyles from "./useIEGridStyles";
import { useMediaQuery } from "react-responsive";
import _ from "underscore";

import Skeleton from "react-loading-skeleton";

/**
 * File level constants
 */
const MAX_ITEMS_PER_ROW = 4;

const actionTypes = {
  DECREASE: "DECREASE",
  INCREASE: "INCREASE",
  SET_INDEX: "SET_INDEX",
  SET_DATA: "SET_DATA",
  ADD_FILTER: "ADD_FILTER",
  REMOVE_FILTER: "REMOVE_FILTER"
};

const sortTypes = {
  AZ(data) {
    const nextData = _.clone(data);
    nextData.sort(function(a, b) {
      if (a.phoneModel > b.phoneModel) {
        return 1;
      }
      if (b.phoneModel > a.phoneModel) {
        return -1;
      }
      return 0;
    });
    return nextData;
  },
  ZA(data) {
    const nextData = _.clone(data);
    nextData.sort(function(a, b) {
      if (a.phoneModel > b.phoneModel) {
        return -1;
      }
      if (b.phoneModel > a.phoneModel) {
        return 1;
      }
      return 0;
    });
    return nextData;
  },
  manufacturer(data, manufacturer) {},
  identity(data) {
    return data;
  }
};

/**
 * Stylesheet hook
 */
const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      overflow: "visible"
    },
    header: {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "0px 0px 20px 10px",
      flexWrap: "wrap"
    },
    headerTitle: {
      //Layout stylesheet
      margin: "0px auto 0px 0px",
      // UI Styles from mock
      textAlign: "left",
      font: "700 48px/56px Coast",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    headerButtons: { display: "flex" },
    grid: {
      display: "grid",
      gridTemplateColumns: ({ itemsPerRow }) =>
        `repeat(${Math.min(
          itemsPerRow,
          MAX_ITEMS_PER_ROW
        )}, minmax(260px, 1fr))`,
      "-ms-grid-columns": ({ itemsPerRow }) =>
        Array(Math.min(itemsPerRow, MAX_ITEMS_PER_ROW))
          .fill("1fr")
          .join(" "),
      gridTemplateRows: "auto",
      "-ms-template-rows": "auto",

      justifyItems: "center",
      gridRowGap: "24px",
      "-ms-grid-row-gap": 24,
      position: "relative"
    },
    pagination: {
      // Layout properties for pagination
      boxSizing: "border-box",
      padding: "24px 0px 0px 0px",
      alignItems: "center",
      display: "flex",
      "@media (max-width: 700px)": {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }
    },
    paginationLegend: {
      // Layout properties for pagination legend
      marginRight: "auto",
      //Ui properties for pagination legend
      textAlign: "left",
      font: "Medium 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "0.66",
      "@media (max-width: 700px)": {
        margin: 0
      }
    },

    paginationBar: {},
    "@media (max-width: 700px)": {
      headerButtons: { width: "100%" }
    }
  };
});

/**
 * Helper functions
 */
function getPageArray(pageSize, currIndex, data, centered, realIndex) {
  let newArr;
  if (centered === true && pageSize % 2) {
    const padding = (pageSize - 1) / 2;
    newArr = data
      .concat()
      .slice(
        Math.max(realIndex - padding, 0),
        Math.min(realIndex - padding + 2 * padding + 1, data.length)
      );
  } else {
    newArr = data
      .concat()
      .slice(currIndex * pageSize, currIndex * pageSize + pageSize);
  }
  return newArr;
}

/**
 *   currIndex: startIndex,
    currentPage: getPageArray(pageSize, startIndex, data),
    data: data,
    filters: [],
    filteredData: data,
    paginationStart: startIndex <= 4,
    paginationEnd: startIndex >= Math.floor(data.length / pageSize) - 4,
    paginationWindow: getPaginationWindow(
      startIndex <= 4,
      startIndex >= Math.floor(data.length / pageSize) - 4,
      data,
      pageSize,
      startIndex
    ),
    pageSize,
    data
 * 
 */

function scrollToTop(scrollDuration) {
  var scrollStep =
      -(window.scrollY || document.documentElement.scrollTop) /
      (scrollDuration / 15),
    scrollInterval = setInterval(function() {
      if ((window.scrollY || document.documentElement.scrollTop) != 0) {
        window.scrollBy(0, scrollStep);
      } else clearInterval(scrollInterval);
    }, 15);
}

function reducer(state, action) {
  let n;
  switch (action.type) {
    case actionTypes.DECREASE: {
      const nextState = _.clone(state);
      nextState.currIndex = Math.max(nextState.currIndex - 1, 0);
      nextState.currentPage = getPageArray(
        nextState.pageSize,
        nextState.currIndex,
        nextState.filteredData
      );
      nextState.paginationStart = nextState.currIndex <= 3;
      nextState.paginationEnd =
        nextState.currIndex >=
        Math.floor(nextState.filteredData.length / nextState.pageSize) - 4;

      nextState.paginationWindow = getPaginationWindow(
        nextState.paginationStart,
        nextState.paginationEnd,
        nextState.filteredData,
        nextState.pageSize,
        nextState.currIndex
      );
      scrollToTop(200);

      return nextState;
    }
    case actionTypes.INCREASE: {
      const nextState = _.clone(state);
      nextState.currIndex = Math.min(
        nextState.currIndex + 1,
        Math.floor(nextState.filteredData.length / nextState.pageSize)
      );
      nextState.currentPage = getPageArray(
        nextState.pageSize,
        nextState.currIndex,
        nextState.filteredData
      );
      nextState.paginationStart = nextState.currIndex <= 3;
      nextState.paginationEnd =
        nextState.currIndex >=
        Math.floor(nextState.filteredData.length / nextState.pageSize) - 4;
      nextState.paginationWindow = getPaginationWindow(
        nextState.paginationStart,
        nextState.paginationEnd,
        nextState.filteredData,
        nextState.pageSize,
        nextState.currIndex
      );

      scrollToTop(200);

      return nextState;
    }
    case actionTypes.SET_INDEX: {
      let { newIndex } = action;
      const nextState = _.clone(state);
      nextState.currIndex = newIndex - 1;
      nextState.currentPage = getPageArray(
        nextState.pageSize,
        nextState.currIndex,
        nextState.filteredData
      );
      nextState.paginationStart = nextState.currIndex <= 3;
      nextState.paginationEnd =
        nextState.currIndex >=
        Math.floor(nextState.filteredData.length / nextState.pageSize) - 4;
      nextState.paginationWindow = getPaginationWindow(
        nextState.paginationStart,
        nextState.paginationEnd,
        nextState.filteredData,
        nextState.pageSize,
        nextState.currIndex
      );

      scrollToTop(200);

      return nextState;
    }
    case actionTypes.SET_DATA: {
      const { data } = action;
      const nextState = _.clone(state);
      nextState.currIndex = 0;
      nextState.data = data;
      nextState.filteredData = nextState.filters.reduce(function(acc, filter) {
        return (filter.filterFunction || sortTypes.identity)(acc);
      }, nextState.data);

      nextState.currentPage = getPageArray(
        nextState.pageSize,
        nextState.currIndex,
        nextState.filteredData
      );

      nextState.paginationStart = nextState.currIndex <= 4;
      nextState.paginationEnd =
        nextState.currIndex >=
        Math.floor(nextState.filteredData.length / nextState.pageSize) - 4;
      nextState.paginationWindow = getPaginationWindow(
        nextState.paginationStart,
        nextState.paginationEnd,
        nextState.filteredData,
        nextState.pageSize,
        nextState.currIndex
      );

      return nextState;
    }
    case actionTypes.ADD_FILTER: {
      let { filterName, filterFunction } = action;
      const nextState = _.clone(state);
      nextState.currIndex = 0;
      if (nextState.filters.some(a => a.filterName === filterName)) {
        return nextState;
      }

      nextState.filters.push({ filterName, filterFunction });
      nextState.filteredData = nextState.filters.reduce(function(acc, filter) {
        return (filter.filterFunction || sortTypes.identity)(acc);
      }, nextState.data);

      nextState.currentPage = getPageArray(
        nextState.pageSize,
        nextState.currIndex,
        nextState.filteredData
      );

      nextState.paginationStart = nextState.currIndex <= 4;
      nextState.paginationEnd =
        nextState.currIndex >=
        Math.floor(nextState.filteredData.length / nextState.pageSize) - 4;
      nextState.paginationWindow = getPaginationWindow(
        nextState.paginationStart,
        nextState.paginationEnd,
        nextState.filteredData,
        nextState.pageSize,
        nextState.currIndex
      );

      return nextState;
    }
    case actionTypes.REMOVE_FILTER: {
      let { filterName } = action;
      const nextState = _.clone(state);
      nextState.currIndex = 0;
      if (nextState.filters.every(a => a.filterName !== filterName)) {
        return nextState;
      }

      nextState.filters = nextState.filters.filter(
        a => a.filterName !== filterName
      );
      nextState.filteredData = nextState.filters.reduce(function(acc, filter) {
        return (filter.filterFunction || sortTypes.identity)(acc);
      }, nextState.data);

      nextState.currentPage = getPageArray(
        nextState.pageSize,
        nextState.currIndex,
        nextState.filteredData
      );

      nextState.paginationStart = nextState.currIndex <= 4;
      nextState.paginationEnd =
        nextState.currIndex >=
        Math.floor(nextState.filteredData.length / nextState.pageSize) - 4;
      nextState.paginationWindow = getPaginationWindow(
        nextState.paginationStart,
        nextState.paginationEnd,
        nextState.filteredData,
        nextState.pageSize,
        nextState.currIndex
      );

      return nextState;
    }
    default:
      return state;
  }
}

function getPaginationWindow(start, end, data, pageSize, currIndex) {
  if (start && end) {
    return _.range(1, Math.floor(data.length / pageSize) + 2);
  } else if (!start && end) {
    return _.range(
      Math.floor(data.length / pageSize) - 4,
      Math.floor(data.length / pageSize) + 2
    );
  } else if (start && !end) {
    return _.range(1, Math.min(Math.floor(data.length / pageSize) + 1, 5) + 1);
  } else {
    return _.range(currIndex, currIndex + 3);
  }
}

/**
 * Component
 */
function PaginationGrid(props) {
  const classes = useStyles(props);
  const ieGridClasses = useIEGridStyles(props);
  const {
    pageSize,
    startIndex,
    data,
    title,
    buttons,
    ButtonsComponent,
    pagination,
    Renderer,
    itemsPerRow
  } = props;

  const myInitialState = {
    currIndex: startIndex,
    currentPage: getPageArray(pageSize, startIndex, data),
    data: data,
    filters: [],
    filteredData: data,
    paginationStart: startIndex <= 4,
    paginationEnd: startIndex >= Math.floor(data.length / pageSize) - 4,
    paginationWindow: getPaginationWindow(
      startIndex <= 4,
      startIndex >= Math.floor(data.length / pageSize) - 4,
      data,
      pageSize,
      startIndex
    ),
    pageSize,
    data
  };
  const [state, dispatch] = useReducer(reducer, myInitialState);
  const {
    currIndex,
    currentPage,
    filteredData,
    paginationStart,
    paginationEnd,
    paginationWindow
  } = state;

  const maxIndex = Math.max(
    Math.floor(filteredData.length / state.pageSize),
    0
  );

  const ref = useRef(null);
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  return (
    <div className={classes.root}>
      {/* Header section of PaginationGrid */}
      <div className={classes.header}>
        <Skeleton count={1} />
      </div>
      {/* Content section Rendered with a custom Renderer */}
      <Skeleton width={"100%"} height={"1000px"} />
    </div>
  );
}

PaginationGrid.propTypes = {
  /**
   * Title for the pagination Grid
   */
  title: PropTypes.string,
  /**
   * Boolean Indicating that a buttons component will be present
   */
  buttons: PropTypes.bool,
  /**
   * Buttons Component to be rended in top right of pagination grid
   */
  ButtonsComponent: PropTypes.elementType,
  /**
   * Boolean Indicating that a pagination is enabled for the data
   */
  pagination: PropTypes.bool,
  /**
   * Indicates the number of items per row with a max of 4 items
   */
  itemsPerRow: PropTypes.number,
  /**
   * TODO: boolean indicating  lazy loaded data (async fetching)
   */
  lazyLoaded: PropTypes.bool,
  /**
   * TODO: Total expected data for lazy loaded implementation
   */
  lazyloadedTotal: PropTypes.number,
  /**
   * The number is items per page for pagination
   */
  pageSize: PropTypes.number,
  /**
   * Data to be rendered in the pagination view
   */
  data: PropTypes.array,
  /**
   * A React component that will render the data.
   * Each element of the array data will be passed as props to this component.
   */
  Renderer: PropTypes.elementType,
  /**
   * The start index for pagination (which page should we start at, 0-indexed variable).
   */

  startIndex: PropTypes.number
};

PaginationGrid.defaultProps = {
  title: "Our Phones",
  buttons: true,
  ButtonsComponent: function() {
    return <button>hello</button>;
  },
  pagination: true,
  itemsPerRow: 4,
  lazyLoaded: false,
  lazyloadedTotal: 200,
  pageSize: 8,
  data: Array(8).fill({ test: "" }),
  Renderer: function(props) {
    return (
      <pre
        style={{ width: 300, height: 300, backgroundColor: "red", margin: 10 }}
      >
        <code>{JSON.stringify(props)}</code>
      </pre>
    );
  },
  startIndex: 0
};

export { getPageArray };
export default PaginationGrid;
