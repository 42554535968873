import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Footer from "components/Footer";

import Header from "components/Header";
import Navbar from "components/Navbar";
import Productbar from "components/Productbar";
import getStrapiURL from "utils/getStrapiURL";

import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import ImageRows from "components/ImageRows";
import Article from "components/Article";
import usePage from "hooks/usePage";
import FormRow from "components/FormRow";
import { useHistory } from "react-router-dom";

function Home(props) {
  // const [classes, contentEl, footerEl] = usePage();
  const history=useHistory();
  const { data } = props;
  return (
    <>
      <div className={"classes.root"}>
        <BannerImage
          topText={data.mainBanner[0]?.breadcrumb}
          title={data.mainBanner[0]?.title}
          markdown={data.mainBanner[0]?.body}
          imageUrl={getStrapiURL(data.mainBanner[0].image.src[0].url)}
          variant={"tertiary"}
          gradient
        />
        {/* <div className={classes.containedComponent}>
        <PriceGrid
          titleText={data.Plan_Details[0].title}
          plans={data.Plan_Details[0].plans}
          location={location}
          onSelection={setLocation}
          hasDisclaimer={true}
          ctaText={data.Plan_Details[0].button_text}
          // TODO add disclaimer to strapi
          disclaimer={data.Plan_Details[0].note}
        />
      </div> */}

        <ImageRows
          rows={[
            {
              imageLeft: true,
              imageSrc: getStrapiURL(
                data.Left_Image_Row[0]?.images[0].src[0].url
              ),
              description:
                "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
              linkText: "",
              conditions: "",
              content: data.Left_Image_Row[0]?.body,
              onClick: function() {
                // alert("click");
              }
            }
          ]}
          title={data.Left_Image_Row[0]?.title}
        />

        <FormRow
          rows={[
            {
              imageLeft: false,
              imageSrc: getStrapiURL(
                data.Right_Image_Row[0]?.images[0].src[0].url
              ),
              description:
                "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
              linkText: "",
              conditions: "",
              content: data.Right_Image_Row[0]?.body,
              onClick: function() {
                ////  alert("click") ;
              }
            }
          ]}
          variant={"tertiary"}
          title={data.Left_Image_Row[1]?.title}
        />
        {/* <Article>{data.Full_Text_Row[0]?.body}</Article> */}

        {/* <ImageRows
          rows={[
            {
              imageLeft: false,
              imageSrc: getStrapiURL(
                data.Right_Image_Row[0]?.images[0].src[0].url
              ),
              description:
                "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
              linkText: "",
              conditions: "",
              content: data.Right_Image_Row[0]?.body,
              onClick: function() {
              ////  alert("click") ;
              }
            }
          ]}
          title={data.Right_Image_Row[0]?.title}
        /> */}

        {/* <Article>{data.Full_Text_Row[1]?.body}</Article> */}

        <BannerImage
          topText={"Mornington eDeals"}
          header={true}
          headerText={data.Ads[0].title}
          markdown={data.Ads[0].body}
          description={
            "Stay connected wherever you are with our Mobile Internet Devices and Flex Plans."
          }
          imageUrl={getStrapiURL(data.Ads[0].images[0].src[0].url)}
          ctaButton
          ctaButtonProps={{
            children: data.Ads[0].button_text,
            variant: "tertiary",
            onClick: function() {
              history.push("/business/shop/bundles/view-bundles")
              
            }
          }}
          gradient
          leftAlign={true}
        />
      </div>
    </>
  );
}

export default Home;
