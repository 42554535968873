import data from "data/data.json";

function lcs(x, y) {
  x = x.toLowerCase();
  y = y.toLowerCase();
  var s,
    i,
    j,
    m,
    n,
    lcs = [],
    row = [],
    c = [],
    left,
    diag,
    latch;
  //make sure shorter string is the column string
  if (m < n) {
    s = x;
    x = y;
    y = s;
  }
  m = x.length;
  n = y.length;
  //build the c-table
  for (j = 0; j < n; row[j++] = 0);
  for (i = 0; i < m; i++) {
    c[i] = row = row.slice();
    for (diag = 0, j = 0; j < n; j++, diag = latch) {
      latch = row[j];
      if (x[i] === y[j]) {
        row[j] = diag + 1;
      } else {
        left = row[j - 1] || 0;
        if (left > row[j]) {
          row[j] = left;
        }
      }
    }
  }
  i--;
  j--;
  //row[j] now contains the length of the lcs
  //recover the lcs from the table
  while (i > -1 && j > -1) {
    switch (c[i][j]) {
      default:
        j--;
        lcs.unshift(x[i]);
      // eslint-disable-next-line
      case i && c[i - 1][j]:
        i--;
        continue;
      case j && c[i][j - 1]:
        j--;
    }
  }
  return lcs.join("");
}

function entryMatch(keyword, entry) {
  const subsequenceContent = lcs(keyword, entry[1].content);
  const subsequenceTitle = lcs(keyword, entry[1].title);
  if (!subsequenceContent && !subsequenceTitle) {
    return false;
  }
  let score = 0;

  if (entry[1].content.toLowerCase().indexOf(keyword) > 0) {
    score += 200;
  }
  if (entry[1].title.toLowerCase().indexOf(keyword) > 0) {
    score += 300;
  }
  if (keyword.toLowerCase() === entry[1].title.toLowerCase()) {
    score += 1000;
  }

  let temp = (keyword + "").trim();
  temp = temp.split(" ");

  if (temp.length > 1) {
    var r = 0;
    temp.forEach(function(item) {
      r +=
        entry[1].title.toLowerCase().indexOf(item.toLowerCase()) > 0 ? 200 : 0;
    });
    console.log(r, "Score", entry[1].title);
    score += r;
  }

  if (keyword === subsequenceContent) {
    score += 0;
  }
  if (keyword === subsequenceTitle) {
    score += 100;
  }

  if (keyword === subsequenceTitle) {
    var length = subsequenceContent.length + subsequenceContent.length;
    score += length;
  }

  return {
    title: entry[1].title,
    body: entry[1].content,
    needle: keyword,
    path: entry[0],
    score
  };
}

export default function clientSearch(keyword) {
  if (!keyword) return [];
  var dataEntries = Object.entries(data);

  dataEntries = dataEntries.reduce(function(acc, entry) {
    var res = entryMatch(keyword, entry);
    if (!res) return acc;

    return acc.concat(res);
  }, []);

  dataEntries = dataEntries.sort(function(a, b) {
    return b.score - a.score;
  });
  dataEntries = dataEntries.filter(function(a) {
    return a.score;
  });

  return dataEntries;
}
