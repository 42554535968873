import React from "react";
import Markdown from "components/Markdown";
import Typography from "components/Typography";
import { createUseStyles } from "react-jss";
import getStrapiURL from "utils/getStrapiURL";
import { useMediaQuery } from "react-responsive";
import Container from "components/Container";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      maxWidth: 900,
      marginRight: "auto",
      marginLeft: "auto"
      // overflow: "scroll"
    },
    image: {
      width: "100%"
    }
  };
});

function View(props) {
  const classes = useStyles(props);

  const { title, imageMobile, imageDesktop, altText } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const chosenImage = isMobile ? imageMobile : imageDesktop;
  return (
    <div>
      {title && (
        <Container>
          <h2>
            <Typography variant="heading1">{title}</Typography>
          </h2>
        </Container>
      )}
      <img
        className={classes.image}
        src={getStrapiURL(chosenImage)}
        alt={altText}
      />
    </div>
  );
}
export default View;
