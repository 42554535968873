/**
 * @description Price grid card component, this component renders the price card for individual phone plams
 * @author  carlosintrigue
 */
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Markdown from "components/Markdown";
const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #CCCCCC",
      borderRadius: "4px",
      opacity: "1",
      width: "532px",
      maxWidth: "80%",
      display: "flex",
      flexDirection: "row",
      position: "relative",
      padding: 16,
      boxSizing: "border-box",
      justifyContent: "center"
    },
    planPriceContainer: {
      // textAlign: "right",
      font: "700 36px/49px Avenir",
      letterSpacing: "0",
      color: ({ variant }) =>
        theme.palette[variant]
          ? theme.palette[variant].main
          : theme.palette.primary.main,
      opacity: "1",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexDirection: "column",
      "& sub": {
        bottom: -15
      },
      textAlign: "center",
      width: "50%"
    },
    separator: {
      minHeight: "90%",
      width: "1.2px",
      backgroundColor: theme.palette.gray.dark,
      margin: "0px 16px 0px 16px",
      boxSizing: "border-box"
    },
    cardHeader: {
      textAlign: "left",
      font: "700 24px/33px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    md: {
      color: "#000000",
      "& ul": {
        margin: 0,
        // ui styles
        opacity: "1 !important",
        textAlign: "left",
        font: "400 16px/22px Avenir",
        letterSpacing: "0",
        color: "#000000",
        padding: "0px 0px 0px 16px",
        "& > li:not(:last-child)": {
          marginBottom: 8
        }
      },
      "& li": {
        opacity: "1 !important"
      }
    },
    contentContainer: {
      width: "50%"
    },
    "@media (max-width: 700px)": {
      root: {
        flexDirection: "column",
        "& *": {
          // textAlign: "center !important"
        }
      },
      separator: {
        minWidth: "90%",
        height: "1px",
        margin: "16px 0px 16px 0px"
      },
      planPriceContainer: {
        flexDirection: "row",
        width: "100%",
        "& sub": {
          bottom: 0,
          display: "block",
          height: "auto",
          "padding-top": "10px"
        }
      },
      contentContainer: {
        width: "auto"
      }
    }
  };
});

function formatMoney(num, n, x) {
  if (num === null || num === undefined) return "Contact us";
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
  return num.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
}

function PriceGridCard(props) {
  const classes = useStyles(props);
  const { planDetails, planPrice, planName, planPriceInterval } = props;

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <span className={classes.cardHeader}>{planName}</span>

        <div className={classes.md}>
          <Markdown escapeHtml={false} skipHtml={false}>
            {planDetails}
          </Markdown>
        </div>
      </div>
      <div className={classes.separator}></div>

      <div className={classes.planPriceContainer}>
        {`${
          planPrice !== null && planPrice !== undefined
            ? `$${formatMoney(planPrice, 2, 3)}`
            : `Contact us`
        }`}
      </div>

      {planPriceInterval && (
        <div className={classes.planPriceContainer}>
          <sub>{planPriceInterval}</sub>
        </div>
      )}
    </div>
  );
}

PriceGridCard.defaultProps = {
  planPrice: 44,
  planName: "Ultra Plus",
  planDetails: [
    { type: "description", content: "Details about this plan" },
    { type: "description", content: "Details about this plan" },
    { type: "link", content: "Details about this plan", href: "/" }
  ],
  planPriceInterval: ""
};

PriceGridCard.propTypes = {
  /**Plan price that will be displayed in the card */
  planPrice: PropTypes.number,
  /**Plan Name that will be displayed on top of the details list */
  planName: PropTypes.string,
  /**Plan Details array Each plan details obj will be either a description or a link */
  planDetails: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.string,
      href: PropTypes.string
    })
  ),
  planPriceInterval: PropTypes.string
};
export default PriceGridCard;
