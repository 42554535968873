import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Checkbox from "components/Checkbox";
import shortid from "shortid";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      textAlign: "left",
      font: "400 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1",
      listStyleType: "none",
      cursor: "pointer",
      whiteSpace: "nowrap",
      transition: "3s",
      "&:hover $input ~ $checkbox ": {
        backgroundColor: theme.palette.gray.lighter
      }
    },
    label: {
      display: "none"
    },
    input: {
      display: "none"
    },
    "@media (max-width: 700px)": {
      root: {
        textAlign: "left"
      }
    }
  };
});

function PhoneGridSortItem(props) {
  const classes = useStyles(props);
  const { selected, content, type, onClick, ...rest } = props;
  const gen = shortid.generate();
  const id = `option${gen}`;
  return (
    <li className={classes.root}>
      <Checkbox
        content={content}
        checked={selected}
        onClick={function() {
          onClick(content);
        }}
      />
    </li>
  );
}

PhoneGridSortItem.defaultProps = {
  content: "Placeholder",
  type: "radio",
  selected: false,
  onClick: function() {
    alert("chicled");
  }
};
export default PhoneGridSortItem;
