import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Typography from "components/Typography";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      margin: "24px 0px 24px 0px"
    },
    resultContent: {
      maxWidth: 900,
      // whiteSpace: "nowrap",
      // display: "inline-block",
      // overflow: "hidden",
      // textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp:": "3",
      "-webkit-box-orient:": "vertical",
      marginLeft: 16,
      overflow: "hidden"
    }
  };
});

function strongifyNeedle(word, needle) {
  word = word.substring(0, 300) + "...";

  const tokens = word.split(needle);

  if (tokens.length == 1) {
    return <span>{tokens[0]}</span>;
  }
  return (
    <>
      {tokens.map(function(token, i) {
        return (
          <>
            <span>{token}</span>
            {i !== tokens.length - 1 && <strong>{needle}</strong>}
          </>
        );
      })}
    </>
  );
}

function IndividualResult(props) {
  const { title, body, needle, path } = props;
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <div className={classes.root}>
      <a
        href={path}
        onClick={function(e) {
          e.preventDefault();
          history.push(path);
        }}
      >
        <Typography variant="searchResult">{title}</Typography>
      </a>
      <br />

      <div className={classes.resultContent}>
        <Typography variant="bodyLight">
          {strongifyNeedle(body, needle)}
        </Typography>
      </div>
    </div>
  );
}

IndividualResult.defaultProps = {
  title: "DSL",
  body: "Hello world.",
  needle: "world",
  path: "/"
};

IndividualResult.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  needle: PropTypes.string,
  path: PropTypes.string
};
export default IndividualResult;
