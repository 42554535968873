import React, { useState } from "react";
import Footer from "components/Footer";
import Header from "components/Header";
import SubRouting from "router/subrouting.js";
import { createUseStyles } from "react-jss";
import { Switch, useHistory } from "react-router-dom";
import usePage from "hooks/usePage";
import Container from "components/Container";
import Typography from "components/Typography";
import BannerImage from "components/BannerImage";
import ImageRows from "components/ImageRows";
import BundlesGrid from "components/BundlesGrid";

import getStrapiURL from "utils/getStrapiURL";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      // paddingTop: 44,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function BundleAndSave(props) {
  const [classes, contentEl, footerEl] = usePage();
  const { data } = props;

  const localClasses = useStyles(props);
  const history= useHistory();
  return (
    <>
      <header className={classes.header}>
        <Header variant={"primary"} personal />
      </header>
      <main className={classes.content} ref={contentEl} id="content">
        <div className={localClasses.root}>
          <BannerImage
            topText={data.mainBanner[0].breadcrumb}
            title={data.mainBanner[0].title}
            markdown={data.mainBanner[0].body}
            imageUrl={getStrapiURL(data.mainBanner[0].image.src[0].url)}
            gradient
          />

          <ImageRows
            rows={[
              {
                imageLeft: true,
                imageSrc: getStrapiURL(
                  data.Left_Image_Row[0]?.images[0].src[0].url
                ),
                description:
                  "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
                linkText: "",
                conditions: "",
                content: data.Left_Image_Row[0]?.body,
                onClick: function() {
                  // alert("click");
                }
              }
            ]}
            title={data.Left_Image_Row[0]?.title}
          />
          <BundlesGrid
            title={"Find your perfect bundle"}
            options={data.Card.map(function(item) {
              return { ...item };
            })}
          />
          <BannerImage
            topText={"Mornington eDeals"}
            header={true}
            headerText={data.Ads[0].title}
            markdown={data.Ads[0].body}
            description={
              "Stay connected wherever you are with our Mobile Internet Devices and Flex Plans."
            }
            imageUrl={getStrapiURL(data.Ads[0].images[0].src[0].url)}
            ctaButton
            ctaButtonProps={{
              children: data.Ads[0].button_text,
              onClick: function() {
                history.push("/contact-us/live-support");
                
              }
            }}
            gradient
            leftAlign={true}
          />
        </div>
      </main>
      <footer className={classes.footer} ref={footerEl} style={{zIndex:1}}>
        <Footer />
      </footer>
    </>
  );
}

export default BundleAndSave;
