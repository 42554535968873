/**
 * @description Component that implements a phone viewer for each individual phone.
 * @author carlosintrigue
 */
/** React */
import React, { useState } from "react";
import PropTypes from "prop-types";

/** Third party libraries */
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

/** Application-level components */
import Button from "components/Button";
import Typography from "components/Typography";
import DotButton from "components/DotButton";
import Container from "components/Container";

/** Stylesheet hook */
const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      display: "flex",
      flexDirection: "column"
      // boxSizing: "border-box"
    },
    headings: {},
    content: { width: "100%", display: "flex", flexDirection: "row" },
    contentMiniSlider: {
      flex: 1,
      position: "relative",
      margin: "auto",
      "& i": {
        color: "#000000",
        opacity: 0.5,
        position: "absolute",
        cursor: "pointer"
      },
      "& img": {
        maxWidth: "350px",
        width: "auto",
        height: "auto",
        fontSize: "16px",
        display: "block",
        marginRight: "auto",
        marginLeft: "auto"
      }
    },
    leftChevron: {
      display: "block",

      top: "50%",
      left: "0px",
      transform: "translateY(-50%)"
    },
    rightChevron: {
      display: "block",

      top: "50%",
      right: "0px",
      transform: "translateY(-50%)"
    },

    thumbnailContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "auto 30px auto 30px"
    },
    thumbnail: {
      maxWidth: 100,
      maxHeight: 100,
      width: "auto",
      height: 100,
      cursor: "pointer"
    },
    selectedThumbnail: {
      border: "3px solid #AE2649"
    },
    specsContainer: {
      flex: 2,
      "& ul": {
        listStyleType: "none",
        margin: 0,
        padding: 0
      },
      "& ul li:not(:last-child)": {
        marginBottom: 24
      }
    },
    specButtonsContainer: {
      display: "flex"
    },
    specStorage2: {
      flex: 1,
      "& > div": {
        marginTop: 10
      }
      // display: "flex",
      // flexDirection: "column"
    },
    specItem: {
      padding: "7px 12px 7px 12px",
      cursor: "pointer",
      userSelect: "none"
    },
    specColour: {
      flex: 1,
      "& button:not(:last-child)": {
        marginRight: 10
      },
      "& > div": {
        marginTop: 10
      }
    },
    specsHighlighted: {
      border: "1px solid #CCCCCC",
      borderRadius: "4px",
      opacity: "1"
    },
    "@media (max-width: 700px)": {
      root: {
        padding: "0px 20px 0px 20px"
      },
      content: {
        flexDirection: "column",
        "& > *:not(:last-child)": {
          marginBottom: 30
        }
      },
      thumbnailContainer: {
        flexDirection: "row",
        margin: 0,

        overflow: "scroll"
        // flexWrap: "wrap",
        // justifyContent: "space-between"
        // alignItems: "flex"
      },
      contentMiniSlider: {
        "& img": {
          maxWidth: "80%"
        }
      },
      specButtonsContainer: {
        flexDirection: "column",
        "& > *:not(:last-child)": {
          marginBottom: 24
        }
      }
    }
  };
});

/** View Layer */
function PhoneViewer(props) {
  const {
    phoneModel,
    phoneBrand,
    variations,
    storageVariations,
    specs
  } = props;
  const classes = useStyles(props);
  const history = useHistory();
  const [variationIndex, setVariationIndex] = useState(0);
  const [storageIndex, setStorageIndex] = useState(0);
  const [colourIndex, setColorIndex] = useState(0);
  const currVariation = variations[variationIndex];
  const currImage = currVariation.images[colourIndex];

  return (
    <Container>
      <div className={classes.root}>
        <div className={classes.headings}>
          <Button
            onClick={function () {
              history.push("/shop/mobile/phones");
            }}
          >
            {" "}
            <i class="fas fa-arrow-left"></i> Back to All Phones
          </Button>
          <br />
          <br />
          <h1>
            <Typography variant="heading1">{phoneModel}</Typography>
          </h1>
          <h2>
            <Typography variant="heading2">{phoneBrand}</Typography>
          </h2>
        </div>
        <div className={classes.content}>
          <div className={classes.contentMiniSlider}>
            <i
              className={`${classes.leftChevron} fas fa-chevron-left`}
              onClick={function () {
                //  supports artibrary
                setColorIndex(
                  (colourIndex + variations.length - 1) % variations.length
                );
              }}
            ></i>
            <img src={currImage} alt={`${phoneModel} image`} />
            <i
              className={`${classes.rightChevron} fas fa-chevron-right`}
              onClick={function () {
                setColorIndex((colourIndex + 1) % variations.length);
              }}
            ></i>
          </div>

          <div className={classes.thumbnailContainer}>
            {currVariation.images.map(function (item, i) {
              return (
                <img
                  src={item}
                  className={`${classes.thumbnail} ${
                    i === colourIndex ? classes.selectedThumbnail : ""
                    }`}
                  key={`${phoneModel} ${currVariation.color} image ${i + 1}`}
                  alt={`${phoneModel} ${currVariation.color} image`}
                  onClick={function () {
                    setColorIndex(i);
                  }}
                  tabindex="0"
                />
              );
            })}
          </div>
          <div className={classes.specsContainer}>
            <h3 id="storagelabel">
              <Typography variant="heading2">{"Product Specs"}</Typography>
            </h3>

            {/* <div
            className={classes.specButtonsContainer}
            role="radiogroup"
            aria-labelledby="storagelabel"
          >
            <div className={classes.specStorage}>
              {storageVariations.length ? (
                <Typography variant="text">Storage Options:</Typography>
              ) : (
                ""
              )}
              <br />
              <div>
                {storageVariations.map(function(variation, i) {
                  return (
                    <span
                      className={`${classes.specItem} ${i === storageIndex &&
                        classes.specsHighlighted}`}
                      onClick={function() {
                        setStorageIndex(i);
                      }}
                      onKeyDown={function(e) {
                        switch (e.keyCode) {
                          case 32:
                            setStorageIndex(i);
                            break;
                          default:
                            break;
                        }
                      }}
                      tabindex="0"
                      role="radio"
                      aria-checked={i === storageIndex}
                    >
                      <Typography variant="text">
                        {i === storageIndex ? (
                          <strong>{variation}</strong>
                        ) : (
                          variation
                        )}
                      </Typography>
                    </span>
                  );
                })}
              </div>
            </div>

             <div className={classes.specColour}>
              <Typography variant="text">Colour:</Typography>
              <Typography variant="text">
                <strong>
                  {" "}
                  {currVariation.color.charAt(0).toUpperCase() +
                    currVariation.color.substring(1)}
                </strong>
              </Typography>
              <div>
                {variations.map(function(variation, i) {
                  return (
                    <DotButton
                      color={variation.color}
                      active={variationIndex === i}
                      onClick={function() {
                        setVariationIndex(i);
                        setColorIndex(0);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div> */}
            <br />
            <ul>
              {specs.map(function (spec) {
                return (
                  <li>
                    <Typography variant="text">{spec}</Typography>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </Container>
  );
}

PhoneViewer.propTypes = {
  /**Array of variations for each phone, each phone color has an image gallery each string in the images array is a href link */
  variations: PropTypes.arrayOf(
    PropTypes.shape({
      images: PropTypes.arrayOf(PropTypes.string),
      color: PropTypes.string
    })
  ),
  /*Array of phone storage options  */
  storageVariations: PropTypes.arrayOf(PropTypes.string),
  /** String representing the phone model */
  phoneModel: PropTypes.string,
  /** Array of strings that describe a list of features the phone offers */
  specs: PropTypes.arrayOf(PropTypes.string)
};

PhoneViewer.defaultProps = {
  variations: [
    {
      images: [
        "/assets/images/phones/galaxy-note-10-plus.png",
        "/assets/images/phones/galaxy-note-10-plus.png",
        "/assets/images/phones/galaxy-note-10-plus.png"
      ],
      color: "red"
    },
    {
      images: [
        "/assets/images/phones/galaxy-note-10-plus.png",
        "/assets/images/phones/galaxy-note-10-plus.png",
        "/assets/images/phones/galaxy-note-10-plus.png"
      ],
      color: "blue"
    }
  ],
  storageVariations: ["128GB", "512GB"],
  phoneModel: "Galaxy Note 10+",
  phoneBrand: "Samsung",

  specs: [
    "Storage: 256GB/512GB",
    "Display: 6.8” Quad HD+",
    "Camera: Rear quad camera (Ultra Wide, Wide-angle, Telephoto, Depth Vision) with 10MP Dual Pixel front camera",
    "Intelligent S Pen included",
    "Waterproof up to 1.5m deep for 30 minutes (IP68 rating)",
    " Available in: Aura Black"
  ]
};
export default PhoneViewer;
