/**
 * @description Price grid component it shows the plan prices for any given mornington communications product
 * @author  carlosintrigue
 */
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import PhonePriceGridCard from "./PhonePriceGridCard";
import Typography from "components/Typography";
import Container from "components/Container";
import Button from "components/Button";
import Typeahead from "components/Typeahead";
import Markdown from "components/Markdown";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
const locations = [
    "Amulree",
    "Atwood",
    "Bamberg",
    "Britton",
    "Drayton",
    "Gadshill",
    "Hampstead",
    "Hawkesville",
    "Hesson",
    "Linwood",
    "Lisbon",
    "Milverton",
    "Molesworth",
    "Monkton",
    "Moorefield",
    "Nithburg",
    "Rothsay",
    "Tavistock",
    "Shakespeare",
    "Wellesley",
    "Other areas"
];

const useStyles = createUseStyles(function (theme) {
  return {
    content: {
      color: theme.palette.primary.main,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    disclaimer: {
      font: "500 18px/25px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1",
      display: "block",
      maxWidth: 720,
      "& *": {
        textAlign: "center !important"
      }
    }
  };
});

function PhonePriceGrid(props) {
  const classes = useStyles(props);
  const {
    plans,
    onClick,
    titleText,
    ctaText,
    onSelection,
    disclaimer,
    location,
    hasTitle,
    hasLocation,
    hasDisclaimer,
    international,
    variant
  } = props;
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {hasLocation && (
          <>
            <Container>
              <Typography variant="heading2">
                {/* Currently viewing prices for{" "}
                {location || "no location selected"}. */}
                {location || sessionStorage.getItem("location") ? `Currently viewing prices for ${location || sessionStorage.getItem("location")}` :
                  'Please select a location'}
              </Typography>
            </Container>

            {!international ? (
              <div style={{ width: 250 }}>
                <Typeahead
                  onSelection={onSelection}
                  value={location}
                  locations={locations.map(item => ({
                    label: item
                  }))}
                  variant={variant}
                />
              </div>
            ) : (
                <div style={{ width: 250 }}>
                  <Typeahead
                    onChange={props.onTypedValueChange}
                    onSelection={onSelection}
                    value={location}
                    locations={props.locations.map(item => ({
                      label: item
                    }))}
                    variant={variant}
                    iconcode={"fa-globe"}
                  />{" "}
                </div>
              )}
          </>
        )}

        {plans && plans.length ? (
          plans.map(function (plan) {
            return <PhonePriceGridCard {...plan} variant={variant} />;
          })
        ) : (
            <Typography variant="heading2">
              There are no plans for this location.
            </Typography>
          )}
      </div>
    </div>
  );
}
PhonePriceGrid.defaultProps = {
  plans: [
    {
      planPrice: 44,
      planName: "Ultra Plus",
      planDetails: [
        { type: "description", content: "Details about this plan" },
        { type: "description", content: "Details about this plan" },
        { type: "link", content: "Details about this plan", href: "/" }
      ]
    },
    {
      planPrice: 44,
      planName: "Ultra Plus",
      planDetails: [
        { type: "description", content: "Details about this plan" },
        { type: "description", content: "Details about this plan" },
        { type: "link", content: "Details about this plan", href: "/" }
      ]
    }
  ],
  //REMOVED DEFAULT PROPS FOR ONCLICK AS HANDLING DEFAULT IN ONCLICK
  // onClick: function () {
  //   // //console.log("clicked");
  // },
  ctaText: "Contact us for more details",
  titleText: "Phone Prices",
  disclaimer: "",
  onSelection: function () { },
  location: "",
  hasTitle: true,
  hasLocation: true,
  hasDisclaimer: true,
  international: false,
  countries: []
};

PhonePriceGrid.propTypes = {
  /**Array of plan objects to be rendered as plan cards */
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      planPrice: PropTypes.number,
      planName: PropTypes.string,
      planDetaile: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          content: PropTypes.string,
          href: PropTypes.string
        })
      )
    })
  ),
  /**Callback for Cta button click event */
  onClick: PropTypes.func,
  /**Text for CTA button */
  ctaText: PropTypes.string,
  /**Text that will appear on the title of the price grid */
  titleText: PropTypes.string,
  /** Disclaimer text that appears below cards, above */
  disclaimer: PropTypes.string,
  /**Callback that gets executed whenever a location is selected from dropdown */
  onSelection: PropTypes.func,
  location: PropTypes.string,
  hasTitle: PropTypes.bool,
  hasLocation: PropTypes.bool,
  disclaimer: PropTypes.bool,
  international: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.any)
};

export default PhonePriceGrid;
