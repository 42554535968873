import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main
    },
    dot: {
      height: ({ active }) => (active ? "20px" : "16px"),
      width: ({ active }) => (active ? "20px" : "16px"),
      backgroundColor: ({ color }) => color,
      borderRadius: "50%",
      display: "inline-block",
      borderStyle: "none"
    }
  };
});

function DotButton(props) {
  const classes = useStyles(props);
  const { onClick } = props;
  return <button className={classes.dot} onClick={onClick}></button>;
}

DotButton.propTypes = {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool
};
DotButton.defaultProps = {
  color: "gray",
  onClick: function() {},
  active: false
};

export default DotButton;
