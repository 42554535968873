/**
 * @description This hook encapsulates calls to the apollo store.
 * This is reused in all components that need to use the application location. Like all price grids.
 * @author carlosintrigue
 */
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_LOCAL_LOCATION = gql`
  {
    location @client
  }
`;

export default function useLocation() {
  const locQ = useQuery(GET_LOCAL_LOCATION);
  const setLocation = function(location) {
    locQ.client.writeData({ data: { location } });
  };
  const { location } = locQ.data;
  return { location, setLocation };
}
