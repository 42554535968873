import React, { useRef, useEffect } from "react";
import SearchInput from "components/SearchInput";
import { useHistory } from "react-router-dom";
import Typeahead from "components/Typeahead";
import { createUseStyles } from "react-jss";
import intersect from "utils/intersectionChecker";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      position: "absolute",
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      padding: "16px 60px 16px 60px",
      backgroundColor: "white"
    },
    fields: {
      position: "relative",
      right: 0,
      display: "flex",
      marginLeft: "auto",
      // "& > $fieldContainer :not(:last-child)": {
      //   marginRight: "24px"
      // }
      "& > div:not(:last-child)": {
        marginRight: 24
      }
    },
    fieldContainer: {
      width: "300px"
    }
  };
});
const locations = [
  "Atwood",
  "Bamberg",
  "Britton",
  "Drayton",
  "Gadshill",
  "Hampstead",
  "Hawkesville",
  "Hesson",
  "Linwood",
  "Lisbon",
  "Milverton",
  "Monkton",
  "Moorefield",
  "Nithburg",
  "Tavistock",
  "Shakespeare",
  "Wellesley",
  "Other areas"
];

function View(props) {
  const classes = useStyles(props);
  const root = useRef(null);
  const { location, onSelection } = props;
  const history = useHistory();
  //console.log("SUBMENU PROPS", props);

  useEffect(function() {
    let observer = new IntersectionObserver(function(data) {
      if (data.length) {
        data = data[0];
        // //console.log(intersect(data.rootBounds, data.boundingClientRect));
        if (!data.rootBounds || !data.boundingClientRect || !root.current)
          return;
        const { leftIntersect, rightIntersect } = intersect(
          data.rootBounds,
          data.boundingClientRect
        );
        if (leftIntersect && root.current) {
          root.current.style.left = "0";
        } else if (rightIntersect && root.current) {
          root.current.style.right = "0";
        }
      }
    });
    observer.observe(root.current);
    // root.current.style.display = "flex";
  });
  return (
    <div className={classes.root} ref={root}>
      <form className={classes.fields}>
        <div className={classes.fieldContainer}>
          <SearchInput
            onEnterKey={function(val) {
              history.push(`/search/${val}`);
            }}
            onSearchIconClick={function(val) {
              history.push(`/search/${val}`);
            }}
          />
        </div>
        <div className={classes.fieldContainer}>
          <Typeahead
            onSelection={onSelection}
            value={location}
            locations={locations.map(item => ({
              label: item
            }))}
            variant={props.variant}
          />
        </div>
      </form>
    </div>
  );
}
export default View;
