import gql from "graphql-tag";

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import useLocation from "hooks/useLocation";
import View from "./view";
import Skeleton from "components/Spinner";
const QUERY = gql`
  query businessSecurityServices($where: JSON) {
    content: businessSecurityServices {
      mainBanner: Banner {
        body
        button_text
        image {
          src {
            url
          }
          image_title
          alt_text
        }
      }

      Carousel_cards {
        body
        image {
          image_title
          alt_text
          src {
            url
          }
        }
      }

      Full_Text_Row: Full_text_row {
        title
        body
      }

      Left_Image_Row: Left_image_row {
        title
        body
        images {
          image_title
          alt_text
          src {
            url
          }
        }
      }

      Plan_Details: Plan_details {
        title
        note
        button_text
        plans(where: $where) {
          planName: name
          planPrice: price
          planDetails: details
          locations {
            name
          }
        }
      }

      Ads {
        title
        body
        button_text
        images {
          title: image_title
          src {
            url
          }
          alt_text
          type
        }
      }
    }
  }
`;

function GraphContainer() {
  const { location, setLocation } = useLocation();
  const locationFilter = { locations: { name: location } };
  const { loading, error, data } = useQuery(QUERY, {
    variables: { where: locationFilter }
  });

  //console.log("GQL", data);
  return loading || error ? (
    <Skeleton />
  ) : (
    <View
      location={location || ""}
      setLocation={setLocation}
      data={data.content[0]}
    ></View>
  );
}

export default GraphContainer;
