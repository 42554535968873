import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import ImageRows from "components/ImageRows";
import FullTextRow from "components/FullTextRow";
import Container from "components/Container";

import getStrapiURL from "utils/getStrapiURL";
import { useHistory } from "react-router-dom";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data } = props;
  const history = useHistory();
  const plans = data.Plan_Details.plans;

  const filtered = plans.filter(
    ob =>
      ob.planName !== "Command Centre" && ob.planName !== "Command Centre Plus"
  );

  const commandCentre = plans.filter(ob => ob.planName === "Command Centre");

  const commandCentrePlus = plans.filter(
    ob => ob.planName === "Command Centre Plus"
  );

  //console.log("PLANS", sessionStorage.getItem("location")==='Milverton' , plans,(plans.length===1 && plans[0].planName==="Command Centre Plus"));

  // alert("this");
  return (
    <div className={classes.root}>
      <BannerImage
        topText={data.mainBanner?.breadcrumb}
        title={data.mainBanner?.title}
        markdown={data.mainBanner?.body}
        imageUrl={getStrapiURL(data.mainBanner?.image.src[0].url)}
        gradient
      />
      <PriceGrid
        titleText={"Our Fibre Plans"}
        disclaimer={
          "Please note: Computer technology, age, software, network management and other factors may affect Internet speed tests. A direct wired connection from computer or device to modem/Command Centre will create the best possible Internet experience."
        }
        plans={filtered}
        location={location}
        onSelection={setLocation}
        hasDisclaimer={true}
        //BASED ON REQUEST RECEIVED JUNE 2 2020
	ctaText={sessionStorage.getItem("location") === 'Milverton' || (plans.length <= 2 && (commandCentre.length > 0 || commandCentrePlus.length > 0)) ?
        'Contact us for more details':'Sign Up Now'}
        //Redirect to smarthub based on feedback
	onClick={() =>sessionStorage.getItem("location") === 'Milverton' || (plans.length <= 2 && (commandCentre.length > 0 || commandCentrePlus.length > 0)) ?
        history.push("/contact-us/get-in-touch"): window.open("https://mornington.smarthub.coop/Shop.html", "_blank")}
        // TODO add disclaimer to strapi
        disclaimer={
          "Please note: Computer technology, age, software, network management and other factors may affect Internet speed tests. A direct wired connection from computer or device to modem/Command Centre will create the best possible Internet experience."
        }
      />
      <ImageRows
        rows={[
          {
            imageLeft: true,
            imageSrc: getStrapiURL(
              data.Left_Image_Row[0]?.images[0].src[0].url
            ),
            description:
              "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
            linkText: "",
            conditions: "",
            content: data.Left_Image_Row[0]?.body,
            onClick: function () {
              ////  alert("click") ;
            }
          }
        ]}
        title={"Command Centre"}
      />

      <PriceGrid
        titleText={""}
        hasTitle={false}
        hasLocation={false}
        plans={commandCentre}
      />

      <ImageRows
        rows={[
          {
            imageLeft: false,
            imageSrc: getStrapiURL(
              data.Right_Image_Row[0]?.images[0].src[0].url
            ),
            title: "",
            description:
              "A single WiFi radio covers roughly 1000 square feet, and because the typical home is 1500+ square feet Mornington Communications offers the Command Centre Plus. The Command Centre Plus allows the home owner to have multiple WiFi radios around the home ensuring seamless coverage. This is also a great solution for those hard to reach areas that block typical WiFi signals. Large home? No worries, we have solutions to help create a reliable network throughout your home. Our technicians will help determine the number of devices your home needs.",
            linkText: "",
            conditions: "",
            content: data.Right_Image_Row[0]?.body,
            onClick: function () {
              ////  alert("click") ;
            }
          }
        ]}
        title={"Command Centre Plus"}
      />

      <PriceGrid
        titleText={""}
        hasTitle={false}
        hasLocation={false}
        plans={commandCentrePlus}
      />

      <ImageRows
        rows={[
          {
            imageLeft: true,
            imageSrc: getStrapiURL(
              data.Left_Image_Row[1]?.images[0].src[0].url
            ),
            description:
              "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
            linkText: "",
            conditions: "",
            content: data.Left_Image_Row[1]?.body,
            onClick: function () {
              ////  alert("click") ;
            }
          }
        ]}
        title={data.Left_Image_Row[1]?.title}
      />
      <FullTextRow>{data.Full_Text_Row[0]?.body}</FullTextRow>

      <BannerImage
        topText={"Mornington Deals"}
        header={true}
        headerText={data.Ads.title}
        markdown={data.Ads.body}
        description={
          "Stay connected wherever you are with our Mobile Internet Devices and Flex Plans."
        }
        imageUrl={getStrapiURL(data.Ads.images[0].src[0].url)}
        ctaButton
        ctaButtonProps={{
          children: data.Ads.button_text,
          onClick: function () {
            history.push("/bundle-and-save");

          }
        }}
        gradient
        leftAlign={true}
      />
    </div>
  );
}
export default View;
