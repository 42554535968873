import { connect } from "react-redux";
import View from "./view";

function mapStateToProps(state) {
  return {
    ...state.formFields
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
