import React, { useState, useRef, useCallback, useEffect } from "react";
import {useDispatch} from 'react-redux';
import { createUseStyles } from "react-jss";
import shortid from "shortid";
import { SET_MESSAGE} from '../../store/constants/formFields';

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      width: "100%"
    },
    input: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #000000BF",
      width: "100%",

      borderRadius: "4px",
      opacity: "1",
      display: "flex",
      boxSizing: "border-box",
      padding: "12px",
      alignItems: "center",
      color: "#000000BF",
      "&:focus-within": {
        borderColor: ({ variant }) =>
          `${
            theme.palette[variant]
              ? theme.palette[variant].main
              : theme.palette.primary.main
          }`
      }
    },
    inpeut: {
      // remove default styles
      width: "inherit",
      borderWidth: "0px",
      border: "none",
      // ui styles
      textAlign: "left",
      font: "400 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      margin: "0px 12px 0px 12px",
      // opacity: "0.5",
      "&:focus": { outline: "none" }
    },
    icon: {
      color: ({ variant }) =>
        theme.palette[variant]
          ? theme.palette[variant].main
          : theme.palette.primary.main,

      fontSize: 8
    },
    label: {
      display: "none"
    }
  };
});

const locations = ["Afghanistan", "Albania", "Ukraine"];

function ILI(props) {
  const classes = useStyles(props);
  // const { locations } = props;
  const idNumber = shortid.generate();
  const labelID = `label-${idNumber}`;
  const inputID = `input-${idNumber}`;
  const listID = `input-${idNumber}`;
  const [value, setValue] = useState(props.value || "");
  const [isOpen, setOpen] = useState(false, { name: "inputOpen" });
  const [listFocused, setListFocused] = useState(false, {
    name: "listFocused"
  });

  const { onSelection } = props;
  const dispatch=useDispatch();
  const node = useRef(null);

  return (
    <div className={classes.root} ref={node} style={props.style}>
      {/* Invisible label */}

      {/* Input */}
      <div
        className={classes.inputRoot}
        id={inputID}
        aria-labelledby={`${labelID} ${inputID}`}
      >
        <label className={classes.label} id={labelID}>
          {props.label || "text input"}
        </label>
        <textarea
          className={classes.input}
          type={props.type}
          placeholder={props.placeholder}
          value={value}
          rows={'5'}
          onChange={function(e) {
            setValue(e.target.value);
            switch(props.id){
              case 'message':{
                dispatch({
                  type:SET_MESSAGE,
                  payload:e.target.value
                });
                break;
              }
            }
          }}
        />
      </div>
      {/* Options pane */}
    </div>
  );
}

ILI.defaultProps = {
  locations: [],
  onSelection: function() {}
};
export default ILI;
