import gql from "graphql-tag";

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import Skeleton from "components/Spinner";
import useLocation from "hooks/useLocation";

import View from "./view";
const QUERY = gql`
  query getFreePreview {
    content: tvFreePreviews {
      mainBanner: Banner {
        body
        image {
          src {
            url
          }
        }
      }

      Title

      Card {
        title
        markdown: body
        buttonText: button_text
        images {
          src {
            url
          }
          image_title
          alt_text
        }
      }

      Ads {
        title
        body
        button_text
        images {
          image_title
          src {
            url
          }
          alt_text
          type
        }
      }
    }
  }
`;

// const GraphContainer = graphql(QUERY)(View);

function GraphContainer() {
  const { location, setLocation } = useLocation();
  const locationFilter = { locations: { name: location } };
  const { loading, error, data } = useQuery(QUERY, {
    variables: { where: locationFilter }
  });
  //console.log("gql", data);

  return loading || error ? (
    <Skeleton />
  ) : (
    <View
      location={location || ""}
      setLocation={setLocation}
      data={data.content[0]}
    ></View>
  );
}

export default GraphContainer;
