import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      maxWidth: "683px",
      margin: "0px auto 0px auto",
      padding: "0px 30px 0px 30px",
      boxSizing: "border-box",
      // overflow: "auto",
      height: "auto"
    },

    "@media (max-width: 700px)": {
      root: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function Container(props) {
  const classes = useStyles(props);

  return <div className={classes.root}>{props.children}</div>;
}
export default Container;
