/**
 * @description Price grid component it shows the plan prices for any given mornington communications product
 * @author  carlosintrigue
 */
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Skeleton as PhonePriceGridCard } from "./PhonePriceGridCard";
import Typography from "components/Typography";
import LocationInput from "components/LocationInput";
import Button from "components/Button";
import Skeleton from "react-loading-skeleton";

const useStyles = createUseStyles(function(theme) {
  return {
    content: {
      color: theme.palette.primary.main,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    disclaimer: {
      textAlign: "center",
      font: "500 18px/25px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1",
      display: "block",
      maxWidth: 720
    }
  };
});

function PhonePriceGrid(props) {
  const classes = useStyles(props);
  const {
    plans,
    onClick,
    titleText,
    ctaText,
    onSelection,
    disclaimer,
    location
  } = props;

  //console.log(props.plans, location);
  return (
    <div className={classes.root}>
      <h1>
        <Typography variant="heading1">{titleText}</Typography>
      </h1>
      <div className={classes.content}>
        <Typography variant="heading2">
          <Skeleton count={1} />
        </Typography>

        <LocationInput onSelection={onSelection} value={location} disabled />
        <Skeleton count={1} />

        {plans && plans.length ? (
          plans.map(function(plan) {
            return <PhonePriceGridCard {...plan} />;
          })
        ) : (
          <Typography variant="heading2">
            There are no plans for this phone in the selected location
          </Typography>
        )}

        {disclaimer && (
          <span className={classes.disclaimer}>
            {<Skeleton count={30} width={50} height={16} />}
          </span>
        )}

        <Button onClick={onClick}>{ctaText}</Button>
      </div>
    </div>
  );
}
PhonePriceGrid.defaultProps = {
  plans: [
    {
      planPrice: 44,
      planName: "Ultra Plus",
      planDetails: [
        { type: "description", content: "Details about this plan" },
        { type: "description", content: "Details about this plan" },
        { type: "link", content: "Details about this plan", href: "/" }
      ]
    },
    {
      planPrice: 44,
      planName: "Ultra Plus",
      planDetails: [
        { type: "description", content: "Details about this plan" },
        { type: "description", content: "Details about this plan" },
        { type: "link", content: "Details about this plan", href: "/" }
      ]
    }
  ],
  onClick: function() {
    // //console.log("clicked");
  },
  ctaText: "Contact us for more details",
  titleText: "Phone Prices",
  disclaimer: "",
  onSelection: function() {},
  location: ""
};

PhonePriceGrid.propTypes = {
  /**Array of plan objects to be rendered as plan cards */
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      planPrice: PropTypes.number,
      planName: PropTypes.string,
      planDetaile: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          content: PropTypes.string,
          href: PropTypes.string
        })
      )
    })
  ),
  /**Callback for Cta button click event */
  onClick: PropTypes.func,
  /**Text for CTA button */
  ctaText: PropTypes.string,
  /**Text that will appear on the title of the price grid */
  titleText: PropTypes.string,
  /** Disclaimer text that appears below cards, above */
  disclaimer: PropTypes.string,
  /**Callback that gets executed whenever a location is selected from dropdown */
  onSelection: PropTypes.func,
  location: PropTypes.string
};

export default PhonePriceGrid;
