import { useQuery } from "@apollo/react-hooks";
import Skeleton from "components/Spinner";
import { motion } from "framer-motion";
import gql from "graphql-tag";
import useLocation from "hooks/useLocation";
import React from "react";
import View from "./view";

const GET_LONG_DISTANCE = gql`
  query getFeatures($where: JSON) {
    homePhoneFeatures {
      mainBanner: Banner {
        body
        button_text
        image {
          src {
            url
          }
          image_title
          alt_text
        }
      }

      Plan_Details {
        title
        note
        button_text
        plans(where: $where) {
          planName: name
          planPrice: price
          planDetails: details
          locations {
            name
          }
        }
      }
      Modal {
        Content
      }

      Ads {
        title
        body
        button_text
        images {
          image_title
          src {
            url
          }
          alt_text
        }
      }
    }
  }
`;

function GraphContainer() {
  const { location, setLocation } = useLocation();
  const locationFilter = { locations: { name: location } };
  const { loading, error, data } = useQuery(GET_LONG_DISTANCE, {
    variables: { where: locationFilter }
  });

  // //console.log(loading, error, data, location);

  //console.log("GQL", data);

  const res = (
    <>
      <motion.div variants={loading || error || !data ? "closed" : "open"}>
        {loading || error || !data ? (
          <Skeleton />
        ) : (
          <View
            location={location || ""}
            setLocation={setLocation}
            data={data.homePhoneFeatures[0]}
          ></View>
        )}
      </motion.div>
    </>
  );

  //console.log(res);

  return res;
}

export default GraphContainer;
