import React, { useState } from "react";
import Footer from "components/Footer";
import SubRouting from "router/subrouting.js";
import { Switch } from "react-router-dom";
import Header from "components/Header";
import usePage from "hooks/usePage";

function Home(props) {
  const [classes, contentEl, footerEl] = usePage();
  const { routes } = props;
  return (
    <>
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TBGMXCR"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      <header className={classes.header}>
        <Header variant={"primary"} personal />
      </header>
      <main className={classes.content} ref={contentEl} id="content">
        <Switch>
          {routes.map(function (route, i) {
            return <SubRouting key={i} {...route} />;
          })}
        </Switch>
      </main>
      <footer className={classes.footer} ref={footerEl} style={{zIndex:1}}>
        <Footer />
      </footer>
    </>
  );
}

export default Home;
