import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import ImageRows from "components/ImageRows";
import FullTextRow from "components/FullTextRow";
import Container from "components/Container";
import getStrapiURL from "utils/getStrapiURL";
import FullImageRow from "components/FullImageRow";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data } = props;
  console.log("here is data", data);

  return (
    <div className={classes.root}>
      <BannerImage
        topText={data.mainBanner[0]?.breadcrumb}
        title={data.mainBanner[0]?.title}
        markdown={data.mainBanner[0]?.body}
        imageUrl={getStrapiURL(data.mainBanner[0]?.image.src[0].url)}
        gradient
      />
      <FullTextRow>{data.Full_Text_Row[0]?.body}</FullTextRow>
      <FullTextRow>
            {data.Full_Text_Row[3]?.body}
       </FullTextRow>
      <FullImageRow
        imageDesktop={
            data.Right_Image_Row?.images[0].src[0].url
        }
        imageMobile={data.Right_Image_Row?.images[0].src[0].url}
      />
     
      <PriceGrid 
        titleText={data.Plan_Details[0].title}
        plans={data.Plan_Details[0].plans}
        hasTitle={true}
        hasLocation={false}
        hasDisclaimer={false}
        hasCta={false}
      />
      <FullTextRow>{data.Full_Text_Row[1]?.body}</FullTextRow>
      <FullTextRow>{data.Full_Text_Row[2]?.body}</FullTextRow>
    </div>
  );
}
export default View;
