import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import Input from "components/Input";
import Textarea from "components/Textarea";
import Button from "components/Button";
import { sendForm } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      border: "2px solid #CCCCCC",
      borderRadius: 4,
      padding: 15
    },
    formRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 24
    },
    formLabel: {
      width:"100%",
    },
    formH2: {
      color: "#ae2649",
      fontWeight: "bold",
      marginBottom: "0.5em"
    },
    toastBackground: {
      backgroundColor: '#D81D36',
      color: '#FFFFFF'
    },
    toastProgressBar: {
      background: "linear-gradient(to right,#961526,#961526,#961526,#961526)"
    }
  };
});



function View(props) {
  const classes = useStyles(props);
  const { campaignID } = props;
  const history = useHistory();
  //handle submit once the props are passed containing form values from mapState
  const handleSubmit = data => async e => {
    e.preventDefault();
    if (data.firstName && data.lastName && data.email) {
      let dataToSend = {
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        message: data.message,
      }
      let response = await sendForm(dataToSend);
      if (response.status === 200) {
        history.push("/thank-you/" + campaignID);
      }
    } else {
      if (!data.firstName || !data.lastName) {
        toast("Please enter a valid name", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: classes.toastBackground,
          progressClassName: classes.toastProgressBar,
        });
      } else {
        toast("Please enter a valid email address.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: classes.toastBackground,
          progressClassName: classes.toastProgressBar
        });
      }
    }
  }
  const thankURL = "/thank-you/" + campaignID;

  return (
    <form id="feedback" onSubmit={handleSubmit(props)} className={classes.root}>
      <h2 className={classes.formH2}>Learn More</h2>
      <div className={classes.formRow}>
        <Input
          required={true}
          style={{ width: "100%" }}
          placeholder={"First name"}
          id={"firstName"}
        />
      </div>

      <div className={classes.formRow}>
        <Input
          required={true}
          style={{ width: "100%" }}
          placeholder={"Last name"}
          id={"lastName"}
        />
      </div>

      <div className={classes.formRow}>
        <Input
          required={true}
          style={{ width: "100%" }}
          placeholder={"Email Address"}
          id={"email"}
        />
      </div>

      <div className={classes.formRow}>
        <Input style={{ width: "100%" }} placeholder={"Phone Number"}
          id={"phoneNumber"} />
      </div>

      <div className={classes.formRow}>
        <Textarea
          style={{ width: "100%" }}
          type={"textarea"}
          id={"message"}
          value={"I would like to learn more about Mornington rate plans and these phones:"}
        />
        <input id="campaign" name="campaign" type="hidden" value={campaignID} />
      </div>

      <div className={classes.formRow}>
        <strong>Tell me more about Mornington phones and rate plans!</strong>
      </div>
      <div className={classes.formRow}>
        By submitting this form, you agree to our Terms and that you have read our Data Use Policy, including our Cookie Use.
      </div>
      <Button
        form="feedback"
        formaction={thankURL}
        formmethod="get"
      >
        Submit
      </Button>
    </form>
  );
}
export default View;
