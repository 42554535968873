import React from "react";
import { withRipple } from "@material/react-ripple";

const StyledButton = withRipple(
  ({ unbounded, initRipple, className, children, style, ...otherProps }) => (
    <div
      ref={initRipple}
      className={`mdc-ripple-surface ${className}`}
      {...otherProps}
      style={{
        ...style
      }}
    >
      {children}
    </div>
  )
);

export default StyledButton;
