import React, { useState } from "react";
import Footer from "components/Footer";
import Header from "components/Header";
import SubRouting from "router/subrouting.js";
import { Switch } from "react-router-dom";
import usePage from "hooks/usePage";

function Home(props) {
  const [classes, contentEl, footerEl] = usePage();
  const { routes } = props;
  //console.log("props", routes);
  return (
    <>
      <header className={classes.header}>
        <Header variant={"tertiary"} business />
      </header>
      <main className={classes.content} ref={contentEl} id="content">
        <Switch>
          {routes.map(function(route, i) {
            return <SubRouting key={i} {...route} />;
          })}
        </Switch>
      </main>
      <footer className={classes.footer} ref={footerEl} style={{zIndex:1}}>
        <Footer variant={"tertiary"} business />
      </footer>
    </>
  );
}

export default Home;
