import  axios  from 'axios';
const  API_URL="https://loop.mornington.ca/process/aca.php";
// const API_URL="https://webhook.site/06497bb2-6300-4e55-8c10-66a00442c5b5"
export const sendForm=(formData)=>{
    return axios.post(API_URL, {
        data:JSON.stringify(formData)
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
}