import React from "react";
import { createUseStyles } from "react-jss";
import SearchInput from "components/SearchInput";
import LocationInput from "components/LocationInput";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      overflow: "hidden",
      height: "inherit",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      maxWidth: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      padding: "17px 24px 17px 24px",
      borderTop: "1px solid #CCCCCC",
      boxSizing: "border-box",
      height: 150,
      overflow: "hidden"
    },
    containers: {
      width: "inherit",
      maxWidth: "75%",
      position: "absolute",
      "& > *:not(:last-child)": {
        marginBottom: 16
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation } = props;
  //console.log("THISPROPS", props)

  return (
    <div className={classes.root}>
      <div className={classes.containers}>
        {" "}
        <SearchInput />
        <LocationInput value={location} onSelection={setLocation} />
      </div>
    </div>
  );
}
export default View;
