import gql from "graphql-tag";
import View from "./view";
import { graphql } from "@apollo/react-hoc";

const GET_PRODUCTS = gql`
  {
    products(sort: "rank:ASC") {
      rank
      title
      description
      os
      manufacturer
      price
      title
      plans: Phone_plans {
        planName: Title
        planDetails: Details
        planPrice: Price
      }
      image_src
      id
    }
  }
`;
const GraphContainer = graphql(GET_PRODUCTS)(View);

export default GraphContainer;
