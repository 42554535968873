import React from "react";
import { createUseStyles } from "react-jss";
import Typography from "components/Typography";
import IndividualResult from "./IndividualResult";
import Container from "components/Container";
import clientSearch from "utils/clientSearch";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { searchInput } = props;

  const res = clientSearch(searchInput);

  return (
    <div className={classes.root}>
      <Container>
        <h1>
          <Typography variant="heading1">
            {!searchInput && "Please type your search terms in the search bar."}
            {searchInput && res.length
              ? `Search results for "${searchInput}"`
              : ""}
            {searchInput && !res.length && (
              <Typography variant="heading1">
                {`No search results for "${searchInput}"`}{" "}
              </Typography>
            )}{" "}
          </Typography>
        </h1>

        {res ? (
          res.map(function(item) {
            //console.log(item);
            return <IndividualResult {...item} />;
          })
        ) : (
          <Typography variant="heading2">Please search again</Typography>
        )}
      </Container>
    </div>
  );
}

View.defaultProps = {
  results: [
    {
      pageTitle: "Test",
      textWindow: "Hello Hello Test",
      path: "/"
    }
  ],
  queryString: ""
};
export default View;
