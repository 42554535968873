import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import ImageRows from "components/ImageRows";
import FullImageRow from "components/FullImageRow";
import Button from "components/Button";
import FullTextRow from "components/FullTextRow";
import Typography from "components/Typography";
import Container from "components/Container";
import getStrapiURL from "utils/getStrapiURL";
import BundlesGrid from "components/BundlesGrid";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    extendedBanner:{
        position: "relative",
        marginTop: "-6.1vw",
        left: "-17vw"
    },
    heading:{
        textAlign: "center",
        padding: "2rem 0 8rem 0"
    },
    outerContainer:{
        display: "inline-flex",
        justifyContent: "center",
        
        // float: "left",
        clear: "none",
        width: "100%"
    },
    innerContainer:{
        textAlign: "center",
        verticalAlign: "middle",
        display: "inline-block",
        padding: "0 2rem 2rem 1rem"
    },
    innerContainerNextRow:{
        textAlign: "center",
        verticalAlign: "middle",
        display: "block",
        padding: "0 2rem 2rem 1rem"
    },
    image:{
        height: "20rem",
        display: "block"
    },
    imageNew:{
        height: "20rem",
        display: "block"
    },
    button:{
    },
    "@media (max-width: 700px)": {
        containedComponent: {
            padding: "0px 20px 0px 20px"
        },
        outerContainer:{
            display:"grid"
        }
    },
    "@media (max-width: 1200px)": {
        extendedBanner:{
            position: "relative",
            marginTop: "-5%",
            left: "-24%"
        }
      },
    //   "@media (min-width: 1200px) (max-width: 1900px)": {
    //     extendedBanner:{
    //         position: "relative",
    //         marginTop: "-5%",
    //         left: "-24%"
    //     }
    //   },
    "@media (min-width: 1920px)": {
        root:{
            "& > *:not(:last-child)": {
                marginBottom: "2px"
            }
        }
      },
    
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data } = props;
  const history= useHistory();

  return (
    <div className={classes.root}>
      <BannerImage
        imageUrl={getStrapiURL('/uploads/a31a4eb96a864ca2b3dd7ebf168e6b52.jpg')}
        // gradient
      />
      <FullImageRow
        imageDesktop={"/uploads/ef7d4709fc604aecab75acea127eae09.png"}
        imageMobile={"/uploads/ef7d4709fc604aecab75acea127eae09.png"}
       />

      <h1 className={classes.heading}>Our best price of the year on new phones!</h1>
      <div className={classes.outerContainer}>
     {/* <img
            src={getStrapiURL("/uploads/9962d86d3e834314b6cf4c44c0129a45.png")}
            altText={"black friday deals"}
            className={classes.extendedBanner}
      /> */}
        <div className={classes.innerContainer}>
            <img
                src="https://strapi.mornington.ca/uploads/5128ed1c03f140caa0f8da3b02ed5732.jpeg"
                className={classes.image}   
            />
            <Button onClick={()=>window.location.href = '/contact-us/get-in-touch'}>GET IT NOW!</Button>
        </div>
        <div className={classes.innerContainer}>
            <img
                src="https://strapi.mornington.ca/uploads/60c2a8e3e4284af5a0f708a49d099160.jpeg"
                className={classes.image}   
            />
            <Button onClick={()=>window.location.href = '/contact-us/get-in-touch'}>GET IT NOW!</Button>
        </div>
        <div className={classes.innerContainer}>
            <img
                src="https://strapi.mornington.ca/uploads/a48415c9d7904edc96d80af8df540a56.jpeg"
                className={classes.image}   
            />
            <Button onClick={ ()=>window.location.href = '/contact-us/get-in-touch'}>GET IT NOW!</Button>
        </div>
        {/* <div className={classes.innerContainer}>
            <img
                src="https://strapi.mornington.ca/uploads/781f1a1033704282a5224fca26a508dd.jpg"
                className={classes.image}   
            />
            <Button onClick={ ()=>window.location.href = '/contact-us/get-in-touch'}>GET IT NOW!</Button>
        </div> */}
        <div className={classes.innerContainerNextRow}>
            <img
                src="https://strapi.mornington.ca/uploads/4b65695a592a4767bda0b1aabaaa69bf.jpeg"
                className={classes.imageNew}   
            />
            <Button onClick={ ()=>window.location.href = '/contact-us/get-in-touch'}>GET IT NOW!</Button>
        </div>
      </div>
      


      <FullTextRow>
      See store for details. Some restrictions may apply. All devices are 128 GB models. Phone colours may not be exactly as shown. May be subject to errors, changes, price changes, and limited availability of stock. Taxes extra *Black Friday Sale is November 20 to 24 2023. Monthly TAB prices shown are based on a $70/mth plan. Lower monthly plans ($40 to $69.99) are available with adjusted monthly phone price. Customer must sign to a monthly plan during the Black Friday Sale. If account is cancelled before the two year term has ended, the customer is required to pay the remainder of the phone cost. Pre-ordering a device does not obligate the customer to sign a two year term during the Black Friday Sale nor are there any obligated costs to customer. **Pre-order your device by November 17, 2023 and your name will automatically be entered to win your device. Winner will be drawn Monday, November 27, 2023.
      </FullTextRow>

    </div>
  );
}
export default View;
