/**
 * @description This hook isolates the logic that makes the margin of the content element dynamic
 * It also encapsulates the common styles for all pages for the fixed position elements Header and Footer.
 * This will automatically take care of getting the rendered height of  footer and add it to the margin of
 * the contnet enabling the hidden footer effect.
 * @author carlosintrigue
 */

import { useCallback, useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";

const usePage = function() {
  const usePageStyles = createUseStyles({
    header: {
      position: "fixed",
      top: "0",
      width: "100%",
      zIndex: "100"
    },

    content: {
      marginTop: "100px",
      overflow: "hidden",
      marginBottom: "100px",
      position: "relative",
      zIndex: "90",
      backgroundColor: "white"
    },
    footer: {
      position: "fixed",
      bottom: 0,
      width: "100%",
      zIndex: -1
    },
    "@media (max-width: 1000px)": {
      // content: { marginTop: "75px" },
      footer: { position: "initial" },
      content: { marginBottom: 0 }
    }
  });

  const footerEl = useRef(null);
  const contentEl = useRef(null);
  const pageClasses = usePageStyles();

  const cb = useCallback(
    function() {
      const height =
        footerEl.current.clientHeight ||
        footerEl.current.clientHeight ||
        footerEl.current.offSetHeight ||
        0;
      // footerEl.current.style.height = `${height}px`;
      contentEl.current.style.marginBottom = `${height}px`;
      const vw = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );

      if (vw <= 1000) {
        contentEl.current.style.marginTop = `${75}px`;
        contentEl.current.style.marginBottom = `${0}px`;
      }
    },
    [footerEl, contentEl]
  );

  useEffect(function() {
    cb();
    window.addEventListener("resize", cb);
    return function() {
      window.removeEventListener("resize", cb);
    };
  });

  return [pageClasses, contentEl, footerEl];
};

export default usePage;
