import gql from "graphql-tag";

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import Skeleton from "components/Spinner";
import useLocation from "hooks/useLocation";

import View from "./view";
const GET_WIRELESS = gql`
  # Write your query or mutation here
  query getWirelessInternets($where: JSON) {
    wirelessInternets {
      mainBanner: Banner {
        body
        image {
          src {
            url
          }
        }
      }
      Left_Image_Row {
        body
        images {
          src {
            url
          }
        }
      }
      Plan_Details {
        title
        note
        button_text
        plans(where: $where) {
          planName: name
          planPrice: price
          planDetails: details
          locations {
            name
          }
        }
      }
      Ads {
        title
        body
        button_text
        images {
          image_title
          src {
            url
          }
          alt_text
          type
        }
      }
    }
  }
`;

// const GraphContainer = graphql(QUERY)(View);

function GraphContainer() {
  const { location, setLocation } = useLocation();
  const locationFilter = { locations: { name: location } };
  const { loading, error, data } = useQuery(GET_WIRELESS, {
    variables: { where: locationFilter }
  });

  return loading || error ? (
    <Skeleton />
  ) : (
    <View
      location={location || ""}
      setLocation={setLocation}
      data={data.wirelessInternets[0]}
    ></View>
  );
}

export default GraphContainer;
