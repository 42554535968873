import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      textAlign: "center",
      width: ({ fixed }) => (fixed ? `50px` : "auto"),
      font: "500 16px/22px Avenir",
      letterSpacing: "0",
      color: ({ active }) => (active ? "#fff" : "#AE2649"),
      padding: "8px 16px 8px 16px",
      backgroundColor: ({ active }) => (active ? "#AE2649" : "#fff"),
      borderStyle: "none",
      "&:disabled": { color: "#000000", opacity: "0.66" }
    }
  };
});

function PaginationGridButton(props) {
  const classes = useStyles(props);
  const { content, ...rest } = props;
  return (
    <button className={classes.root} {...rest}>
      {content}
    </button>
  );
}

PaginationGridButton.defaultProps = {
  content: "",
  active: false,
  disabled: false,
  onClick: function() {},
  onBlur: function() {},
  onFocus: function() {}
};

PaginationGridButton.propTypes = {
  content: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
};
export default PaginationGridButton;
