import React from "react";
import PhoneGridSortButton from "../PhoneGridSortButton";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      border: "1px solid #CCCCCC",
      borderRadius: "4px",
      display: "flex",
      flexWrap: "wrap",
      width: "inherit"
    }
  };
});

function PhoneGridSortButtonList(props) {
  const classes = useStyles(props);
  const { options } = props;
  return <div className={classes.root}>{props.children}</div>;
}

PhoneGridSortButtonList.defaultProps = {
  options: [
    {
      opName: "Sort by",
      options: [
        { content: "test", type: "radio" },
        { content: "test", type: "radio" }
      ],
      opFunc: function(option, data) {
        switch (option) {
          case "Alphabetical":
            return data.sort(function(a, b) {
              return a.name < b.name;
            });
        }

        return data;
      },
      checkbox: true
    }
  ]
};
export default PhoneGridSortButtonList;
