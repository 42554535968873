import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import PhoneGridSortItem from "../PhoneGridSortItem";
const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #CCCCCC",
      borderRadius: "0px 0px 4px 4px",
      opacity: "1",
      margin: 0,
      padding: 24,
      boxSizing: "border-box"
    }
  };
});

function PhoneGridSortList(props) {
  const classes = useStyles(props);
  const { options } = props;
  return (
    <ul className={classes.root}>
      {options.map(function(option) {
        return <PhoneGridSortItem {...option} />;
      })}
    </ul>
  );
}

PhoneGridSortList.defaultProps = {
  options: []
};
PhoneGridSortList.propTypes = {
  options: PropTypes.array
};
export default PhoneGridSortList;
