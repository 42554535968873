import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import shortid from "shortid";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      position: "relative",
      lineHeight: "16px",
      paddingLeft: "28px",
      cursor: "pointer",
      margin: 0,
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      MsUserSelect: "none",
      userSelect: "none",
      textAlign: "left",
      font: "500 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1",
      transition: "3s",
      "& input": { position: "absolute", opacity: "0", cursor: "pointer" },
      "&:hover input ~ $checkmark": {
        backgroundColor: theme.palette.gray.lighter
      },
      "&:active input ~ $checkmark": {
        backgroundColor: theme.palette.gray.light
      },
      "&:focus, &:focus-within": {
        outline: `none`,
        "& $checkmark": {
          outline: `2px solid ${theme.palette.accent.blue}`
        }
      },
      "& input:checked ~ $checkmark": {
        backgroundColor: theme.palette.accent.blue
      },
      "& input:checked ~ $checkmark::after": { display: "block" },
      "& $checkmark::after": {
        left: "4px",
        top: "2px",
        width: "5px",
        height: "10px",
        border: "solid white",
        borderWidth: "0 2px 2px 0",
        WebkitTransform: "rotate(45deg)",
        MsTransform: "rotate(45deg)",
        transform: "rotate(45deg)"
      }
    },
    checkmark: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "16px",
      height: "16px",
      background: "#FFF 0% 0% no-repeat padding-box",
      border: `1px solid ${theme.palette.gray.darker}`,
      borderRadius: "4px",
      opacity: "1",
      "&::after": {
        transition: "3s",
        content: '""',
        position: "absolute",
        display: "none"
      }
    }
  };
});

function Checkbox(props) {
  const classes = useStyles(props);
  const gen = shortid.generate();
  const id = `option${gen}`;
  const { content, checked, onClick, disabled } = props;

  return (
    <label className={classes.root}>
      <span> {content}</span>
      <input
        type="checkbox"
        id={id}
        name={content + " option"}
        checked={checked}
        className={classes.input}
        onClick={function() {
          onClick(content);
        }}
        disabled={disabled}
      />
      <span className={classes.checkmark}></span>
    </label>
  );
}

Checkbox.propTypes = {
  content: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

Checkbox.defaultProps = {
  content: "PropTypes.string",
  checked: true,
  disabled: false,
  onClick: function() {}
};
export default Checkbox;
