import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import ImageRows from "components/ImageRows";
import FullTextRow from "components/FullTextRow";
import Container from "components/Container";

import getStrapiURL from "utils/getStrapiURL";
import { useHistory } from "react-router-dom";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data } = props;
  const history=useHistory();
  //console.log("PLANS", data);

  return (
    <div className={classes.root}>
      <BannerImage
        topText={data.mainBanner[0]?.breadcrumb}
        title={data.mainBanner[0]?.title}
        markdown={data.mainBanner[0]?.body}
        imageUrl={getStrapiURL(data.mainBanner[0]?.image.src[0].url)}
        gradient
      />

      <ImageRows
        rows={[
          {
            imageLeft: true,
            imageSrc: getStrapiURL(
              data.Left_Image_Row[0]?.images[0].src[0].url
            ),
            description:
              "Do you have children and teenagers at home? Do you have wireless devices that you use to surf the internet, play games, stream music or movies? Mornington Communications’ Command Centre is for you! Add Command Centre to your Fibre Internet for safe, reliable and fast WiFi service. Mornington Communications will manage the service for you while you’re in full control. You can decide how to set up Parental Control policies, filter out websites, and even control the time of day usage. Our Technicians will optimize your service by finding the best spot in your home to place the equipment, allowing you to get the best coverage from anywhere in your home. Should there ever be any technical questions or service issues, you simply contact our local Support Team and they help you out. If the equipment ever fails, it will be replaced free of charge. Command Centre is future capable – designed for speeds up to 1 Gbps!",
            linkText: "",
            conditions: "",
            content: data.Left_Image_Row[0]?.body,
            onClick: function() {
              ////  alert("click") ;
            }
          }
        ]}
        title={data.Left_Image_Row[0]?.title}
      />

      <FullTextRow>{data.Full_Text_Row[0]?.body}</FullTextRow>

      <BannerImage
        header={true}
        headerText={data.Ads[0].title}
        markdown={data.Ads[0].body}
        imageUrl={getStrapiURL(data.Ads[0].images[0].src[0].url)}
        ctaButton
        ctaButtonProps={{
          children: data.Ads[0].button_text,
          onClick: function() {
            history.push("/bundle-and-save");
            
          }
        }}
        gradient
        leftAlign={true}
      />
    </div>
  );
}
export default View;
