import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #000000BF",
      borderRadius: "4px",
      opacity: "1",
      display: "flex",
      width: "100%",
      boxSizing: "border-box",
      padding: "12px",
      alignItems: "center",
      color: "#000000BF",
      paddingRight: 24,
      "&:focus-within": {
        borderColor: ({ variant }) =>
          `${
            theme.palette[variant]
              ? theme.palette[variant].main
              : theme.palette.primary.main
          }`
        // color: ({ variant }) =>
        //   `${
        //     theme.palette[variant]
        //       ? theme.palette[variant].main
        //       : theme.palette.primary.main
        //   }`
      }
    },
    input: {
      // remove default styles
      borderWidth: "0px",
      border: "none",
      width: "100%",
      // ui styles
      textAlign: "left",
      font: "400 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      margin: "0px 12px 0px 12px",
      // opacity: "0.5",
      "&:focus": { outline: "none" }
    },
    iconSearch: {
      cursor: "pointer"
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const [value, setValue] = useState(props.value || "");

  return (
    <div className={classes.root}>
      <i className="fas fa-search"></i>{" "}
      <input
        className={classes.input}
        type="text"
        placeholder="I want to find..."
        value={value}
        onChange={function(e) {
          setValue(e.target.value);
        }}
        onKeyDown={function(e) {
          var code = e.keyCode ? e.keyCode : e.which;
          if (code == 13) {
            //Enter keycode
            props.onEnterKey && props.onEnterKey(value);
          }
        }}
        name="usrnm"
      />
      <i
        className={`${classes.iconSearch} fas fa-arrow-right`}
        onClick={function() {
          props.onSearchIconClick(value);
        }}
      ></i>{" "}
    </div>
  );
}
export default View;
