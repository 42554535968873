const intersect = function(viewport, element) {
  if (!viewport || !element)
    return {
      leftIntersect: false,
      rightIntersect: false,
      bottomIntersect: false,
      topIntersect: false
    };
  const rightIntersect =
    viewport.width < (element.x || element.right) + element.width;
  const leftIntersect = 0 > (element.x || element.right);
  const bottomIntersect =
    viewport.height < (element.y || element.height) + element.height;
  const topIntersect = 0 > (element.y || element.height);
  return { leftIntersect, rightIntersect, bottomIntersect, topIntersect };
};

export default intersect;
