/**
 * @description This is the view layer for the ImageSlider component.
 * Component is used in ImageSlider under home screen
 * @author  cindyintrigue
 */
import React from "react";
import { withStyles } from "react-jss";
import PropTypes from "prop-types";
import BannerImage from "../BannerImage";
import "font-awesome/css/font-awesome.min.css";

const styles = theme => {
  return {
    slide: {
      left: "100%",
      width: "100%",
      overflow: "hidden",
      animation: "$slides 0.5s forwards"
    },
    "@keyframes slides": { "100%": { left: "0" } },
    controls: {
      textAlign: "center",
      marginTop: "18px",
      marginBottom: "48px"
    },
    arrowLeft: {
      cursor: "pointer",
      marginRight: "36px",
      opacity: "0.5"
    },
    arrowRight: {
      cursor: "pointer",
      marginLeft: "24px",
      opacity: "0.5"
    },
    activeDotButton: {
      height: "12px",
      width: "12px",
      backgroundColor: "#000",
      opacity: "0.5",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "12px",
      cursor: "pointer"
    },
    dotButton: {
      height: "12px",
      width: "12px",
      backgroundColor: "#fff",
      border: "1px solid #000",
      opacity: "0.5",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "12px",
      cursor: "pointer"
    }
  };
};

class ImageSlider extends React.Component {
  constructor(props) {
    super(props);
    let maxIndex = this.props.images.length;
    this.state = {
      activeIndex: 0,
      length: maxIndex
    };
  }

  componentDidMount() {
    // const speed = 7000;
    // this.timer = setInterval(() => {
    //   this.transitionSlide();
    // }, speed);
  }

  transitionSlide = () => {
    let nextIndex = this.state.activeIndex + 1;
    const { images } = this.props;
    let maxIndex = images.length - 1;
    // reset to first slide
    if (nextIndex > maxIndex) {
      nextIndex = 0;
    }
    this.setState({ activeIndex: nextIndex });
  };

  dotIndicators = index => {
    this.setState({ activeIndex: index });
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.transitionSlide();
    }, 7000);
  };

  prevSlide = () => {
    let prevSlide = this.state.activeIndex - 1;
    if (prevSlide < 0) {
      prevSlide = this.state.length - 1;
    }
    this.setState({ activeIndex: prevSlide });
  };

  nextSlide = () => {
    const nextSlide = (this.state.activeIndex + 1) % this.state.length;
    this.setState({ activeIndex: nextSlide });
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const classes = this.props.classes;
    const { images } = this.props;
    // slider
    const slider = images.map((image, index) => {
      if (this.state.activeIndex == index) {
        return (
          <div className={classes.slide}>
            <BannerImage
              topText={image.topic}
              imageUrl={image.imageUrl}
              title={image.title}
              markdown={image.markdown}
              description={image.description}
              textColor={image.textColor}
              ctaButton={image.ctaButton}
              ctaButtonProps={image.ctaButtonProps}
              gradient={image.gradient}
              gradientColor={image.gradientColor}
            ></BannerImage>
          </div>
        );
      }
    });
    let dotButtons = Array.apply(null, {
      length: images.length
    }).map((e, index) => (
      <span
        key={index}
        className={
          index === this.state.activeIndex
            ? classes.activeDotButton
            : classes.dotButton
        }
        onClick={() => this.dotIndicators(index)}
      ></span>
    ));
    return (
      <>
        {slider}
        <div className={classes.controls}>
          <span className={classes.arrowLeft} onClick={this.prevSlide}>
            <i className="fas fa-chevron-left"></i>
          </span>
          {dotButtons}
          <span className={classes.arrowRight} onClick={this.nextSlide}>
            <i className="fas fa-chevron-right"></i>
          </span>
        </div>
      </>
    );
  }
}

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      topic: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      ctaButton: PropTypes.bool,
      ctaButtonProps: PropTypes.object,
      textColor: PropTypes.string,
      gradient: PropTypes.bool
    })
  )
};

ImageSlider.defaultProps = {
  images: [
    {
      imageUrl: "/assets/images/slides/slide1.png",
      topic: "Call for Director Nominations",
      title: "Board of Directors",
      description:
        "Nominations and applications welcome! Deadline is February 29th, 2020 at 3 pm. Voting to take place at the Annual General Meeting.",
      ctaButton: true,
      ctaButtonProps: {
        children: "Learn more",
        onClick: function() {
          // alert("click");
        }
      }
    },
    {
      imageUrl: "/assets/images/slides/slide2.png",
      topic: "Installation & Monitoring",
      title: "Mornington Home Security",
      description:
        "Protect what means the most to you by having your home properly equipped with the best security equipment.",
      ctaButton: true,
      ctaButtonProps: {
        children: "Learn more",
        onClick: function() {
          // alert("click");
        }
      },
      textColor: "#fff",
      gradient: true
    },
    {
      imageUrl: "/assets/images/slides/slide3.png",
      ctaButton: true,
      ctaButtonProps: {
        children: "Get yours now",
        onClick: function() {
          // alert("click");
        }
      }
    }
  ]
};

export default withStyles(styles)(ImageSlider);
