import React, { useState, useRef, useEffect, useCallback } from "react";
import { createUseStyles } from "react-jss";
import posed, { tween } from "react-pose";
import shortid from "shortid";

const RotateI = posed.i({
  closedt: { rotate: "0deg" },
  opent: { rotate: "180deg" }
});

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "0",
      marginBottom: "0",
      listStyle: "none",
      "&:hover": {
        backgroundColor: ({ variant }) => `${theme.palette.white.dark}`,
        color: ({ variant }) => `${theme.palette.white.contrast}`
      },
      "&:focus, &:focus-within": {
        // boxShadow: `0 0 0 0.1rem ${theme.palette.accent.blue}`,
        // outline: "none",
        zIndex: 10
      },
      "&:active": {
        // backgroundColor: ({ variant }) => `${theme.palette.white.darker}`
      }
    },
    content: {
      width: "100%",
      display: "inline-block",
      marginRight: "auto",
      textAlign: "left",
      font: ({ large }) =>
        large ? "500 18px/25px Avenir" : `500 16px/22px Avenir`,
      letterSpacing: "0",
      backgroundColor: "transparent",
      color: theme.palette.white.contrast,
      opacity: "1",
      boxSizing: "border-box",
      padding: "10px 24px 10px 24px",
      userSelect: "none",
      transition: "0.3s",
      whiteSpace: "nowrap",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none"
      },
      "&:focus  ": { outline: "none" },
      "&:active": {}
    },
    submenu: { padding: 0 },
    activeItem: {
      font: ({ large }) =>
        large ? "700 18px/25px Avenir" : `700 16px/22px Avenir`,
      borderBottom: ({ variant, enableUnderline }) =>
        enableUnderline
          ? `3px solid ${
              theme.palette[variant]
                ? theme.palette[variant].main
                : theme.palette.white.contrast
            }`
          : ""
    }
    // "@media (max-width: 700px)": {
    //   content: {
    //     padding: "10px 24px 10px 24px",
    //     font: ({ large }) =>
    //       large ? "500 18px/25px Avenir" : `500 16px/22px Avenir`
    //   },
    //   activeItem: { font: "700 16px/25px Avenir" }
    // }
  };
});

function ProductbarItem(props) {
  const classes = useStyles(props);
  const {
    dropDown,
    onClick,
    active,
    Submenu,
    paddingLeft = 0,
    isOpen,
    large,
    enableUnderline = false
  } = props;

  return (
    <li className={classes.root}>
      <a
        href={props.href || "#"}
        onClick={function(e) {
          e.preventDefault();
          props.onClick && props.onClick(e);
        }}
        className={`${classes.content} ${active ? classes.activeItem : ""}`}
      >
        <span style={{ paddingLeft }}>
          {props.children}{" "}
          {dropDown ? (
            <RotateI
              pose={isOpen ? "opent" : "closedt"}
              className="fas fa-caret-down"
              aria-hidden="true"
              withParent={false}
            ></RotateI>
          ) : (
            ""
          )}
        </span>
      </a>
    </li>
  );
}
export default ProductbarItem;
