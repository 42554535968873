import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import Markdown from "components/Markdown";
import Button from "components/Button";

import Footer from "components/Footer";
import Header from "components/Header";
import usePage from "hooks/usePage";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      height: "100vh",
      margin: "0px 60px 0px 60px"
    },
    container: {
      position: "absolute",
      top: "10%",
      maxWidth: 730
    }
  };
});

function ThankYou(props) {
  const [classes, contentEl, footerEl] = usePage();
  const localClasses = useStyles();
  const history = useHistory();
  const { routes } = props;
  return (
    <>
      <header className={classes.header}>
        <Header variant={"primary"} personal />
      </header>
      <main className={classes.content} ref={contentEl} id="content">
        <div className={localClasses.root}>
          <div className={localClasses.container}>
            <Markdown>
              {`
# Thank you for reaching out.
### Your message has been sent.

Now that we’ve received your message, we’ll get back to you within **2 business days**.
        `}
            </Markdown>

            <Button
              variant={props.variant}
              onClick={function(e) {
                e.preventDefault();
                history.push("/");
              }}
            >
              Go back to Home
            </Button>
          </div>
        </div>
      </main>
      <footer className={classes.footer} ref={footerEl} style={{zIndex:1}}>
        <Footer />
      </footer>
    </>
  );
}

export default ThankYou;
