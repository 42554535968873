import React from "react";
import Button from "components/Button";
import Markdown from "components/Markdown";
import Container from "components/Container";
import SearchInput from "components/SearchInput";
import { createUseStyles } from "react-jss";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";

const useStyles = createUseStyles(function (theme) {
  return {
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 50px 0px 50px",
      boxSizing: "border-box"
    },
    root: {
      color: theme.palette.primary.main,
      background: ({ imageUrl, gradient }) =>
        `url(${imageUrl}) no-repeat center center`,
      backgroundImage: ({ imageUrl }) =>
        `${`url(${imageUrl}) no-repeat center center`}`,
      backgroundSize: () => "cover",
      width: "100%",
      height: "400px",
      minHeight: "400px",
      position: "absolute",
      top: 0,
      left: 0
    },

    rootText: {
      color: theme.palette.primary.main,

      width: "100%",
      height: "400px",
      minHeight: "400px",
      position: "absolute"
    },
    gradientLayer: {
      background: ({ imageUrl, gradient, gradientColor }) =>
        `${
        gradient
          ? `linear-gradient(45deg, ${gradientColor}, transparent)`
          : ""
        }`,
      width: "100%",
      height: "400px",
      minHeight: "400px",
      opacity: 0.75,
      position: "absolute",
      top: 0,
      left: 0
    },
    mainContainer: {
      position: "relative",
      width: "100%",
      height: "400px",
      zIndex: 91
    },
    widthWrapper: {
      maxWidth: 1366,
      padding: "0px 60px 0px 60px",
      boxSizing: "border-box",
      display: "grid",
      // display: "-ms-grid",
      gridTemplateColumns: "50% 50%",
      "-ms-grid-columns": "50% 50%",
      marginRight: "auto",
      marginLeft: "auto"
    },
    header: {
      textAlign: "left",
      font: "700 48px/56px Coast",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1",
      margin: "48px auto 24px auto",
      display: "block",
      maxWidth: 1270
    },
    containerWrapper: {
      display: "flex",
      flexDirection: "column",
      height: "400px"
    } /**This is a wrapper to allow ie flexcolumn to vertically center for IE11 */,

    container: {
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
      // paddingLeft: 60,
      alignItems: ({ leftAlign }) => (leftAlign ? "flex-start" : "flex-end"),
      justifyContent: "center",
      height: "400px",
      minHeight: "100%",
      "-ms-grid-column": 1
    },
    topText: {
      textAlign: ({ leftAlign }) => (leftAlign ? "left" : "right"),
      font: "500 18px/25px Avenir",
      letterSpacing: "0",
      color: ({ textColor }) => textColor,
      opacity: "1"
    },
    title: {
      textAlign: ({ leftAlign }) => (leftAlign ? "left" : "right"),
      font: "700 24px/33px Avenir",
      letterSpacing: "0",
      color: ({ textColor }) => textColor,
      opacity: "1",
      textTransform: "capitalize"
    },
    description: {
      textAlign: ({ leftAlign }) => (leftAlign ? "left" : "right"),
      font: "500 18px/25px Avenir",
      letterSpacing: "0",
      color: ({ textColor }) => textColor,
      opacity: "1"
    },
    md: {
      // display: "flex",
      // flexDirection: "column",
      // boxSizing: "border-box",
      // paddingLeft: 48,
      // alignItems: ({ leftAlign }) => (leftAlign ? "flex-start" : "flex-end"),
      textAlign: ({ leftAlign }) => (leftAlign ? "left" : "right"),

      display: "flex",
      "flex-direction": "column",

      "& h3": {
        margin: 0,
        width: "100%",

        textAlign: ({ leftAlign }) => (leftAlign ? "left" : "right"),
        font: "700 24px/33px Avenir",
        letterSpacing: "0",
        color: ({ textColor }) => textColor,
        opacity: "1",
        textTransform: "capitalize"
      },
      "& h2": {
        textAlign: ({ leftAlign }) => (leftAlign ? "left" : "right"),
        color: ({ textColor }) => textColor,
        width: "100%",

        font: "700 18px/25px Avenir",
        letterSpacing: "0",
        color: "#000000",
        display: "inline-block"
      },
      "& p": {
        margin: 0,
        textAlign: ({ leftAlign }) => (leftAlign ? "left" : "right"),
        font: "500 18px/25px Avenir",
        letterSpacing: "0",
        color: ({ textColor }) => textColor,
        opacity: "1",
        width: "301px",
        display: "inline-block"
        // float: "right"
      },
      "& h1": {
        margin: 0,
        width: "100%",
        textAlign: ({ leftAlign }) => (leftAlign ? "left" : "right"),
        font: "700 48px/66px Avenir",
        letterSpacing: "0",
        color: ({ textColor }) => textColor,
        opacity: "1",
        display: "inline-block"
      },
      "& > button": {
        textAlign: ({ leftAlign }) => (leftAlign ? "left" : "right"),
        display: "inline",
        margin: ({ leftAlign }) => (leftAlign ? "0 auto 0 0" : "0 0 0 auto")
      }
    },
    searchInputContainer: {
      width: "300px",
      display: "inline-block"
    },
    "@media (max-width: 700px)": {
      root: {
        height: "400px",
        minHeight: "400px"
      },
      containerWrapper: {
        height: "400px"
      },
      searchInputContainer: {
        maxWidth: "80%"
      },

      widthWrapper: {
        maxWidth: 1270,
        display: "grid",
        padding: "0px 0px 0px 0px",

        // display: "-ms-grid",
        gridTemplateColumns: "100% 0%",
        "-ms-grid-columns": "100% 0%",
        marginRight: "auto",
        marginLeft: "auto"
      },
      topText: {
        textAlign: "center"
      },
      title: {
        textAlign: "center"
      },
      description: {
        textAlign: "center !important"
      },
      container: {
        alignItems: "center !important",
        marginLeft: "auto",
        marginRight: "auto",
        padding: 0,
        justifyContent: "center"
      },
      md: {
        "& *": {
          textAlign: "center !important"
        },
        "& > button": {
          marginRight: "auto !important",
          marginLeft: "auto !important"
        }
      }
    }
  };
});

function BannerImage(props) {
  const classes = useStyles(props);
  const {
    topText,
    title,
    description,
    ctaButton,
    ctaButtonProps,
    header,
    headerText
  } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  //console.log(props);

  return (
    <>
      {header && (
        <Container>
          {" "}
          <h2 className={classes.header}>{headerText}</h2>{" "}
        </Container>
      )}

      <div className={classes.mainContainer}>
        <div
          className={classes.root}
          style={
            isMobile
              ? {
                backgroundImage: props.gradient
                  ? `${`linear-gradient(90deg, ${props.gradientColor} 0%,  ${props.gradientColor}40 100%)
            , url(${props.imageUrl})`}`
                  : `url(${props.imageUrl})`,
                backgroundPosition: "top center",
                backgroundSize: "cover"
              }
              : {}
          }
        ></div>

        <div className={classes.gradientLayer}></div>

        <div className={classes.rootText}>
          <div className={classes.widthWrapper}>
            <div className={classes.containerWrapper}>
              <div className={classes.container}>
                <>
                  <div className={classes.md}>
                    {/* <ReactMarkDown source={props.markdown} /> */}

                    <Markdown>{props.markdown}</Markdown>
                    <br />

                    {ctaButton ? (
                      <div style={{ width: "100%" }}>
                        <Button {...ctaButtonProps}></Button>
                      </div>
                    ) : (
                        ""
                      )}

                    {props.searchInput ? (
                      <div style={{ width: "100%" }}>
                        <div className={classes.searchInputContainer}>
                          <SearchInput
                            onEnterKey={props.setSearchInput}
                            onSearchIconClick={props.setSearchInput}
                            {...props.searchInputProps}
                          />
                        </div>
                      </div>
                    ) : (
                        ""
                      )}
                  </div>
                </>
              </div>
              {/* <div className={classes.container}></div> */}
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}

BannerImage.propTypes = {
  /**
   * Background URL for the Horizontal Banner
   */
  imageUrl: PropTypes.string,
  /**
   * Top text in the horizontal Banner
   */
  topText: PropTypes.string,
  /**
   * Title for horizontal banner
   */
  title: PropTypes.string,
  /**
   * Description string for horizontal banner
   */
  description: PropTypes.string,
  /**
   * Boolean value that indicates whether or not the horizontal banner will have a CTA button
   */
  ctaButton: PropTypes.bool,
  /**
   * Props object to be passed to the Horizontal banner
   */
  ctaButtonProps: PropTypes.object,
  /**
   * Defines whether the text will be right or left aligned in the horizontal banner
   */
  leftAlign: PropTypes.bool,
  /**
   * Defines whether the text colour
   */
  textColor: PropTypes.string,

  overlay: PropTypes.bool
};

BannerImage.defaultProps = {
  imageUrl: "",
  topText: "",
  title: "",
  description: "",
  ctaButton: false,
  ctaButtonProps: {},
  leftAlign: false,
  textColor: "#000000",
  gradient: false,
  gradientColor: "#FFFFFF"
};

export default React.memo(BannerImage);
