import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import NavbarItem from "./NavbarItem";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      backgroundColor: ({ variant }) =>
        theme.palette[variant]
          ? theme.palette[variant].main
          : theme.palette.primary.main,
      color: ({ variant }) =>
        theme.palette[variant]
          ? theme.palette[variant].contrast
          : theme.palette.primary.contrast,

      width: "100%",
      "@media (max-width: 1000px)": {
        display: "none"
      }
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 60px 0 60px",
      margin: 0
    },
    switcher: {
      display: "flex",
      margin: 0,
      padding: 0
    }
  };
});

// const menuStructure = [
//   {
//     content: "About us",
//     dropDown: true,
//     items: {
//       content: "Our history",
//       props: {
//         onClick: function(e) {
//           e.preventDefault();
//           history.push("/history");
//         }
//       }
//     }
//   }
// ];

function Navbar(props) {
  const classes = useStyles(props);
  const history = useHistory();
  const { variant, business, personal } = props;
  return (
    <nav
      className={classes.root}
      aria-label="Mornington communications Navigation Menu"
    >
      <ul className={classes.content} role="menubar">
        {/* {navItems.map(function(item) {
          return <NavbarItem>hello</NavbarItem>;
        })} */}
        <NavbarItem
          dropDown
          variant={variant}
          items={[
            {
              content: "Our History",
              href: "/about-us/our-history",
              onClick: function (e) {
                e.preventDefault();
                history.push("/about-us/our-history");
              }
            },
            {
              content: "Get Involved",
              href: "/about-us/get-involved",
              onClick: function (e) {
                e.preventDefault();
                history.push("/about-us/get-involved");
              }
            },
            {
              content: "Our Team",
              href: "/about-us/our-team",
              onClick: function (e) {
                e.preventDefault();
                history.push("/about-us/our-team");
              }
            },
            // {
            //   content: "Accessibility",
            //   href: "/about-us/accessibility",
            //   onClick: function (e) {
            //     e.preventDefault();
            //     history.push("/about-us/accessibility");
            //   }
            // },
            {
              content: "Accessibility",
              href: "/about-us/accessibility-feedback",
              onClick: function (e) {
                e.preventDefault();
                history.push("/about-us/accessibility-feedback");
              }
            },
            {
              content: "Community Involvement",
              href: "/about-us/community-involvement",
              onClick: function (e) {
                e.preventDefault();
                history.push("/about-us/community-involvement");
              }
            },
            {
              content: "News & Updates",
              href: "/about-us/news-and-updates",
              onClick: function (e) {
                e.preventDefault();
                history.push("/about-us/news-and-updates");
              }
            },
            {
              content: "Careers",
              href: "/about-us/careers",
              onClick: function (e) {
                e.preventDefault();
                history.push("/about-us/careers");
              }
            }
          ]}
        >
          About Us
        </NavbarItem>
        <NavbarItem
          dropDown
          variant={variant}
          items={[
            {
              content: (
                <>
                  Webmail <i className="fas fa-external-link-alt"></i>
                </>
              ),
              href: "https://webmail.cyg.net",
              target: "_blank"
            },
            {
              content: (
                <>
                  FAQS <i className="fas fa-external-link-alt"></i>
                </>
              ),
              href: "https://help.mornington.ca/faq",
              target: "_blank"
            },
            {
              content: "Forms",
              href: "/resources/forms",
              onClick: function (e) {
                e.preventDefault();
                history.push("/resources/forms");
              }
            }
          ]}
        >
          Resources
        </NavbarItem>
        <NavbarItem
          dropDown
          variant={variant}
          items={[
            {
              content: "My Bills",
              href: "/about-us/my-bills",
              onClick: function (e) {
                e.preventDefault();
                window.open("https://mornington.smarthub.coop/Login.html", "_blank");
              }
            },
            {
              content: "Pay Now",
              href: "/about-us/pay-now",
              onClick: function (e) {
                e.preventDefault();
                window.open("https://mornington.smarthub.coop/PayNow.html", "_blank");
              }
            },
            {
              content: "My Inbox",
              href: "/about-us/my-inbox",
              onClick: function (e) {
                e.preventDefault();
                window.open("https://webmail.cyg.net/mail/", "_blank");
              }
            }
          ]}
        >
          My Account
        </NavbarItem>
        <NavbarItem
          variant={variant}
          onClick={function (e) {
            e.preventDefault();
            history.push("/");
          }}
          decorated={personal}
          href={"/"}
        >
          Personal
        </NavbarItem>{" "}
        |{" "}
        <NavbarItem
          variant={variant}
          onClick={function (e) {
            e.preventDefault();
            history.push("/business");
          }}
          decorated={business}
          href={"/business"}
        >
          Business
        </NavbarItem>
      </ul>
    </nav>
  );
}
export default Navbar;
