import { SET_FIRSTNAME,SET_LASTNAME, SET_EMAIL,SET_PHONENUMBER,SET_MESSAGE} from '../constants/formFields';

const INTIAL_STATE={
    firstName:null,
    lastName:null,
    email:null,
    phoneNumber:null,
    message:null
};

export default (state=INTIAL_STATE, action)=>{
    switch(action.type){
        case SET_FIRSTNAME:{
            return {...state,firstName:action.payload}
        }
        case SET_LASTNAME:{
            return {...state,lastName:action.payload}
        }
        case SET_EMAIL:{
            return {...state,email:action.payload}
        }
        case SET_PHONENUMBER:{
            return {...state,phoneNumber:action.payload}
        }
        case SET_MESSAGE:{
            return {...state,message:action.payload}
        }

        default:
            return state;
    }
};