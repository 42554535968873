import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import ImageRows from "components/ImageRows";
import Article from "components/Article";

import getStrapiURL from "utils/getStrapiURL";
import { useHistory } from "react-router-dom";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data } = props;
  const history=useHistory();
  // const filtered = data.plans.filter(
  //   ob => ob.name !== "Command Centre" && ob.name !== "Command Centre Plus"
  // );

  // const commandCentre = data.plans.filter(ob => ob.name === "Command Centre");

  // const commandCentrePlus = data.plans.filter(
  //   ob => ob.name === "Command Centre Plus"
  // );
  return (
    <div className={classes.root}>
      <BannerImage
        topText={data.mainBanner[0] ?.breadcrumb}
        title={data.mainBanner[0] ?.title}
        markdown={data.mainBanner[0] ?.body}
        imageUrl={getStrapiURL(data.mainBanner[0].image.src[0].url)}
        gradient
      />
      <PriceGrid
        titleText={data.Plan_Details[0].title}
        plans={data.Plan_Details[0].plans}
        location={location}
        onSelection={setLocation}
        hasDisclaimer={true}
        ctaText={data.Plan_Details[0].button_text}
        // TODO add disclaimer to strapi
        disclaimer={data.Plan_Details[0].note}
      />

      <BannerImage
        topText={"Mornington Deals"}
        header={true}
        headerText={data.Ads[0].title}
        markdown={data.Ads[0].body}
        description={
          "Stay connected wherever you are with our Mobile Internet Devices and Flex Plans."
        }
        imageUrl={getStrapiURL(data.Ads[0].images[0].src[0].url)}
        ctaButton
        ctaButtonProps={{
          children: data.Ads[0].button_text,
          onClick: function () {
            history.push("/bundle-and-save");
            
          }
        }}
        gradient
        leftAlign={true}
      />
    </div>
  );
}
export default View;
