import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import Input from "components/Input";
import Textarea from "components/Textarea";
import Button from "components/Button";
import {sendForm}  from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main
    },
    formRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 24
    },
    toastBackground:{
      backgroundColor:'#D81D36',
      color:'#FFFFFF'
    },
    toastProgressBar:{
      background: "linear-gradient(to right,#961526,#961526,#961526,#961526)"
    },
    button:{
        marginBottom: '10px'
    }
  };
});



function View(props) {
  const classes = useStyles(props);
  const { variant } = props;
  const history = useHistory();
  //handle submit once the props are passed containing form values from mapState
  const handleSubmit=data=> async e=>{
    e.preventDefault();
    if(data.firstName && data.message && (data.email || data.phoneNumber)){
      let dataToSend={
        firstName:data.firstName,
        message:data.message,
        phoneNumber:data.phoneNumber,
        email:data.email
      }
      let response= await sendForm(dataToSend);
      if(response.status===200){
        history.push("/thank-you");
      }
    }else{
      if(!data.firstName){
        toast("Please enter a valid name or enter 'anonymous'", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className:classes.toastBackground,
          progressClassName:classes.toastProgressBar,
          });
      }else if(!data.message){
        toast("Please enter the nature of feedback", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className:classes.toastBackground,
          progressClassName:classes.toastProgressBar,
          });
      }else if(!data.email && !data.phoneNumber){
        toast("Please enter a valid contact method.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className:classes.toastBackground,
          progressClassName:classes.toastProgressBar
          });
      }
    }
    
  }
  
  return (
    <form id="feedbackAccessibility" onSubmit={handleSubmit(props)}>
      <div className={classes.formRow}>
        <Input
          required={true}
          style={{ width: "100%" }}
          placeholder={"Do you wish to remain anonymous, or provide your name?  enter 'anonymous' or please provide your name"}
          id={"firstName"}
        />
      </div>
      <div className={classes.formRow}>
        <Textarea
          required={true}
          style={{ width: "100%" }}
          placeholder={"Nature of feedback.  Please provide as much detail as possible. If a specific incident, please include date/time"}
          type={"textarea"}
          id={"message"}
        />
      </div>
      <div className={classes.formRow}>
        <Input
          style={{ width: "48%" }}
          placeholder={"Email Address"}
          id={"email"}
        />
        <Input 
            style={{ width: "48%" }} 
            placeholder={"Phone Number"} 
            id={"phoneNumber"}
        />
      </div>

      <div className={classes.button}>
        <Button
            form="feedbackAccessibility"
            formaction="/thank-you"
            formmethod="get"
            variant={variant}
        >
            Submit your feedback
        </Button>
      </div>
    </form>
  );
}
export default View;