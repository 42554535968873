/**
 * This is a hook for classNames that contains styles for IE11's implementation of css grid
 */

import { createUseStyles } from "react-jss";

const useIEGridStyles = createUseStyles({
  IEGrid11: {
    "-ms-grid-column": 1,
    "-ms-grid-row": 1,
    "-ms-grid-column-align": "center"
  },
  IEGrid12: {
    "-ms-grid-column": 2,
    "-ms-grid-row": 1,
    "-ms-grid-column-align": "center"
  },
  IEGrid13: {
    "-ms-grid-column": 3,
    "-ms-grid-row": 1,
    "-ms-grid-column-align": "center"
  },
  IEGrid14: {
    "-ms-grid-column": 4,
    "-ms-grid-row": 1,
    "-ms-grid-column-align": "center"
  },
  IEGrid21: {
    "-ms-grid-column": 1,
    "-ms-grid-row": 2,
    "-ms-grid-column-align": "center"
  },
  IEGrid22: {
    "-ms-grid-column": 2,
    "-ms-grid-row": 2,
    "-ms-grid-column-align": "center"
  },
  IEGrid23: {
    "-ms-grid-column": 3,
    "-ms-grid-row": 2,
    "-ms-grid-column-align": "center"
  },
  IEGrid24: {
    "-ms-grid-column": 4,
    "-ms-grid-row": 2,
    "-ms-grid-column-align": "center"
  },
  IEGrid31: {
    "-ms-grid-column": 1,
    "-ms-grid-row": 3,
    "-ms-grid-column-align": "center"
  },
  IEGrid32: {
    "-ms-grid-column": 2,
    "-ms-grid-row": 3,
    "-ms-grid-column-align": "center"
  },
  IEGrid33: {
    "-ms-grid-column": 3,
    "-ms-grid-row": 3,
    "-ms-grid-column-align": "center"
  },
  IEGrid34: {
    "-ms-grid-column": 4,
    "-ms-grid-row": 3,
    "-ms-grid-column-align": "center"
  },
  IEGrid41: {
    "-ms-grid-column": 1,
    "-ms-grid-row": 4,
    "-ms-grid-column-align": "center"
  },
  IEGrid42: {
    "-ms-grid-column": 2,
    "-ms-grid-row": 4,
    "-ms-grid-column-align": "center"
  },
  IEGrid43: {
    "-ms-grid-column": 3,
    "-ms-grid-row": 4,
    "-ms-grid-column-align": "center"
  },
  IEGrid44: {
    "-ms-grid-column": 4,
    "-ms-grid-row": 4,
    "-ms-grid-column-align": "center"
  }
});

export default useIEGridStyles;
