import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import ImageRows from "components/ImageRows";
import FullTextRow from "components/FullTextRow";
import Container from "components/Container";

import getStrapiURL from "utils/getStrapiURL";
import { useHistory } from "react-router-dom";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data } = props;
  const history= useHistory();

  // const filtered = plans.filter(
  //   ob =>
  //     ob.planName !== "Command Centre" && ob.planName !== "Command Centre Plus"
  // );

  // const commandCentre = plans.filter(ob => ob.planName === "Command Centre");

  // const commandCentrePlus = plans.filter(
  //   ob => ob.planName === "Command Centre Plus"
  // );

  //console.log("PLANS", data);

  // alert("this");
  return (
    <div className={classes.root}>
      <BannerImage
        topText={data.mainBanner[0]?.breadcrumb}
        title={data.mainBanner[0]?.title}
        markdown={data.mainBanner[0]?.body}
        imageUrl={getStrapiURL(data.mainBanner[0]?.image.src[0].url)}
        gradient={false}
      />
     <ImageRows
        rows={[
          {
            imageRight: true,
            imageSrc: getStrapiURL(
              data.Right_Image_Row_1[0]?.images[0].src[0].url
            ),
            backgroundSize: "contain",
            description:
              "",
            linkText: "",
            conditions: "",
            content: data.Right_Image_Row_1[0]?.body,
            onClick: function() {
              ////  alert("click") ;
            }
          }
        ]}
        title={data.Right_Image_Row_1[0]?.title}
      />
      <ImageRows
        rows={[
          {
            imageLeft: true,
            imageSrc: getStrapiURL(
              data.Left_Image_Row[0]?.images[0].src[0].url
            ),
            backgroundSize: "contain",
            description:
              "",
            linkText: "",
            conditions: "",
            content: data.Left_Image_Row[0]?.body,
            onClick: function() {
              ////  alert("click") ;
            }
          }
        ]}
        title={data.Left_Image_Row[0]?.title}
      />
      <ImageRows
        rows={[
          {
            imageRight: true,
            imageSrc: getStrapiURL(
              data.Right_Image_Row[0]?.images[0].src[0].url
            ),
            backgroundSize: "contain",
            description:
              "",
            linkText: "",
            conditions: "",
            content: data.Right_Image_Row[0]?.body,
            onClick: function() {
              ////  alert("click") ;
            }
          }
        ]}
        title={data.Right_Image_Row[0]?.title}
      />
      {/* <FullTextRow 
      title={data.Full_Text_Row[0]?.title} hasTitle={true}>
        {data.Full_Text_Row[0]?.body}
    </FullTextRow> */}
    <ImageRows
        rows={[
          {
            imageLeft: true,
            imageSrc: getStrapiURL(
              data.Left_Image_Row_1[0]?.images[0].src[0].url
            ),
            description:
              "",
            linkText: "",
            conditions: "",
            content: data.Left_Image_Row_1[0]?.body,
            onClick: function() {
              ////  alert("click") ;
            }
          }
        ]}
        title={data.Left_Image_Row_1[0]?.title}
      />

    </div>
  );
}
export default View;