import React, { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import ReactMarkDown from "react-markdown/with-html";
import getStrapiURL from "utils/getStrapiURL";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      "& h1": {
        font: "700 48px/66px Avenir",
        letterSpacing: "0",
        color: "#000000"
      },
      "& h3": {
        textAlign: "left",
        font: "700 24px/33px Avenir",
        letterSpacing: "0",
        color: "#000000"
      },
      "& h4": {
        textAlign: "left",
        font: "700 18px/25px Avenir",
        letterSpacing: "0",
        color: "#000000"
      },

      "& h5": {
        textAlign: "left",
        font: "700 18px/25px Avenir",
        letterSpacing: "0",
        color: "#000000"
      },
      "& h6": {
        textAlign: "left",
        font: "700 16px/22px Avenir",
        letterSpacing: "0",
        color: "#000000"
      },
      "& strong": {
        color: "#000",
        opacity: "1"
      },
      "& p, & li": {
        textAlign: "left",
        font: "400 16px/22px Avenir",
        letterSpacing: "0",
        color: "#4F4F4F",
        opacity: "1"
      },
      "& table": {
        margin: "auto",
        overflowX: "scroll"
      },

      // "& table td": {
      //   textAlign: "right",
      //   font: "400 18px/25px Avenir",
      //   letterSpacing: "0",
      //   color: "#000000",
      //   width: "218px",
      //   height: "60px",
      //   boxSizing: "border-box",
      //   opacity: "1"
      // },

      "& table tbody tr:not(:last-child)": {
        borderBottom: "1px solid #CCCCCC"
      },

      "& table thead tr": {
        borderBottom: "1.5px solid #000000"
      },

      "& table th, & table td": {
        textAlign: "right",
        font: "400 18px/25px Avenir",

        letterSpacing: "0",
        color: "#000000",
        width: "218px",
        // height: "60px",
        boxSizing: "border-box",
        padding: "15px",
        opacity: "1"
      },

      "& table th": {
        font: "700 20px/27px Avenir"
      },
      "& a, & li a": {
        textAlign: "left",
        font: "500 16px/22px Avenir",
        letterSpacing: "0",
        color: "#007AB1",
        opacity: "1"
      }
    },
    "@global": {
      "red-asterisk": {
        color: theme.palette.primary.main
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { children, ...rest } = props;

  const node = useRef(null);
  useEffect(
    function() {
      node.current.querySelectorAll("a").forEach(function(node) {
        const original = node.href.replace(window.location.origin, "");
        if (original.includes("/uploads/")) {
          node.href = getStrapiURL(original);
        }
      });
    },
    [node.current]
  );
  return (
    <div className={classes.root} ref={node}>
      <ReactMarkDown source={props.children} {...rest} />
    </div>
  );
}
export default View;
