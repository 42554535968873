import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import ImageRows from "components/ImageRows";
import Article from "components/Article";

import getStrapiURL from "utils/getStrapiURL";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data } = props;

  const history = useHistory();
  let path = history.location.pathname;
  let urlTail = path.split("/").filter(function (item) {
    return item;
  });

  let urlHead = urlTail.reverse().shift();
  urlTail.reverse();
  urlTail = urlTail.join(" > ");

  return (
    <div className={classes.root}>
      <BannerImage
        topText={data.mainBanner.breadcrumb}
        title={data.mainBanner.title}
        markdown={data.mainBanner.body}
        imageUrl={getStrapiURL(data.mainBanner.image.src[0].url)}
        gradient
      />
      <PriceGrid
        titleText={data.Plan_Details.title}
        plans={data.Plan_Details.plans}
        location={location}
        onSelection={setLocation}
        hasDisclaimer={true}
        ctaText={data.Plan_Details.button_text}
        disclaimer={data.Plan_Details.note}
      />
      <ImageRows
        rows={[
          {
            imageLeft: true,
            imageSrc: getStrapiURL(
              data.Left_Image_Row[0] ?.images[0].src[0].url
            ),
            description: "",
            linkText: "",
            conditions: "",
            content: data.Left_Image_Row[0] ?.body
          }
        ]}
        title={"Learn more about resell internet"}
      />
      <BannerImage
        topText={"Mornington Deals"}
        header={true}
        headerText={data.Ads.title}
        markdown={data.Ads.body}
        description={
          "Stay connected wherever you are with our Mobile Internet Devices and Flex Plans."
        }
        imageUrl={getStrapiURL(data.Ads.images[0].src[0].url)}
        ctaButton
        ctaButtonProps={{
          children: data.Ads.button_text,
          onClick: function () {
            history.push("/bundle-and-save");
            
          }
        }}
        gradient
        leftAlign={true}
      />
    </div>
  );
}
export default View;
