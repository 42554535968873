import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import Markdown from "components/Markdown";
import Button from "components/Button";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      height: "100vh",
      margin: "0px 60px 0px 60px"
    },
    container: {
      position: "absolute",
      top: "10%",
      maxWidth: 730
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Markdown>{`
# Oops, that page can’t be found.
### Error code: 404

The page you are trying to visit may have moved, the URL may be incorrect or the page cannot be found. We apologize for any inconvenience this may have caused. 

If you entered in the URL into the address bar, please check the spelling. 

If you are visiting from a bookmarked page, the bookmark may now be outdated.
        `}</Markdown>

        <Button
          variant={props.variant}
          onClick={function(e) {
            e.preventDefault();
            history.push("/");
          }}
        >
          Go back to home
        </Button>
      </div>
    </div>
  );
}
export default View;
