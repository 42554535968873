import React from "react";
import { createUseStyles } from "react-jss";

import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import getStrapiURL from "utils/getStrapiURL";
import { useHistory } from "react-router-dom";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);

  const { data, location, setLocation, locations } = props;
  const history= useHistory();
  const BannerData = data.Main_banner[0];
  const Ads = data.Ads[0];
  const calling_rates = data.calling_rates.map(plan => {
    const a = plan;
    a.planName = `${plan.country} - ${plan.provider}`;
    a.planPriceInterval = "/min";
    return a;
  });

  return (
    <div className={classes.root}>
      <BannerImage
        topText={BannerData?.breadcrumb}
        title={BannerData?.title}
        description={BannerData?.body}
        markdown={BannerData?.body}
        imageUrl={getStrapiURL(BannerData?.image?.src[0].url)}
        gradient
      />
      <PriceGrid
        titleText={"International Calling rates"}
        onTypedValueChange={props.setTypedValue}
        plans={calling_rates}
        location={location}
        onSelection={setLocation}
        locations={data.countries.map(item => item.name)}
        hasDisclaimer={true}
        international={true}
        // TODO add disclaimer to strapi
        disclaimer={
          "Please note: Speeds vary by area - call to confirm availability of ADSL or VDSL."
        }
      />

      <BannerImage
        topText={"Mornington Deals"}
        header={true}
        headerText={data.Ads[0].title}
        markdown={data.Ads[0].body}
        description={
          "Stay connected wherever you are with our Mobile Internet Devices and Flex Plans."
        }
        imageUrl={getStrapiURL(data.Ads[0].images[0].src[0].url)}
        ctaButton
        ctaButtonProps={{
          children: data.Ads[0].button_text,
          onClick: function() {
            history.push("/bundle-and-save");
            
          }
        }}
        gradient
        leftAlign={true}
      />
    </div>
  );
}
export default View;
