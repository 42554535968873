import View from "./view";
import React from "react";

function Map(props) {
  const { title, description, price, image_src, manufacturer, ...rest } = props;
  // //console.log("MAPPROPS", props);
  const mappedProps = {
    imageUrl: image_src,
    phoneModel: title,
    specs: description ? description.specs : [],
    manufacturer: manufacturer
  };
  return <View {...rest} {...mappedProps} />;
}

export default Map;
