import React, { useRef, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import intersect from "utils/intersectionChecker";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      padding: "24px 24px 24px 24px",
      position: "absolute",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #CCCCCC",
      borderRadius: "0px 0px 4px 4px",
      opacity: "1",
      display: "flex",
      flexDirection: "row",
      transition: "all 1s ease"
    },
    subMenuSection: {
      display: "flex",
      margin: 0,
      padding: 0,
      flexDirection: "column",
      marginRight: 24,
      "& li": {
        listStyleType: "none",
        padding: 0
      },
      "& li h3": {
        padding: 0,
        margin: 0
      },
      "& li:last-child h3 a": {}
    },
    subMenuTitle: {
      margin: 0,
      padding: "0px 0px 0px 12px",
      textAlign: "left",
      font: "700 1.125rem/25px Avenir",
      letterSpacing: "0",
      color: ({ variant }) =>
        `${
          theme.palette[variant]
            ? theme.palette[variant].main
            : theme.palette.primary.main
        }`,
      opacity: "1"
    },
    subMenuLink: {
      display: "block",
      padding: "8px 0px 8px 12px",
      textAlign: "left",
      font: "400 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1",
      textDecoration: "none",
      width: "125px",
      boxSizing: "border-box",
      // whiteSpace: "nowrap",
      "&:hover": {
        textDecoration: "none",
        backgroundColor: theme.palette.white.dark
      },
      "&:focus": {
        backgroundColor: theme.palette.white.darker
      }
    },
    currentPath: {
      textAlign: "left",
      textDecoration: "underline",
      font: "700 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000"
    }
  };
});

/**
 *
 * sections = [
 * {
 *  title:"test",
 * links:[
 *  {
 *  content:"test",
 * onClick:function(){},
 * }
 * ]
 * }
 * ]
 */

function ProductbarSubMenu(props) {
  const classes = useStyles(props);
  const { sections } = props;
  const root = useRef(null);
  const history = useHistory();
  useEffect(function() {
    let observer = new IntersectionObserver(function(data) {
      // //console.log(data);
      if (data.length) {
        data = data[0];
        // //console.log(intersect(data.rootBounds, data.boundingClientRect));
        if (!data.rootBounds || !data.boundingClientRect || !root.current)
          return;
        const { leftIntersect, rightIntersect } = intersect(
          data.rootBounds,
          data.boundingClientRect
        );
        if (leftIntersect && root.current) {
          root.current.style.left = "60px";
        } else if (rightIntersect && root.current) {
          root.current.style.right = "60px";
        }
      }
    });
    observer.observe(root.current);
    // root.current.style.display = "flex";
  });
  return (
    <div className={classes.root} ref={root}>
      {sections.map(function({ title, links }) {
        return (
          <ul className={classes.subMenuSection} role="menu">
            {title ? <h2 className={classes.subMenuTitle}>{title}</h2> : ""}
            {links.map(function(link) {
              return (
                <li>
                  <h3>
                    <a
                      {...link}
                      className={`${classes.subMenuLink} ${history.location
                        .pathname === link.href && classes.currentPath}`}
                      onClick={link.onClick}
                    >
                      {link.content}
                    </a>
                  </h3>
                </li>
              );
            })}
          </ul>
        );
      })}
    </div>
  );
}

ProductbarSubMenu.defaultProps = { sections: [] };
export default ProductbarSubMenu;
