import React, { useState, useCallback, useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import MobileNavItem from "../MobileNavItem";

import posed from "react-pose";

const AnimatedDiv = posed.div({
  closedt: {
    height: "0px",
    opacity: 1,
    applyAtEnd: { oveflow: "hidden", display: "none" }
  },
  opent: {
    height: "auto",
    opacity: 1,
    applyAtStart: { oveflow: "hidden", display: "block" }
  }
});

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main
    },
    li: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "0",
      marginBottom: "0",
      listStyle: "none",
      overflow: "hidden"
    },
    bg: {
      backgroundColor: "rgba(0,0,0,0.12)",
      overflow: "hidden"
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const [isOpen, setOpen] = useState(false);
  const { OptionsComponent, paddingLeft, large, enableUnderline } = props;
  const node = useRef(null);
  const menu = useRef(null);

  const cb = useCallback(
    function handler(e) {
      if (node.current && node.current.contains(e.target)) {
        if (!menu.current.contains(e.target)) setOpen(!isOpen);
        return;
      }
      setOpen(false);
    },
    [isOpen]
  );

  useEffect(
    function() {
      document.addEventListener("click", cb, false);
      return function() {
        document.removeEventListener("click", cb, false);
      };
    },
    [isOpen, cb]
  );

  return (
    <div
      className={classes.li}
      onClick={function() {
        setOpen(true);
      }}
      ref={node}
    >
      <MobileNavItem
        variant={props.variant}
        dropDown
        caret
        paddingLeft={paddingLeft || 0}
        isOpen={isOpen}
        active={isOpen}
        large={large}
        enableUnderline={enableUnderline}
      >
        {props.children}
      </MobileNavItem>

      <AnimatedDiv
        pose={isOpen ? "opent" : "closedt"}
        withParent={false}
        ref={menu}
      >
        {/* <MobileNavItem
          variant={"primary"}
          dropDown
          caret
          paddingLeft={0}
          isOpen={openOption === "d"}
        > */}
        {/* </MobileNavItem> */}
        {OptionsComponent && (
          <div className={classes.bg}>
            <OptionsComponent />
          </div>
        )}
      </AnimatedDiv>
    </div>
  );
}
export default View;
