import { SET_PHONE } from "store/constants/phoneViewer";

import _ from "underscore";

const initialState = {
  imageUrl: "/assets/images/phones/galaxy-note-10-plus.png",
  images: [
    {
      images: [
        "/assets/images/phones/galaxy-note-10-plus.png",
        "/assets/images/phones/galaxy-note-10-plus.png",
        "/assets/images/phones/galaxy-note-10-plus.png"
      ],
      color: "red"
    },
    {
      images: [
        "/assets/images/phones/galaxy-note-10-plus.png",
        "/assets/images/phones/galaxy-note-10-plus.png",
        "/assets/images/phones/galaxy-note-10-plus.png"
      ],
      color: "blue"
    }
  ],
  phoneModel: "Galaxy Note 10+",
  phoneBrand: "Samsung",
  colors: ["red", "blue"],
  specs: [
    "Storage: 256GB/512GB",
    "Display: 6.8” Quad HD+",
    "Camera: Rear quad camera (Ultra Wide, Wide-angle, Telephoto, Depth Vision) with 10MP Dual Pixel front camera",
    "Intelligent S Pen included",
    "Waterproof up to 1.5m deep for 30 minutes (IP68 rating)",
    " Available in: Aura Black"
  ]
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PHONE: {
      const nextState = _.clone(action.payload);
      return nextState;
    }
    default: {
      return state;
    }
  }
}

export default reducer;
