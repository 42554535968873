import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useMediaQuery } from "react-responsive";
import PaginationGrid, { Skeleton } from "components/PaginationGrid";
import PhoneCard from "components/PhoneCard";
import PhoneGridSortButtonList from "components/PhoneGrid/PhoneGridSortButtonList";
import PhoneGridSortButton from "components/PhoneGrid/PhoneGridSortButton";
import PhoneGridFilterGroup from "components/PhoneGrid/PhoneGridFilterGroup";
import { motion } from "framer-motion";
// import Skeleton from "react-loading-skeleton";

import _ from "underscore";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      width: "100%",
      padding: "50px 0px 0px 0px",
      maxWidth: 1366,
      margin: "0px auto 0px auto"
    },
    "@media (max-width: 700px)": {
      root: {
        padding: "50px 30px 0px 30px"
      }
    }
  };
});

const aPhone = {
  imageUrl: "/assets/images/phones/galaxy-note-10-plus.png",
  phoneModel: "Galaxy Note 10+",
  manufacturer: "Samsung",
  os: "android",
  colors: [
    {
      color: "red",
      onClick: function() {
        //console.log("selected-red-color");
      },
      active: true
    },
    {
      color: "blue",
      onClick: function() {
        //console.log("selected-blue-color");
      }
    }
  ],
  specs: [
    "Storage: 256GB/512GB",
    "Display: 6.8” Quad HD+",
    "Camera: Rear quad camera (Ultra Wide, Wide-angle, Telephoto, Depth Vision) with 10MP Dual Pixel front camera",
    "Intelligent S Pen included",
    "Waterproof up to 1.5m deep for 30 minutes (IP68 rating)",
    " Available in: Aura Black"
  ],
  onClick: function() {
    //console.log("phone card button clicked");
  }
};

const bPhone = {
  imageUrl: "/assets/images/phones/galaxy-note-10-plus.png",
  phoneModel: "Apple Iphone",
  manufacturer: "Apple",
  os: "ios",

  colors: [
    {
      color: "red",
      onClick: function() {
        //console.log("selected-red-color");
      },
      active: true
    },
    {
      color: "blue",
      onClick: function() {
        //console.log("selected-blue-color");
      }
    }
  ],
  specs: [
    "Storage: 256GB/512GB",
    "Display: 6.8” Quad HD+",
    "Camera: Rear quad camera (Ultra Wide, Wide-angle, Telephoto, Depth Vision) with 10MP Dual Pixel front camera",
    "Intelligent S Pen included",
    "Waterproof up to 1.5m deep for 30 minutes (IP68 rating)",
    " Available in: Aura Black"
  ],
  onClick: function() {
    //console.log("phone card button clicked");
  }
};

const sortTypes = {
  AZ(data) {
    const nextData = _.clone(data);
    nextData.sort(function(a, b) {
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      if (b.title > a.title) {
        return -1;
      }
      return 0;
    });
    return nextData;
  },
  ZA(data) {
    const nextData = _.clone(data);
    nextData.sort(function(a, b) {
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return -1;
      }
      if (b.title.toLowerCase() > a.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return nextData;
  },
  identity(data) {
    return data;
  },
  prepaid(data) {
    return data.filter(a => a.prepaid === "Yes");
  },
  notPrepaid(data) {
    return data.filter(a => a.prepaid === "No");
  }
};
function PB3({ addFilter, removeFilter, filters, data, setSortFn }) {
  const [sort, setSort] = useState("");
  const [manufacturerFilter, setManufacturerFilter] = useState([]);
  const [osFilter, setOsFilter] = useState([]);
  const [prepaid, setPrepaid] = useState([]);

  function toggleFilter(filterName, filterFunction) {
    if (filters.some(filter => filter.filterName === filterName)) {
      removeFilter(filterName);
    } else {
      addFilter(filterName, filterFunction);
    }
  }

  const manufacturers = Object.keys(
    data.reduce((acc, item) => {
      acc[item.manufacturer] = 1;
      return acc;
    }, {})
  );

  const operatingSystems = Object.keys(
    data.reduce((acc, item) => {
      acc[item.os] = 1;
      return acc;
    }, {})
  );
  return (
    <PhoneGridSortButtonList>
      <PhoneGridSortButton
        options={[
          {
            content: "Alphabetical (A-Z)",
            selected: sort === "Alphabetical (A-Z)",
            onClick: function() {
              setSortFn(sortTypes.AZ);
              setSort("Alphabetical (A-Z)");
              // const res = sortTypes.AZ(data);
              // setData(res);
            }
          },
          {
            content: "Alphabetical (Z-A)",
            selected: sort === "Alphabetical (Z-A)",
            onClick: function() {
              setSortFn(sortTypes.ZA);

              setSort("Alphabetical (Z-A)");

              // const res = sortTypes.ZA(data);
              // setData(res);
            }
          }
        ]}
      >
        Sort by
      </PhoneGridSortButton>
      <PhoneGridFilterGroup
        manufacturers={manufacturers}
        operatingSystems={operatingSystems}
        Comp={props => {
          return (
            <>
              <i class="fas fa-filter"></i> Filters
            </>
          );
        }}
      >
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ height: 0 }}
          style={{ overflow: "hidden" }}
        >
          <PhoneGridSortButton
            options={manufacturers.map(function(manufacturer) {
              const active = manufacturerFilter.indexOf(manufacturer) >= 0;
              return {
                content: manufacturer,
                selected: active,
                onClick: function() {
                  if (active) {
                    const newList = manufacturerFilter.filter(function(item) {
                      return item !== manufacturer;
                    });
                    setManufacturerFilter(newList);
                    removeFilter(manufacturer);
                  } else {
                    setManufacturerFilter([
                      ...manufacturerFilter,
                      manufacturer
                    ]);
                    addFilter(manufacturer, function(data) {
                      return data.filter(function(item) {
                        return item.manufacturer === manufacturer;
                      });
                    });
                  }
                }
              };
            })}
          >
            Manufacturer
          </PhoneGridSortButton>
          <PhoneGridSortButton
            options={operatingSystems.map(function(os) {
              const active = osFilter.indexOf(os) >= 0;
              return {
                content: os,
                selected: active,
                onClick: function() {
                  if (active) {
                    const newList = osFilter.filter(function(item) {
                      return item !== os;
                    });
                    setOsFilter(newList);
                    removeFilter(os);
                  } else {
                    setOsFilter([...osFilter, os]);
                    addFilter(os, function(data) {
                      return data.filter(function(item) {
                        return item.os === os;
                      });
                    });
                  }
                }
              };
            })}
          >
            Operating System
          </PhoneGridSortButton>
          <PhoneGridSortButton
            options={[
              {
                content: "Yes",
                selected: prepaid === "Yes",
                onClick: function() {
                  //console.log(filters);
                  removeFilter("Not Prepaid");

                  if (
                    filters.some(a => a.filterName === "Prepaid") ||
                    prepaid === "Yes"
                  ) {
                    removeFilter("Prepaid");
                    setPrepaid("");
                  } else {
                    addFilter("Prepaid", sortTypes.prepaid);
                    setPrepaid("Yes");
                  }
                }
              },
              {
                content: "No",
                selected: prepaid === "No",
                onClick: function() {
                  //console.log(filters);
                  removeFilter("Prepaid");

                  if (
                    filters.some(a => a.filterName === "Not Prepaid") ||
                    prepaid === "No"
                  ) {
                    removeFilter("Not Prepaid");
                    setPrepaid("");
                  } else {
                    addFilter("Not Prepaid", sortTypes.notPrepaid);
                    setPrepaid("No");
                  }
                }
              }
            ]}
          >
            Prepaid
          </PhoneGridSortButton>
        </motion.div>
      </PhoneGridFilterGroup>
    </PhoneGridSortButtonList>
  );
}

function PB2({ addFilter, removeFilter, filters, data, setSortFn }) {
  const [sort, setSort] = useState("");
  const [manufacturerFilter, setManufacturerFilter] = useState([]);
  const [osFilter, setOsFilter] = useState([]);
  const [prepaid, setPrepaid] = useState([]);

  function toggleFilter(filterName, filterFunction) {
    if (filters.some(filter => filter.filterName === filterName)) {
      removeFilter(filterName);
    } else {
      addFilter(filterName, filterFunction);
    }
  }

  const manufacturers = Object.keys(
    data.reduce((acc, item) => {
      acc[item.manufacturer] = 1;
      return acc;
    }, {})
  );

  const operatingSystems = Object.keys(
    data.reduce((acc, item) => {
      acc[item.os] = 1;
      return acc;
    }, {})
  );
  return (
    <PhoneGridSortButtonList>
      <PhoneGridSortButton
        options={[
          {
            content: "Alphabetical (A-Z)",
            selected: sort === "Alphabetical (A-Z)",
            onClick: function() {
              setSortFn(sortTypes.AZ);
              setSort("Alphabetical (A-Z)");

              // const res = sortTypes.AZ(data);
              // //console.log(res);
              // setData(res);
            }
          },
          {
            content: "Alphabetical (Z-A)",
            selected: sort === "Alphabetical (Z-A)",
            onClick: function() {
              setSortFn(sortTypes.ZA);
              setSort("Alphabetical (Z-A)");
              // const res = sortTypes.ZA(data);

              // //console.log(res);

              // setData(res);
            }
          }
        ]}
      >
        Sort by
      </PhoneGridSortButton>
      <PhoneGridSortButton
        options={manufacturers.map(function(manufacturer) {
          const active = manufacturerFilter.indexOf(manufacturer) >= 0;
          return {
            content: manufacturer,
            selected: active,
            onClick: function() {
              if (active) {
                const newList = manufacturerFilter.filter(function(item) {
                  return item !== manufacturer;
                });
                setManufacturerFilter(newList);
                removeFilter(manufacturer);
              } else {
                setManufacturerFilter([...manufacturerFilter, manufacturer]);
                addFilter(manufacturer, function(data) {
                  return data.filter(function(item) {
                    return item.manufacturer === manufacturer;
                  });
                });
              }
            }
          };
        })}
      >
        Manufacturer
      </PhoneGridSortButton>
      <PhoneGridSortButton
        options={operatingSystems.map(function(os) {
          const active = osFilter.indexOf(os) >= 0;
          return {
            content: os,
            selected: active,
            onClick: function() {
              if (active) {
                const newList = osFilter.filter(function(item) {
                  return item !== os;
                });
                setOsFilter(newList);
                removeFilter(os);
              } else {
                setOsFilter([...osFilter, os]);
                addFilter(os, function(data) {
                  return data.filter(function(item) {
                    return item.os === os;
                  });
                });
              }
            }
          };
        })}
      >
        Operating System
      </PhoneGridSortButton>
      <PhoneGridSortButton
        options={[
          {
            content: "Yes",
            selected: prepaid === "Yes",
            onClick: function() {
              //console.log(filters);
              removeFilter("Not Prepaid");

              if (
                filters.some(a => a.filterName === "Prepaid") ||
                prepaid === "Yes"
              ) {
                removeFilter("Prepaid");
                setPrepaid("");
              } else {
                addFilter("Prepaid", sortTypes.prepaid);
                setPrepaid("Yes");
              }
            }
          },
          {
            content: "No",
            selected: prepaid === "No",
            onClick: function() {
              //console.log(filters);
              removeFilter("Prepaid");

              if (
                filters.some(a => a.filterName === "Not Prepaid") ||
                prepaid === "No"
              ) {
                removeFilter("Not Prepaid");
                setPrepaid("");
              } else {
                addFilter("Not Prepaid", sortTypes.notPrepaid);
                setPrepaid("No");
              }
            }
          }
        ]}
      >
        Prepaid
      </PhoneGridSortButton>
    </PhoneGridSortButtonList>
  );
}

function Test({ data }) {
  return (
    <PaginationGrid
      Renderer={Skeleton}
      itemsPerRow={4}
      pageSize={8}
      buttons
      data={data}
      ButtonsComponent={PB2}
    />
  );
}

function PhoneGrid(props) {
  //console.log("graphql", props.data);
  const classes = useStyles(props);
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1200px)" });
  const data = [...Array(100).fill(aPhone), ...Array(57).fill(bPhone)];

  if (props.data.loading) {
    return (
      <section className={classes.root}>
        <Skeleton width={"100%"} height={"1000px"} />
      </section>
    );
  }

  if (props.data.error) {
    return (
      <section className={classes.root}>
        {!isMobile && isTablet && (
          <Skeleton itemsPerRow={2} pageSize={8} buttons />
        )}

        {isMobile && isTablet && (
          <Skeleton itemsPerRow={1} pageSize={8} buttons />
        )}

        {!isMobile && !isTablet && (
          <Skeleton itemsPerRow={4} pageSize={8} buttons />
        )}
      </section>
    );
  }
  console.log(JSON.stringify(props.data.products));

  return (
    <section className={classes.root}>
      {!isMobile && isTablet && (
        <PaginationGrid
          Renderer={PhoneCard}
          itemsPerRow={2}
          pageSize={8}
          buttons
          data={props.data.products}
          ButtonsComponent={PB2}
        />
      )}

      {isMobile && isTablet && (
        <PaginationGrid
          Renderer={PhoneCard}
          itemsPerRow={1}
          pageSize={4}
          buttons
          data={props.data.products}
          ButtonsComponent={PB3}
        />
      )}

      {!isMobile && !isTablet && (
        <PaginationGrid
          Renderer={PhoneCard}
          itemsPerRow={4}
          pageSize={8}
          buttons
          data={props.data.products}
          ButtonsComponent={PB2}
        />
      )}
    </section>
  );
}

export default PhoneGrid;
