import { useQuery } from "@apollo/react-hooks";
import Skeleton from "components/Spinner";
import gql from "graphql-tag";
import React from "react";
import View from "./view";

const QUERY = gql`
  query getBundleAndSave {
    content: bundleAndSaves {
      mainBanner: Banner {
        body
        button_text
        image {
          src {
            url
          }
          image_title
          alt_text
        }
      }
      Ads {
        title
        body
        button_text
        images {
          image_title
          src {
            url
          }
          alt_text
          type
        }
      }

      Card {
        title
        markdown: body

        button_text
      }

      Left_Image_Row: Left_image_row {
        title
        body
        images {
          image_title
          alt_text
          src {
            url
          }
        }
      }
    }
  }
`;

function GraphContainer() {
  const { loading, error, data } = useQuery(QUERY);

  return loading || error || !data ? (
    <Skeleton />
  ) : (
    <View data={data.content[0]}></View>
  );
}

export default GraphContainer;
