/**
 * @description Price grid card component, this component renders the price card for individual phone plams
 * @author  carlosintrigue
 */
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Skeleton from "react-loading-skeleton";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #CCCCCC",
      borderRadius: "4px",
      opacity: "1",
      width: "532px",
      maxWidth: "80%",
      display: "flex",
      flexDirection: "row",
      position: "relative",
      padding: 16,
      boxSizing: "border-box",
      justifyContent: "center"
    },
    planPriceContainer: {
      textAlign: "right",
      font: "700 36px/49px Avenir",
      letterSpacing: "0",
      color: "#AE2649",
      opacity: "1",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexDirection: "column"
    },
    separator: {
      minHeight: "90%",
      width: "1px",
      backgroundColor: theme.palette.gray.dark,
      margin: "0px 16px 0px 16px",
      boxSizing: "border-box"
    },
    cardHeader: {
      textAlign: "left",
      font: "700 20px/27px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    md: {
      color: "#000000",
      "& ul": {
        margin: 0,
        // ui styles
        textAlign: "left",
        font: "400 16px/22px Avenir",
        letterSpacing: "0",
        color: "#000000",
        padding: "0px 0px 0px 16px",
        "& > li:not(:last-child)": {
          marginBottom: 8
        }
      }
    },
    "@media (max-width: 700px)": {
      root: {
        flexDirection: "column"
      },
      separator: {
        minWidth: "90%",
        height: "1px",
        margin: "16px 0px 16px 0px"
      },
      planPriceContainer: {
        flexDirection: "row"
      }
    }
  };
});

function PriceGridCard(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <div>
        <Skeleton count={10} width={20} height={16} />
      </div>
      <div className={classes.separator}></div>
      <div>
        <Skeleton count={10} width={50} height={16} />
        <Skeleton count={1} width={50} height={16} />
        <Skeleton count={1} width={50} height={16} />
        <Skeleton count={1} width={50} height={16} />
        <Skeleton count={1} width={50} height={16} />
        <Skeleton count={1} width={50} height={16} />
      </div>
    </div>
  );
}

PriceGridCard.defaultProps = {
  planPrice: 44,
  planName: "Ultra Plus",
  planDetails: [
    { type: "description", content: "Details about this plan" },
    { type: "description", content: "Details about this plan" },
    { type: "link", content: "Details about this plan", href: "/" }
  ]
};

PriceGridCard.propTypes = {
  /**Plan price that will be displayed in the card */
  planPrice: PropTypes.number,
  /**Plan Name that will be displayed on top of the details list */
  planName: PropTypes.string,
  /**Plan Details array Each plan details obj will be either a description or a link */
  planDetaile: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.string,
      href: PropTypes.string
    })
  )
};
export default PriceGridCard;
