import React, { useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import SyncLoader from "react-spinners/SyncLoader";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      },
      display: "flex",
      width: "100%",
      minHeight: "100vh",
      justifyContent: "center",
      alignItems: "center"
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    spinnerContainer: {
      transform: "translateY(-50%)"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <div className={classes.spinnerContainer}>
        {" "}
        <SyncLoader
          size={20}
          //size={"150px"} this also works
          color={theme.palette.primary.main}
          loading={true}
        />
      </div>
    </div>
  );
}
export default View;
