import  axios  from 'axios';
const  API_URL="https://loop.mornington.ca/process/contact.php";
//'https://webhook.site/78bc7315-6dd6-400b-bf6e-3fe977b79f1b'
export const sendForm=(formData)=>{
    return axios.post(API_URL, {
        data:JSON.stringify(formData)
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
}