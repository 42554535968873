import React from "react";
import { createUseStyles } from "react-jss";
import { useMediaQuery } from "react-responsive";

import PaginationGrid from "components/PaginationGrid";
import GridCard from "components/GridCard";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: theme.palette.primary.main
    }
  };
});

const testData = [
  {
    title: "Fibre",
    downloadSpeed: "1 Gbps",
    uploadSpeed: "500 Mbps",
    monthlyUsage: "Unlimited",
    onClick: function () {
      // //console.log("phone card button clicked");
    }
  },
  {
    title: "DSL",
    downloadSpeed: "20 Mbps",
    uploadSpeed: "1 Mbps",
    monthlyUsage: "Unlimited",
    onClick: function () {
      // //console.log("phone card button clicked");
    }
  },
//  {
//    title: "Wireless",
//    downloadSpeed: "8 Mbps",
//    uploadSpeed: "2 Mbps",
//    monthlyUsage: "Unlimited",
//    onClick: function () {
//      // //console.log("phone card button clicked");
//    }
//  },
  {
    title: "Resell Internet",
    downloadSpeed: "1 Gbps",
    uploadSpeed: "500 Mbps",
    monthlyUsage: "Unlimited",
    onClick: function () {
      // //console.log("phone card button clicked");
    }
  }
];

function InternetGrid(props) {
  const classes = useStyles(props);
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1200px)" });

  const { options, title } = props;


  return (
    <section className={classes.root}>
      {/* Mobile */}
      {isMobile && isTablet && (
        <PaginationGrid
          title={title}
          buttons={false}
          pageSize={4}
          itemsPerRow={1}
          pagination={false}
          data={options}
          Renderer={GridCard}
        />
      )}
      {/* Tablet */}

      {!isMobile && isTablet && (
        <PaginationGrid
          title={title}
          buttons={false}
          pageSize={4}
          itemsPerRow={2}
          pagination={false}
          data={options}
          Renderer={GridCard}
        />
      )}
      {/* Desktop */}

      {!isMobile && !isTablet && (
        <PaginationGrid
          title={title}
          buttons={false}
          pageSize={4}
          itemsPerRow={4}
          pagination={false}
          data={options}
          Renderer={GridCard}
        />
      )}
    </section>
  );
}
export default InternetGrid;
