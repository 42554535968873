import React from "react";
import Typeahead from "components/Typeahead";

// type State = {
//   lat: number,
//   lng: number,
//   zoom: number,
// }

export default class SimpleExample extends React.Component {
  state = {
    lat: 51.505,
    lng: -0.09,
    zoom: 13
  };

  render() {
    //const position = [this.state.lat, this.state.lng];
    return (
      <div style={{ width: "500px", height: "300px" }}>
        <Typeahead
          locations={[
            { label: "hello" },
            { label: "hrello" },
            { label: "heltlo" }
          ]}
        />
        {/* <Input type="text" placeholder="testing" />
        <Map
          center={position}
          zoom={this.state.zoom}
          style={{ width: "500px", height: "300px" }}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}></Marker>
        </Map> */}
      </div>
    );
  }
}
