/**
 * @description Price grid card component, this component renders the price card for individual phone plams
 * @author  carlosintrigue
 */
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Markdown from "components/Markdown";
const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      opacity: "1",
      width: "100%",
      maxWidth: "100%",
      position: "relative",
      padding: "8px 16px",
      boxSizing: "border-box",
      justifyContent: "left",
      "margin-bottom": "0 !important",
    },
    planPriceContainer: {
      // textAlign: "right",
      font: "700 16px/22px Avenir",
      letterSpacing: "0",
      color: ({ variant }) =>
        theme.palette[variant]
          ? theme.palette[variant].main
          : theme.palette.primary.main,
      opacity: "1",
      textAlign: "left",
      width: "100%",
    },
    planNotes: {
      "margin-bottom": "0 !important"
    },
    cardHeader: {
      textAlign: "left",
      font: "700 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    md: {
      color: "#000000",
      "& ul": {
        margin: 0,
        // ui styles
        opacity: "1 !important",
        textAlign: "left",
        font: "400 16px/22px Avenir",
        letterSpacing: "0",
        color: "#000000",
      },
      "& li": {
        opacity: "1 !important"
      }
    },
    contentContainer: {
      width: "auto"
    },
    "@media (max-width: 700px)": {
      root: {
      },
      planPriceContainer: {
        width: "100%",
        "& sub": {
          bottom: 0,
          display: "block",
          height: "auto",
        }
      }
    }
  };
});

function formatMoney(num, n, x) {
  if (num === null || num === undefined) return "Contact us";
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
  return num.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
}

function PhonePriceGridCard(props) {
  const classes = useStyles(props);
  const { planDetails, planPrice, planName, planPriceInterval } = props;

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <span className={classes.cardHeader}>{planName}</span>

        <div className={classes.md}>
          <p className={classes.planNotes}>{planDetails}</p>
          <span className={classes.planPriceContainer}>
          {`${
            planPrice !== null && planPrice !== undefined
              ? `$${formatMoney(planPrice, 2, 3)}`
              : `Contact us`
          }`}
        </span>
        </div>
      </div>
    </div>
  );
}

PhonePriceGridCard.defaultProps = {
  planPrice: 44,
  planName: "Ultra Plus",
  planDetails: [
    { type: "description", content: "Details about this plan" },
    { type: "description", content: "Details about this plan" },
    { type: "link", content: "Details about this plan", href: "/" }
  ],
  planPriceInterval: ""
};

PhonePriceGridCard.propTypes = {
  /**Plan price that will be displayed in the card */
  planPrice: PropTypes.number,
  /**Plan Name that will be displayed on top of the details list */
  planName: PropTypes.string,
  /**Plan Details array Each plan details obj will be either a description or a link */
  planDetails: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.string,
      href: PropTypes.string
    })
  ),
  planPriceInterval: PropTypes.string
};
export default PhonePriceGridCard;
