const personalTheme = {
  palette: {
    primary: {
      main: "#AE2649",
      dark: "#841D37",
      darker: "#5A1426",
      light: "#D3345D",
      lighter: "#DC5E7E",
      contrast: "#ffffff"
    },
    secondary: {
      main: "#000000",
      dark: "#333333",
      darker: "#1A1A1A",
      light: "#4D4D4D",
      lighter: "#666666",
      contrast: "#ffffff"
    },
    tertiary: {
      main: "#007FB8",
      dark: "#005C85",
      darker: "#003952",
      light: "#00A2EB",
      lighter: "#1FBAFF",
      contrast: "#ffffff"
    },
    white: {
      light: "#FFFFFF",
      lighter: "#FFFFFF",
      main: "#FFFFFF",
      dark: "#E6E6E6",
      darker: "#CCCCCC",
      contrast: "black"
    },
    gray: {
      lighter: "#F7F7F7",
      light: "#E6E6E6",
      dark: "#CCCCCC",
      darker: "#B3B3B3"
    },
    accent: {
      salmon: "#EF7674",
      cyan: "#00BFB2",
      red: "#DE1A1A",
      purple: "#631D76",
      vanilla: "#F4E8C1",
      blue: "#007AB1"
    }
  }
};
export default personalTheme;
