import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import ImageRows from "components/ImageRows";
import Article from "components/Article";
import Container from "components/Container";
import { useHistory } from "react-router-dom";
import getStrapiURL from "utils/getStrapiURL";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data } = props;
  const history=useHistory();
  const plans = data.Plan_Details.plans;

  // const filtered = plans.filter(
  //   ob =>
  //     ob.planName !== "Command Centre" && ob.planName !== "Command Centre Plus"
  // );

  // const commandCentre = plans.filter(ob => ob.planName === "Command Centre");

  // const commandCentrePlus = plans.filter(
  //   ob => ob.planName === "Command Centre Plus"
  // );

  //console.log("PLANS", data);

  // alert("this");
  return (
    <div className={classes.root}>
      <BannerImage
        topText={data.mainBanner[0]?.breadcrumb}
        title={data.mainBanner[0]?.title}
        markdown={data.mainBanner[0]?.body}
        imageUrl={getStrapiURL(data.mainBanner[0]?.image.src[0].url)}
        gradient
      />

      <PriceGrid
        plans={data.Plan_Details[0].plans}
        location={location}
        onSelection={setLocation}
        hasDisclaimer={true}
        ctaText={data.Plan_Details[0].button_text}
        // TODO add disclaimer to strapi
        hasTitle={true}
        titleText={data.Plan_Details[0].title}
        disclaimer={data.Plan_Details[0].note}
      />

      {/* <PriceGrid
        titleText={""}
        hasTitle={false}
        hasLocation={false}
        plans={commandCentre}
      /> */}

      <PriceGrid
        plans={data.Plan_Details[1].plans}
        location={location}
        onSelection={setLocation}
        hasDisclaimer={true}
        ctaText={data.Plan_Details[1].button_text}
        // TODO add disclaimer to strapi
        hasTitle={true}
        titleText={data.Plan_Details[1].title}
        hasLocation={false}
        disclaimer={data.Plan_Details[1].note}
      />

      <BannerImage
        header={true}
        headerText={data.Ads[0].title}
        markdown={data.Ads[0].body}
        imageUrl={getStrapiURL(data.Ads[0].images[0].src[0].url)}
        ctaButton
        ctaButtonProps={{
          children: data.Ads[0].button_text,
          onClick: function() {
            history.push("/bundle-and-save");
            
          }
        }}
        gradient
        leftAlign={true}
      />
    </div>
  );
}
export default View;
