/**
 * @description This is the view layer for the InternetCard component.
 * Component is used in PhoneGrid under wireless>phones screen
 * @author  carlosintrigue
 */
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Button from "components/Button";
import Markdown from "components/Markdown";
import getStrapiURL from "utils/getStrapiURL";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      width: 300
    },
    rootUIProps: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #CCCCCC",
      borderRadius: "4px",
      opacity: "1",
      boxSizing: "border-box",
      "& > :not(:last-child)": {
        /** Add a border bottom to all card sections except the last */
        borderBottom: "1px solid #CCCCCC"
      }
    },
    image: {
      display: "flex",
      flexDirection: "row",
      height: "400px",
      width: "auto",
      alignItems: "center",
      "& img": {
        width: "100%",
        height: "auto"
      }
    },
    header: {
      position: "relative",
      zIndex: "10",
      boxSizing: "border-box",
      padding: 16,

      "& div h1": {
        margin: 0,
        display: "block",
        fontSize: "16px"
      }
    },
    headerTitle: {
      textAlign: "left",
      font: "700 18px/25px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    headerSubtitle: {
      textAlign: "left",
      font: "700 12px/16px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    headerDotButtons: {
      position: "absolute",
      right: 16,
      bottom: 16,
      boxSizing: "border-box",
      "& > :not(:last-child)": {
        marginRight: 8
      }
    },
    content: {
      position: "relative",
      zIndex: "10",
      boxSizing: "border-box",
      padding: 16,
      flexGrow: 1,
      "& p, & li": { opacity: "1 !important" }
    },
    attributeName: {
      textAlign: "left",
      font: "500 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    attributeValue: {
      textAlign: "left",
      font: "700 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    contentList: {
      // reset styles for ul
      margin: 0,
      // ui styles
      textAlign: "left",
      font: "400 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      padding: "0px 0px 0px 16px"
    },
    buttonArea: {
      position: "relative",
      zIndex: "10",
      display: "flex",
      justifyContent: "center",
      boxSizing: "border-box",
      padding: "16px 0px 16px 0px"
    }
  };
});

function InternetCard(props) {
  const classes = useStyles(props);
  const { title, markdown, buttonText, onClick, imageUrl } = props;
  //console.log(props);
  return (
    <article className={`${classes.root} ${classes.rootUIProps}`}>
      {imageUrl && (
        <div className={classes.image}>
          <img src={getStrapiURL(imageUrl)}></img>
        </div>
      )}

      {title && (
        <div className={classes.header}>
          <div>
            <h1>
              <span className={classes.headerTitle}>{title}</span>
            </h1>
          </div>
        </div>
      )}

      <div className={classes.content}>
        <Markdown>{markdown}</Markdown>
      </div>

      {buttonText && (
        <div className={classes.buttonArea}>
          <Button onClick={onClick}>{buttonText}</Button>
        </div>
      )}
    </article>
  );
}

InternetCard.propTypes = {
  /**
   * Title for the Internet service type
   */
  title: PropTypes.string,
  /**
   * A string repesenting internet download speed
   */
  buttonText: PropTypes.string,
  /**
   * A string representimg markdown contents
   */
  markdown: PropTypes.string,
  /**
   * Callback function for the card's button
   */
  onClick: PropTypes.func,
  /**
   * Url source for image in card
   */
  imageUrl: PropTypes.string
};

InternetCard.defaultProps = {
  title: "Fibre",
  buttonText: "",
  uploadSpeed: "500 Mbps",
  monthlyUsage: "Unlimited",
  onClick: function() {
    //console.log("phone card button clicked");
  }
};

export default InternetCard;
