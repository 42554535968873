import React from "react";
import { createUseStyles } from "react-jss";
import { useMediaQuery } from "react-responsive";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

function ProductBar(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  return !isMobile ? <Desktop {...props} /> : <Mobile {...props} />;
}

export default ProductBar;
