import { connect } from "react-redux";
import View from "./view";

function mapStateToProps(state) {
  return {
    /* REDUX STATE IS NOT NEEDED HERE AS THIS INFORMATION NEED NOT BE REMEMBERED ELSE WHERE IN THE APP*/
   // ...state.phoneViewer
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
