import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import BannerImage from "components/BannerImage";
import PriceGrid from "components/PriceGrid";
import ImageRows from "components/ImageRows";
import FullTextRow from "components/FullTextRow";
import FormRow from "components/FormRow";
import getStrapiURL from "utils/getStrapiURL";
import Container from "components/Container/view";
import FeedbackFormAccessibility from "components/FeedbackFormAccessibility";
import Markdown from "components/Markdown/view";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    },
    title: {
        font: "700 48px/56px Coast",
        marginBottom: "24px",
        marginTop: "48px",
        textAlign: "left",
        color: "black"
      },
    md: {
        margin: "20px 0px 20px 0px"
     },
     row:{
        maxWidth: 900,
      marginRight: "auto",
      marginLeft: "auto"
     }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { location, setLocation, data, variant } = props;

  return (
    <div className={classes.root}>
      <BannerImage
        markdown={data.mainBanner.body}
        imageUrl={getStrapiURL(data.mainBanner.image.src[0].url)}
        ctaButton={data.mainBanner.button_text}
        ctaButtonProps={{
          children: data.mainBanner.button_text,
          onClick: function() {
            ////  alert("click") ;
          }
        }}
        gradient
      />
      <FullTextRow>{data.Full_Text_Row[0]?.body}</FullTextRow>
      <div className={classes.row}>
            <h2 className={classes.title}>{data.Full_Text_Row[0]?.title}</h2>            
            <FeedbackFormAccessibility variant={variant} />
      </div>
      
      
    </div>
  );
}
export default View;