import "./polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "intersection-observer";
import "@material/react-ripple/dist/ripple.css";
// import "leaflet/dist/leaflet.css";

// Animation polyfills
import "core-js"; // <- at the top of your entry point

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "core-js/es/object";
import "core-js/es/array";

import * as serviceWorker from "./serviceWorker";
import Router from "router";
// import "bootstrap/dist/css/bootstrap.min.css";
import * as test from "theme/personal-theme.scss";
import { Button } from "react-bootstrap";
import injectSheet, { ThemeProvider } from "react-jss";
import { PersonalTheme } from "theme";
import { ApolloProvider } from "@apollo/react-hooks";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose  } from "redux";
import { ToastContainer, toast } from 'react-toastify'; 
import rootReducer from "store/reducers";
import client from "api";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
//ReactGA.initialize('UA-38446621-1');
//ReactGA.pageview(window.location.pathname + window.location.search);
TagManager.initialize({gtmId: 'G-8SFN59074X'}); //AW-11012258527
ReactPixel.init('642518740636843');
ReactPixel.pageView();

if (!Object.entries) {
  Object.entries = function(obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array while (i--)
    resArray[i] = [ownProps[i], obj[ownProps[i]]];
    return resArray;
  };
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer,composeEnhancers(applyMiddleware()));

const App = function() {
  return (
    <>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={PersonalTheme}>
            <ToastContainer/>
            <Router />
          </ThemeProvider>
        </ApolloProvider>
      </Provider>
    </>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
