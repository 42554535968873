import gql from "graphql-tag";

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import useLocation from "hooks/useLocation";
import View from "./view";
import Skeleton from "components/Spinner";
const GET_FIBRES = gql`
  query businessWirelesses($where: JSON) {
    content: businessWirelesses {
      mainBanner: Banner {
        body
        button_text
        image {
          src {
            url
          }
          image_title
          alt_text
        }
      }

      Plan_Details: Plan_details {
        title
        note
        button_text
        plans(where: $where) {
          planName: name
          planPrice: price
          planDetails: details
          locations {
            name
          }
        }
      }
      Ads {
        title
        body
        button_text
        images {
          title: image_title
          src {
            url
          }
          alt_text
          type
        }
      }
    }
  }
`;

function GraphContainer() {
  const { location, setLocation } = useLocation();
  const locationFilter = { locations: { name: location } };
  const { loading, error, data } = useQuery(GET_FIBRES, {
    variables: { where: locationFilter }
  });

  //console.log(loading, error, data, location);
  return loading || error ? (
    <Skeleton />
  ) : (
      <View
        location={location || ""}
        setLocation={setLocation}
        data={data.content[0]}
      ></View>
    );
}

export default GraphContainer;
