/**
 * @description This is the view layer for the PhoneCard component.
 * Component is used in PhoneGrid under wireless>phones screen
 * @author  carlosintrigue
 */
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

import Skeleton from "react-loading-skeleton";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      width: 300
    },
    rootUIProps: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #CCCCCC",
      borderRadius: "4px",
      opacity: "1",
      boxSizing: "border-box",
      "& > :not(:last-child)": {
        /** Add a border bottom to all card sections except the last */
        borderBottom: "1px solid #CCCCCC"
      }
    },
    image: {
      width: "300px",
      height: "300px"
    },
    header: {
      position: "relative",
      zIndex: "10",
      boxSizing: "border-box",
      padding: 16
    },
    headerTitle: {
      textAlign: "left",
      font: "700 18px/25px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    headerSubtitle: {
      textAlign: "left",
      font: "700 12px/16px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    headerDotButtons: {
      position: "absolute",
      right: 16,
      bottom: 16,
      boxSizing: "border-box",
      "& > :not(:last-child)": {
        marginRight: 8
      }
    },
    content: {
      position: "relative",
      zIndex: "10",
      boxSizing: "border-box",
      padding: 16,
      flexGrow: 1
    },
    contentTitle: {
      textAlign: "left",
      font: "700 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    contentList: {
      // reset styles for ul
      margin: 0,
      // ui styles
      textAlign: "left",
      font: "400 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      padding: "0px 0px 0px 16px"
    },
    buttonArea: {
      position: "relative",
      zIndex: "10",
      display: "flex",
      justifyContent: "center",
      boxSizing: "border-box",
      padding: "16px 0px 16px 0px"
    }
  };
});

function PhoneCard(props) {
  const classes = useStyles(props);
  const { imageUrl, phoneModel, phoneBrand, colors, specs, onClick } = props;
  return (
    <article className={`${classes.root} ${classes.rootUIProps}`}>
      <div className={classes.image} src={imageUrl}>
        <Skeleton width={"100%"} height={"100%"} />
      </div>

      <div className={classes.header}>
        <div>
          <Skeleton count={1} />
        </div>
      </div>

      <div className={classes.content}>
        <ul className={classes.contentList}>
          <Skeleton count={5} />
        </ul>
      </div>

      <div className={classes.buttonArea}></div>
    </article>
  );
}

PhoneCard.propTypes = {
  /**
   * This is the url for the image that will be displayed in the card.
   */
  imageUrl: PropTypes.string,
  /**
   * A string with the phone model name
   */
  phoneModel: PropTypes.string,
  /**
   * A string with the Phone's brand name
   */
  phoneBrand: PropTypes.string,
  /**
   * An array of objects that represent the available colors for the phone.
   * These will map directly to the color change buttons in the card.
   * There must be one active color in the array representing the image being displayed
   */
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      onClick: PropTypes.func,
      active: PropTypes.bool
    })
  ),
  /**
   * An array of string representing the phone specs.
   * These will be mapped to a list of specs in the card
   */
  specs: PropTypes.arrayOf(PropTypes.string),
  /**
   * Callback function for the card's button
   */
  onClick: PropTypes.func
};

PhoneCard.defaultProps = {
  imageUrl: "/assets/images/phones/galaxy-note-10-plus.png",
  phoneModel: "Galaxy Note 10+",
  phoneBrand: "Samsung",
  colors: [
    {
      color: "red",
      onClick: function() {},
      active: true
    },
    {
      color: "blue",
      onClick: function() {}
    }
  ],
  specs: [
    "Storage: 256GB/512GB",
    "Display: 6.8” Quad HD+",
    "Camera: Rear quad camera (Ultra Wide, Wide-angle, Telephoto, Depth Vision) with 10MP Dual Pixel front camera",
    "Intelligent S Pen included",
    "Waterproof up to 1.5m deep for 30 minutes (IP68 rating)",
    " Available in: Aura Black"
  ],
  onClick: function() {}
};

export default PhoneCard;
