import React, { useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(function(theme) {
  return {
    li: {
      background: ({ variant }) =>
        `${
          theme.palette[variant]
            ? theme.palette[variant].main
            : theme.palette.primary.main
        } 0% 0% no-repeat padding-box;`,
      backgroundColor: ({ variant }) =>
        `${
          theme.palette[variant]
            ? theme.palette[variant].main
            : theme.palette.primary.main
        }`,
      color: ({ variant }) =>
        `${
          theme.palette[variant]
            ? theme.palette[variant].contrast
            : theme.palette.primary.contrast
        }`,
      listStyleType: "none",
      display: "block",
      boxSizing: "border-box",
      cursor: "pointer",
      // position: "relative",
      zIndex: "200"
    },
    a: {
      padding: "8px 16px 8px 16px",
      background: ({ variant }) =>
        `${
          theme.palette[variant]
            ? theme.palette[variant].main
            : theme.palette.primary.main
        } 0% 0% no-repeat padding-box;`,

      backgroundColor: ({ variant }) =>
        `${
          theme.palette[variant]
            ? theme.palette[variant].main
            : theme.palette.primary.main
        }`,
      color: ({ variant }) =>
        `${
          theme.palette[variant]
            ? theme.palette[variant].contrast
            : theme.palette.primary.contrast
        }`,
      textDecoration: "none",
      textAlign: "right",
      font: "500 0.875rem/1.0625rem Avenir",
      letterSpacing: "0",
      transition: "0.3s",
      opacity: 1,
      display: "block",
      "&:hover": {
        backgroundColor: ({ variant }) =>
          `${
            theme.palette[variant]
              ? theme.palette[variant].dark
              : theme.palette.primary.dark
          }`,
        color: ({ variant }) =>
          `${
            theme.palette[variant]
              ? theme.palette[variant].contrast
              : theme.palette.primary.contrast
          }`,
        textDecoration: "none"
      },
      "&:focus  ": {
        boxShadow: `0 0 0 0.1rem ${theme.palette.gray.darker}`,
        // outline: "none",
        zIndex: 10
      },
      "&:active": {
        backgroundColor: ({ variant }) =>
          `${
            theme.palette[variant]
              ? theme.palette[variant].darker
              : theme.palette.primary.darker
          }`
      }
    },
    submenu: {
      backgroundColor: ({ variant }) =>
        `${
          theme.palette[variant]
            ? theme.palette[variant].contrast
            : theme.palette.primary.contrast
        }`,
      padding: 0,
      margin: 0,
      display: "block",
      position: "absolute",
      border: "1px solid #CCCCCC",
      borderRadius: "0px 0px 4px 4px",
      opacity: "1",
      minWidth: 161,
      width: "100%",
      transition: "0.3s"
    },
    subMenuItem: {
      textAlign: "left",
      font: "400 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    decorated: {
      textDecoration: "underline",
      fontWeight: "700",
      "&:hover": { textDecoration: "underline", fontWeight: "700" }
    }
  };
});

function ListItem(props) {
  const { onClick, onMouseEnter, items } = props;
  const classes = useStyles(props);

  return (
    <ul
      role="menu"
      className={classes.submenu}
      tabindex="-1"
      role="listbox"
      {...props}
    >
      {items.map(function({ content, ...rest }) {
        return (
          <li className={classes.li} role="menuitem" {...rest}>
            <a
              href="#"
              className={`${classes.a} ${classes.subMenuItem}`}
              onClick={e => {
                e.preventDefault();
              }}
            >
              {content}
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export default ListItem;
