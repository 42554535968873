import { useQuery } from "@apollo/react-hooks";
import Skeleton from "components/Spinner";
import gql from "graphql-tag";
import React from "react";
import View from "./view";

const QUERY = gql`
  query getLegal {
    content: legals {
      id

      Ads {
        title
        body
        button_text
        images {
          title: image_title
          src {
            url
          }
          alt_text
          type
        }
      }

      Full_text_row {
        title
        body
      }
    }
  }
`;

function GraphContainer() {
  const { loading, error, data } = useQuery(QUERY);

  //console.log("GQL", data);

  return loading || error || !data ? (
    <Skeleton />
  ) : (
    <View data={data.content[0]}></View>
  );
}

export default GraphContainer;
