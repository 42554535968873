import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import Input from "components/Input";
import Textarea from "components/Textarea";
import Button from "components/Button";
import {sendForm}  from './api';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main
    },
    formRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 24
    },
    toastBackground:{
      backgroundColor:'#D81D36',
      color:'#FFFFFF'
    },
    toastProgressBar:{
      background: "linear-gradient(to right,#961526,#961526,#961526,#961526)"
    }
  };
});



function View(props) {
  const classes = useStyles(props);
  const { variant } = props;
  const history = useHistory();
  //handle submit once the props are passed containing form values from mapState
  const handleSubmit=data=> async e=>{
    e.preventDefault();
    if(data.firstName && data.lastName && data.email){
      let dataToSend={
        firstName:data.firstName,
        lastName:data.lastName,
        phoneNumber:data.phoneNumber,
        email:data.email,
        message:data.message,
      }
      let response= await sendForm(dataToSend);
      if(response.status===200){
        history.push("/thank-you");
      }
    }else{
      if(!data.firstName||!data.lastName){
        toast("Please enter a valid name", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className:classes.toastBackground,
          progressClassName:classes.toastProgressBar,
          });
      }else{
        toast("Please enter a valid email address.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className:classes.toastBackground,
          progressClassName:classes.toastProgressBar
          });
      }
    }
    
  }
  
  return (
    <form id="feedback" onSubmit={handleSubmit(props)}>
      <div className={classes.formRow}>
        <Input
          required={true}
          style={{ width: "48%" }}
          placeholder={"First name"}
          id={"firstName"}
        />
        <Input
          required={true}
          style={{ width: "48%" }}
          placeholder={"Last name"}
          id={"lastName"}
        />
      </div>

      <div className={classes.formRow}>
        <Input
          required={true}
          style={{ width: "48%" }}
          placeholder={"Email Address"}
          id={"email"}
        />
        <Input style={{ width: "48%" }} placeholder={"Phone Number"} 
        id={"phoneNumber"}/>
      </div>

      <div className={classes.formRow}>
        <Textarea
          style={{ width: "100%" }}
          placeholder={"Your message"}
          type={"textarea"}
          id={"message"}
        />
      </div>

      <Button
        form="feedback"
        formaction="/thank-you"
        formmethod="get"
        variant={variant}
      >
        Submit your feedback
      </Button>
    </form>
  );
}
export default View;
