import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Home from "screens/Home";
import ShopNow from "screens/ShopNow";
import Phones from "screens/ShopNow/Mobile/Phones";
import Phone from "screens/ShopNow/Mobile/Phone";
import AddOns from "screens/ShopNow/Mobile/AddOns";
import BlackFridayOffer from "screens/ShopNow/Mobile/BlackFridayOffer";

import PhonesCampaign from "screens/ShopNow/Mobile/PhonesCampaign";

import Which from "screens/ShopNow/Internet/Which";
import Fibre from "screens/ShopNow/Internet/Fibre";
import DSL from "screens/ShopNow/Internet/DSL";
import Webhosting from "screens/ShopNow/Internet/Webhosting";
import Wireless from "screens/ShopNow/Internet/Wireless";
import Resell from "screens/ShopNow/Internet/Resell";
import TV from "screens/ShopNow/TV";
import ICR from "screens/ShopNow/HomePhone/InternationalCallingRates";
import Rates from "screens/ShopNow/HomePhone/Rates";
import Features from "screens/ShopNow/HomePhone/Features";
import LongDistance from "screens/ShopNow/HomePhone/LongDistance";

import AboutUs from "screens/AboutUs";
import OurHisory from "screens/AboutUs/OurHistory";
import GetInvolved from "screens/AboutUs/GetInvolved";
import CommunityInvolvment from "screens/AboutUs/CommunityInvolvment";
import ConnectingFamilies from "screens/AboutUs/ConnectingFamilies";
import Careers from "screens/AboutUs/Careers";
import OurTeam from "screens/AboutUs/OurTeam";
import Accessibility from "screens/AboutUs/Accessibility";
import AccessibilityFeedback from "screens/AboutUs/AccessibilityFeedback";
import NewsAndUpdates from "screens/AboutUs/NewsAndUpdates";

import WellesleyConstruction from "screens/AboutUs/WellesleyConstruction";
import HessonBrunnerConstruction from "screens/AboutUs/HessonBrunnerConstruction";
import LinwoodConstruction from "screens/AboutUs/LinwoodConstruction";
import FibreInstallation from "screens/AboutUs/FibreInstallation";
import Enhanced911 from "screens/AboutUs/Enhanced911";
import PasswordPolicy from "screens/AboutUs/PasswordPolicy";

import DigitalBoxes from "screens/ShopNow/TV/DigitalBoxes";
import Packages from "screens/ShopNow/TV/Packages";
import OnDemand from "screens/ShopNow/TV/OnDemand";
import TVEverywhere from "screens/ShopNow/TV/TVEverywhere";
import FreePreview from "screens/ShopNow/TV/FreePreview";
import MorningtonStream from "screens/ShopNow/TV/MorningtonStream";

import Installation from "screens/ShopNow/HomeSecurity/Installation";
import Services from "screens/ShopNow/HomeSecurity/Services";

import Search from "screens/Search";

import ContactUs from "screens/ContactUs";
import LiveSupport from "screens/ContactUs/LiveSupport";
import Locations from "screens/ContactUs/Locations";
import GetInTouch from "screens/ContactUs/GetInTouch";
import Map from "screens/Map";

import Resources from "screens/Resources";
import Forms from "screens/Resources/Forms";

import NotFound from "../screens/404";
import ThankYou from "screens/ThankYou";

import Business from "screens/Business";
import BusinessHome from "screens/Business/Home";
import BusinessFibre from "screens/Business/ShopNow/Internet/Fibre";
import BusinessDSL from "screens/Business/ShopNow/Internet/DSL";
import BusinessWirelesInternet from "screens/Business/ShopNow/Internet/WirelessInternet";
import BusinessCircuitsInternet from "screens/Business/ShopNow/Internet/CircuitsInternet";
import BusinessWebHosting from "screens/Business/ShopNow/Internet/WebHosting";
import BusinessTelephoneServices from "screens/Business/ShopNow/Telephone/Services";
import BusinessMobileMobility from "screens/Business/ShopNow/Wireless/Mobility";
import BusinessSecurityServices from "screens/Business/ShopNow/BusinessSecurity/Services";
import BusinessSecurityInstallation from "screens/Business/ShopNow/BusinessSecurity/Installation";
import ViewBundles from "screens/Business/ShopNow/Bundles/ViewBundles";
import Legal from "screens/Legal";
import GeneralTariffs from "screens/GeneralTariffs";
import PrivacyPolicy from "screens/PrivacyPolicy";
import Tariffs from "screens/Tariffs";

import BundleAndSave from "screens/BundleAndSave";

import SubRouting from "./subrouting.js";

import ScrollTop from "components/ScrollTop";
import MorningtonTab from "screens/ShopNow/Mobile/MorningtonTab";

const routes = [
  {
    // redirect: "/shop/mobile/phones",
    path: "/",
    exact: true,
    isolate: "public",
    component: Home
  },
  {
    path: "/business",
    component: Business,
    isolate: "public",
    routes: [
      {
        path: "/business",
        isolate: "public",
        exact: true,
        component: BusinessHome
      },
      {
        path: "/business/shop/internet/fibre",
        isolate: "public",
        exact: true,
        component: BusinessFibre
      },
      {
        path: "/business/shop/internet/dsl",
        isolate: "private",
        exact: true,
        component: BusinessDSL
      },
      {
        path: "/business/shop/internet/wireless-internet",
        isolate: "public",
        exact: true,
        component: BusinessWirelesInternet
      },
      {
        path: "/business/shop/internet/circuits-internet",
        isolate: "public",
        exact: true,
        component: BusinessCircuitsInternet
      },
      {
        path: "/business/shop/internet/web-hosting",
        isolate: "public",
        exact: true,
        component: BusinessWebHosting
      },
      {
        path: "/business/shop/telephone/services",
        isolate: "public",
        exact: true,
        component: BusinessTelephoneServices
      },
      {
        path: "/business/shop/mobile/mobility-solutions",
        isolate: "public",
        exact: true,
        component: BusinessMobileMobility
      },
      ,
      {
        path: "/business/shop/business-security/services",
        isolate: "public",
        exact: true,
        component: BusinessSecurityServices
      },
      {
        path: "/business/shop/business-security/installation",
        isolate: "public",
        exact: true,
        component: BusinessSecurityInstallation
      },
      {
        path: "/business/shop/bundles/view-bundles",
        isolate: "private",
        exact: true,
        component: ViewBundles
      },
      {
        path: "*",
        isolate: "public",
        variant: "tertiary",
        component: NotFound
      }
    ]
  },
  {
    path: "/shop",
    isolate: "public",
    component: ShopNow,
    routes: [
      {
        path: "/shop/mobile/phones",
        isolate: "public",
        exact: true,
        component: Phones
      },
      {
        path: "/shop/mobile/phones/:campaign",
        isolate: "public",
        component: PhonesCampaign
      },
      {
        path: "/shop/mobile/phone",
        isolate: "public",
        component: Phone
      },
      {
        path: "/shop/mobile/add-ons",
        isolate: "public",
        component: AddOns
      },
      {
        path: "/shop/mobile/tab",
        isolate: "public",
        component: MorningtonTab
      },
      {
        path: "/shop/mobile/black-friday-deals",
        isolate: "private",
        component: BlackFridayOffer
      },
      {
        path: "/shop/internet",
        exact: true,
        isolate: "public",
        component: Which
      },
      {
        path: "/shop/internet/fibre",
        exact: true,
        isolate: "public",
        component: Fibre
      },
      {
        path: "/shop/internet/dsl",
        exact: true,
        isolate: "private",
        component: DSL
      },
      {
        path: "/shop/internet/webhosting",
        exact: true,
        isolate: "public",
        component: Webhosting
      },
      {
        path: "/shop/internet/wireless",
        exact: true,
        isolate: "public",
        component: Wireless
      },
    //   {
    //     path: "/shop/internet/resell",
    //     exact: true,
    //     isolate: "private",
    //     component: Resell
    //   },
      {
        path: "/shop/internet/connecting-families",
        isolate: "public",
        component: ConnectingFamilies
      },
      // {
      //   path: "/shop/tv",
      //   isolate: "public",
      //   component: TV
      // },
      {
        path: "/shop/home-phone/rates",
        exact: true,
        isolate: "public",
        component: Rates
      },
      {
        path: "/shop/home-phone/features",
        exact: true,
        isolate: "public",
        component: Features
      },
      {
        path: "/shop/home-phone/long-distance",
        exact: true,
        isolate: "public",
        component: LongDistance
      },
      {
        path: "/shop/home-phone/international-calling-rates",
        exact: true,
        isolate: "public",
        component: ICR
      },
      {
        path: "/shop/tv/stream",
        exact: true,
        isolate: "public",
        component: MorningtonStream
      },
      {
        path: "/shop/tv/packages",
        exact: true,
        isolate: "public",
        component: Packages
      },
      {
        path: "/shop/tv/on-demand",
        exact: true,
        isolate: "public",
        component: OnDemand
      },
      {
        path: "/shop/tv/tv-everywhere",
        exact: true,
        isolate: "public",
        component: TVEverywhere
      },
      {
        path: "/shop/tv/free-previews",
        exact: true,
        isolate: "public",
        component: FreePreview
      },
      {
        path: "/shop/home-security/installation",
        exact: true,
        isolate: "public",
        component: Installation
      },
      {
        path: "/shop/home-security/services",
        exact: true,
        isolate: "public",
        component: Services
      },
      {
        path: "*",
        isolate: "public",
        component: NotFound
      }
    ]
  },
  {
    path: "/about-us",
    isolate: "public",
    component: AboutUs,
    routes: [
      {
        path: "/about-us/our-history",
        isolate: "public",
        component: OurHisory
      },
      {
        path: "/about-us/get-involved",
        isolate: "public",
        component: GetInvolved
      },
      {
        path: "/about-us/community-involvment",
        isolate: "public",
        component: CommunityInvolvment
      },
      {
        path: "/about-us/careers",
        isolate: "public",
        component: Careers
      },
      {
        path: "/about-us/our-team",
        isolate: "public",
        component: OurTeam
      },
      {
        path: "/about-us/accessibility",
	    exact: true,
        isolate: "public",
        component: Accessibility
      },
      {
        path: "/about-us/accessibility-feedback",
	    exact: true,
        isolate: "public",
        component: AccessibilityFeedback
      },
      {
        path: "/about-us/news-and-updates",
        component: NewsAndUpdates,
        exact: true,
        isolate: "public"
      },
      {
        path: "/about-us/news-and-updates/wellesly-construction",
        isolate: "public",
        component: WellesleyConstruction
      },
      {
        path: "/about-us/news-and-updates/hesson-brunner",
        isolate: "public",
        component: HessonBrunnerConstruction
      },

      {
        path:
          "/about-us/news-and-updates/linwood-hawkesville-bamberg-construction",
        isolate: "public",
        component: LinwoodConstruction
      },
      {
        path: "/about-us/news-and-updates/fibre-installation",
        isolate: "public",
        component: FibreInstallation
      },
      {
        path: "/about-us/news-and-updates/enhanced-911",
        isolate: "public",
        component: Enhanced911
      },
      {
        path: "/about-us/news-and-updates/password-policy",
        isolate: "public",
        component: PasswordPolicy
      }
    ]
  },
  {
    path: "/thank-you/:campaign",
    isolate: "public",
    component: ThankYou
  },
  {
    path: "/thank-you",
    exact: true,
    isolate: "public",
    component: ThankYou
  },
  {
    path: "/contact-us",
    isolate: "public",
    component: ContactUs,
    routes: [
      {
        path: "/contact-us/live-support",
        exact: true,
        isolate: "public",
        component: LiveSupport
      },
      {
        path: "/contact-us/our-locations",
        exact: true,
        isolate: "public",
        component: Locations
      },
      {
        path: "/contact-us/get-in-touch",
        exact: true,
        isolate: "public",
        component: GetInTouch
      },
      {
        path: "*",
        isolate: "public",
        component: NotFound
      }
    ]
  },
  {
    path: "/resources",
    isolate: "public",
    component: Resources,
    routes: [
      {
        path: "/resources/forms",
        exact: true,
        isolate: "public",
        component: Forms
      },
      {
        path: "*",
        isolate: "public",
        component: NotFound
      }
    ]
  },
  {
    path: "/map",
    isolate: "public",
    component: Map
  },
  {
    path: "/search",
    isolate: "public",
    component: Search
  },
  {
    path: "/legal",
    isolate: "public",
    component: Legal
  },
  {
    path: "/general-tariffs",
    isolate: "public",
    component: GeneralTariffs
  },
  {
    path: "/privacy-policy",
    isolate: "public",
    component: PrivacyPolicy
  },
  {
    path: "/tariffs",
    isolate: "public",
    component: Tariffs
  },
  {
    path: "/bundle-and-save",
    isolate: "public",
    component: BundleAndSave
  },
  {
    path: "*",
    isolate: "public",
    component: NotFound
  }
  // {
  //   path: "/login",
  //   exact: true,
  //   isolate: "public",
  //   component: Login
  // },
  // {
  //   path: "/share",
  //   exact: true,
  //   isolate: "public",
  //   component: ShareCampaign
  // }
];

function Router() {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Switch>
        {routes.map((route, i) => (
          <SubRouting key={i} {...route} />
        ))}
      </Switch>
    </BrowserRouter>
  );
}
export default Router;
