import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(function(theme) {
  return {
    heading: {
      textAlign: "left",
      font: "700 48px/66px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    heading1: {
      textAlign: "left",
      font: "Bold 48px/56px Coast",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    heading2: {
      textAlign: "left",
      font: "700 24px/33px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    subheading: {
      textAlign: "left",
      font: "700 18px/25px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    heading1Center:{
        textAlign:"center",
        font: "Bold 48px/56px Coast",
        letterSpacing: "0",
        color: "#000000",
        opacity: "1"
    },
    breadcrumbs: {
      textAlign: "left",
      font: "400 18px/25px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    text: {
      textAlign: "left",
      font: "400 18px/25px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    link: {
      textAlign: "left",
      font: "500 16px/22px Avenir",
      letterSpacing: "0",
      color: "#007AB1",
      opacity: "1"
    },
    body: {
      textAlign: "left",
      font: "500 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "1"
    },
    bodyLight: {
      textAlign: "left",
      font: "500 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "0.75"
    },
    note: {
      textAlign: "left",
      font: "500 10px/14px Avenir",
      letterSpacing: "0",
      color: "#000000",
      opacity: "0.75"
    },
    searchResult: {
      textAlign: "left",
      font: "700 24px/33px Avenir",
      letterSpacing: "0",
      color: "#007AB1",
      opacity: "1"
    }
  };
});

function View(props) {
  const classes = useStyles(props);
  const { variant } = props;
  return (
    <span className={classes[variant] || classes.body}>{props.children}</span>
  );
}
export default View;
