import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

import BannerImage from "components/BannerImage";
import InternetGrid from "components/InternetGrid";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      "& > *:not(:last-child)": {
        marginBottom: 24
      }
    },
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    "@media (max-width: 700px)": {
      containedComponent: {
        padding: "0px 20px 0px 20px"
      }
    }
  };
});

function View(props) {
  const classes = useStyles(props);

  const history = useHistory();
  let path = history.location.pathname;
  let urlTail = path.split("/").filter(function (item) {
    return item;
  });
  urlTail = urlTail.join(" > ");

  return (
    <div className={classes.root}>
      <BannerImage
        topText={urlTail}
        title={"Packages"}
        description={
          "Mornington is more than just talk. We also provide residential television services."
        }
        imageUrl={"/assets/images/family-watching-tv.png"}
        gradient
      />
      <div className={classes.containedComponent}>
        <InternetGrid />
      </div>
      <BannerImage
        topText={"Mornington Deals"}
        header={true}
        headerText={"Looking to surf on-the-go?"}
        title={"Surf when you want, where you want."}
        description={
          "Stay connected wherever you are with our Mobile Internet Devices and Flex Plans."
        }
        imageUrl={"/assets/images/internet-surfing.png"}
        ctaButton
        ctaButtonProps={{
          children: "Get yours now",
          onClick: function () {
            ////  alert("click") ;
          }
        }}
        gradient
        leftAlign={true}
      />
    </div>
  );
}
export default View;
