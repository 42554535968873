import React, { useState, useRef } from "react";
import BannerImage from "components/BannerImage";
import BannerImageShort from "components/BannerImageShort";
import PhoneCardCampaign from "components/PhoneCardCampaign";
import PhoneGridCampaign from "components/PhoneGridCampaign";
import FeedbackFormCampaign from "components/FeedbackFormCampaign";
import FullTextRow from "components/FullTextRow";
import { createUseStyles } from "react-jss";
import { useHistory, useParams } from "react-router-dom";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {},
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    },
    sideSplit: {
      width: "100%",
      padding: "50px 0px 0px 0px",
      maxWidth: 1366,
      margin: "0px auto 0px auto",
      display: "flex",
      "flex-direction": "row"
    },
    leftSide: {
      width: 760,
      maxWidth: 760
    },
    rightSide: {
      maxWidth: 606,
      marginTop: 120
    },
    "@media (max-width: 900px)": {
      sideSplit: {
        "flex-direction":"column"
      },
      leftSide: {
        width: "100%",
        maxWidth: "100%"
      },
      rightSide: {
        marginTop: 40,
        maxWidth: 640,
        marginLeft: 10,
        marginRight: 10
      }
    }

  };
});

const description =
  "# Phones\n\nNeed a new phone or an upgrade? Mornington has you covered.\n Check out our wide selection of phones from brands you trust.";
const familyDescription =
  "Mornington Deals\n\n### Bundle And Save!\n\nBundle discounts are available on qualified wireless, internet, TV and web hosting packages in select service areas.";

function Home(props) {
  const classes = useStyles();
  const history = useHistory();
  const infoForm = useRef(null);
  const scrollToForm = () => infoForm.current.scrollIntoView();
  let path = history.location.pathname;
  let urlTail = path.split("/").filter(function (item) {
    return item;
  });
  let urlHead = urlTail.reverse().shift();
  urlTail.reverse();
  urlTail = urlTail.join(" > ");
  let { campaign } = useParams();
  //const [interestedPhone, setInterestedPhone] = useState('');

  return (
    <>
      <div className={classes.root}>
        <BannerImageShort
          topText={urlHead}
          title={urlTail}
          markdown={description}
          imageUrl={"/assets/images/girl-on-cell-phone.png"}
          leftAlign={false}
        />
        <div className={classes.sideSplit}>
          <div className={classes.leftSide}>
            <PhoneGridCampaign Renderer={PhoneCardCampaign} refForm={infoForm} />
          </div>
          <div className={classes.rightSide} ref={infoForm}  id="campaignForm">
            <FeedbackFormCampaign campaignID={campaign} />
          </div>
        </div>
        <BannerImage
          topText={"Mornington Deals"}
          header={true}
          headerText={"Looking for more than just phones?"}
          title={"Bundle and save!"}
          markdown={familyDescription}
          imageUrl={"/assets/images/family-on-devices.png"}
          ctaButton
          ctaButtonProps={{
            children: "Get yours now",
            onClick: function () {
              history.push("/bundle-and-save");
            }
          }}
          gradient
          leftAlign={true}
        />
      </div>
    </>
  );
}

export default Home;
