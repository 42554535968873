import React, { useState, useCallback, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";
import Navbar from "components/Navbar";
import Productbar from "components/Productbar";
import ProductbarBusiness from "components/ProductbarBusiness";
import Button from "components/Button";
import { useMediaQuery } from "react-responsive";

const useStyles = createUseStyles(function(theme) {
  return {
    header: {
      position: "fixed",
      top: "0",
      width: "100%",
      zIndex: "100"
    },
    skipBtn: {
      position: "absolute",
      top: -200,
      left: 0,
      zIndex: 9999,
      "&:focus, &:focus-within": {
        top: 0
      }
    }
  };
});

const variants = {
  open: { y: 0 },
  closed: { y: "-38px" }
};

function View(props) {
  const classes = useStyles(props);
  const { variant, personal, location, setLocation, business } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const [visible, setVisible] = useState("open");
  const cb = useCallback(
    function(e) {
      // alert("te");
      if (window.pageYOffset === 0) {
        setVisible("open");
      } else if (visible === "open") {
        setVisible("closed");
      }
    },
    [visible]
  );
  useEffect(function() {
    window.addEventListener("scroll", cb);
    return function() {
      window.removeEventListener("scroll", cb);
    };
  });
  return !isMobile ? (
    <motion.div animate={visible} variants={variants}>
      <span className={classes.skipBtn}>
        <Button
          variant="secondary"
          onClick={function() {
            window.location.href = "#content";
          }}
        >
          Skip to main content
        </Button>
      </span>
      <Navbar variant={variant} personal={personal} business={props.business} />
      {!business ? (
        <Productbar
          variant={variant}
          persona
          location={location}
          setLocation={setLocation}
        />
      ) : (
        <ProductbarBusiness
          variant={variant}
          persona
          location={location}
          setLocation={setLocation}
        />
      )}
    </motion.div>
  ) : (
    <div>
      <Productbar
        variant={variant}
        persona
        location={location}
        setLocation={setLocation}
      />
    </div>
  );
}
export default View;
