import gql from "graphql-tag";

import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import View from "./view";
import Skeleton from "components/Spinner";

import { motion } from "framer-motion";

const GET_COUNTRIES = gql`
  query getCountries($start: Int) {
    callingRates(start: $start) {
      country
    }
  }
`;

// const CREATE_COUNTRY = gql`
//   mutation uploadCountries($countries: createCountryInput) {
//     createCountry(input: $countries) {
//       country {
//         name
//       }
//     }
//   }
// `;

let allCountries = {};

function GraphContainer() {
  const [paginationStart, setPaginationStart] = useState(0);

  const { loading, error, data } = useQuery(GET_COUNTRIES, {
    variables: { start: paginationStart }
  });

  let countries = data || Object.keys(allCountries);
  countries = countries.callingRates ? countries.callingRates : countries;
  countries = countries.reduce(function (acc, item) {
    acc[item.country] = 1;
    return acc;
  }, allCountries);
  allCountries = countries;

  const res = (
    <>
      <motion.div variants={loading || error || !data ? "closed" : "open"}>
        <button
          onClick={function () {
            setPaginationStart(paginationStart + 100);
          }}
        >
          test
        </button>

        <button
          onClick={function () {
            // Object.keys(allCountries).forEach(item =>
            //   test[0]({
            //     variables: { countries: { data: { name: item } } }
            //   })
            // );
          }}
        >
          {" "}
          Write all{" "}
        </button>
        <ol>
          {Object.keys(allCountries).map(item => (
            <li>{item}</li>
          ))}
        </ol>
        {loading || error || !data ? <Skeleton /> : <View></View>}
      </motion.div>
    </>
  );

  //console.log(res);

  return res;
}

export default GraphContainer;
