import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      maxWidth: "1366px",
      margin: "0px auto 0px auto",
      padding: "0px 60px 0px 60px",
      boxSizing: "border-box"
    },

    "@media (max-width: 700px)": {
      root: {
        padding: "0px 40px 0px 40px"
      }
    }
  };
});

function Container(props) {
  const classes = useStyles(props);

  return <div className={classes.root}>{props.children}</div>;
}
export default Container;
