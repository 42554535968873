import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      color: theme.palette.primary.main,
    },
    mainContainer: {
      background: "#333333 0% 0% no-repeat padding-box",
      opacity: "1",
      "font-family": "Avenir",
      width: "100%",
      padding: "36px 60px",
      display: "flex", //FLEX TO HAVE SUBITEMS SIT ON SAME ROW
      margin: "auto"
    },
    bottomContainer: {
      background: ({ variant }) =>
        `${
        theme.palette[variant]
          ? theme.palette[variant].main
          : theme.palette.primary.main
        } 0% 0% no-repeat padding-box`,
      opacity: "1",
      display: "flex",
      "flex-direction": "column",
      "text-align": "center",
      "font-family": "Avenir",
      "font-weight": "500",
      color: "#FFFFFF",
      opacity: "1",
      padding: "16px",
      "& a ": {
        color: "#FFFFFF"
      }, //THIS WILL ADD LINE SPACING FOR ALL LI UNDER UL
      "& p": {
        "margin-bottom": "0px"
      }
    },
    footerLinks: {
      display: "flex",
      "flex-wrap": "wrap",
      "justify-content": "center",
      "margin-bottom": "16px"
    }, // WRAP ON ALL DEVICES
    subMainContainer: {
      display: "flex",
      width: "100%",
      "justify-content": "flex-end",
      overflow: "hidden"
    },
    eachColumn: {
      "font-size": "88%",
      color: "#FFFFFF",
      opacity: "1",
      "max-width": "175px",
      "&:not(:last-child)": {
        "margin-right": "60px"
      }
    },
    list: {
      "list-style-type": "none",
      "font-weight": "500",
      "& >li >a": {
        color: "#FFFFFF"
      },
      "& >li:not(:last-child)": {
        "padding-bottom": "12px"
      } //THIS WILL ADD LINE SPACING FOR ALL LI UNDER UL
    },
    underlineText: {
      textAlign: "left",
      "font-size": "133%",
      "font-weight": "700",
      letterSpacing: "0",
      color: "#FFFFFF",
      opacity: "1",
      "list-style-type": "none"
    },
    underlineThreeLetters: {
      "border-bottom": "2px solid"
    },
    logo: {
      width: "250px",
      height: "auto"
    },
    "@media screen and (max-width:1000px)": {
      logo: {
        "margin-bottom": "24px",
        "& img": {
          width: "175px",
          height: "auto"
        }
      },
      mainContainer: {
        "flex-direction": "column",
        padding: "36px 16px"
      },
      subMainContainer: {
        "flex-flow": "row wrap",
        "justify-content": "center",
        "align-content": "flex-start"
      }, // WRAP FLEX MENU ON SMALLER SCREENS
      eachColumn: {
        "&:not(:last-child)": {
          "margin-right": "16px"
        },
        padding: "0px 0px 24px 0px",
        width: "150px"
      },
      list: {
        padding: "0px !important" // OVERRIDE USER AGENT STYLESHEET
      }
    },
    "@media screen and (max-width:400px)": {
      eachColumn: {
        "&:not(:last-child)": {
          "margin-right": "0px" // MAKE SURE ON SMALLER SCREENS THEY ALL ALIGN TO THE LEFT OF THE FLEX
        },
        width: "175px" // INCREASE WIDTH SO THEY'RE FORCED TO ONE COLUMN
      }
    }
  };
});

function Footer(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        {/* <div className={classes.extraColumn}></div> */}
        <div className={classes.logo}>
          <a href="/">
            <img
              src="/assets/images/mornington-tagline-white.svg"
              alt="Mornington communications logo"
            />
          </a>
        </div>
        <div className={classes.subMainContainer}>
          <div className={classes.eachColumn}>
            <ul className={classes.list}>
              <li className={classes.underlineText}>
                <span className={classes.underlineThreeLetters}>Sho</span>p Now
              </li>
              <li>
                <a href="/shop/mobile/phones">Mobile</a>
              </li>
              <li>
                <a href="/shop/internet">Internet</a>
              </li>
              <li>
                <a href="/shop/tv/packages">TV</a>
              </li>
              <li>
                <a href="/shop/home-phone/rates">Home Phone</a>
              </li>
              <li>
                <a href="/shop/home-security/services">Home Security</a>
              </li>
            </ul>
          </div>
          <div className={classes.eachColumn}>
            <ul className={classes.list}>
              <li className={classes.underlineText}>
                <span className={classes.underlineThreeLetters}>Cor</span>porate
              </li>
              <li>
                <a href="/about-us/our-history">About Us</a>
              </li>
              <li>
                <a href="/about-us/get-involved">Get Involved</a>
              </li>
              <li>
                <a href="/about-us/our-team">Our Team</a>
              </li>
              <li>
                <a href="/about-us/community-involvment">
                  Community Involvement
                </a>
              </li>
              <li>
                <a href="/about-us/news-and-updates">Community Updates</a>
              </li>
              <li>
                <a href="/about-us/careers">Careers</a>
              </li>
            </ul>
          </div>
          <div className={classes.eachColumn}>
            <ul className={classes.list}>
              <li className={classes.underlineText}>
                <span className={classes.underlineThreeLetters}>Mor</span>e
                Links
              </li>
              <li>
                <a href="https://help.mornington.ca/faq" target="_blank">
                  FAQS
                </a>
              </li>
              <li>
                <a href="/resources/forms">Forms</a>
              </li>
              <li>
                <a href="/about-us/news-and-updates/password-policy">
                  Password Policy
                </a>
              </li>
              <li>
                <a href="/about-us/news-and-updates/enhanced-911">E9-1-1</a>
              </li>
              <li>
                <a
                  href="https://crtc.gc.ca/eng/phone/mobile/prepay.htm"
                  target="_blank"
                >
                  Know Your Rights
                </a>
              </li>
              <li>
                <a href="/contact-us/get-in-touch">Join Our Newsletter</a>
              </li>
            </ul>
          </div>
          <div className={classes.eachColumn}>
            <ul className={classes.list}>
              <li className={classes.underlineText}>
                <span className={classes.underlineThreeLetters}>Sup</span>port
              </li>
              <li>
                <a href="tel:+15192720451">
                  <i class="fas fa-phone-alt"></i>&nbsp; 519-272-0451
                </a>
              </li>
              <li>
                <a href="tel:+18662638692">
                  <i class="fas fa-headphones-alt"></i>&nbsp; 1-866-263-8692
                </a>
              </li>
              <li>
                <a href="tel:+15195954142">
                  <i class="fas fa-fax"></i>&nbsp; 519-595-4142
                </a>
              </li>
              <li>
                <a href="mailto:support@cyg.net">
                  <i class="fas fa-envelope"></i>&nbsp; support@cyg.net
                </a>
              </li>
            </ul>

            <ul className={classes.list}>
              <li className={classes.underlineText}>
                <span className={classes.underlineThreeLetters}>Soc</span>ial
                Links
              </li>
              <li>
                <a
                  href="https://facebook.com/Morningtoncommunications/"
                  target="_blank"
                >
                  <i class="fab fa-facebook"></i>&nbsp;&nbsp;Facebook
                </a>
              </li>
              <li>
                <a href="https://twitter.com/mornington1919" target="_blank">
                  <i class="fab fa-twitter"></i>&nbsp;&nbsp;Twitter
                </a>
              </li>
            </ul>
          </div>
          {/* <div className={classes.extraColumn}></div> */}
        </div>
      </div>
      <div className={classes.bottomContainer}>
        <div className={classes.footerLinks}>
          <div>
            <a href="/privacy-policy">Privacy Policy</a>&nbsp;&nbsp;|
          </div>
          <div>
            &nbsp;&nbsp;<a href="/assets/sitemap.xml">Sitemap</a>&nbsp;&nbsp;|
          </div>
          <div>
            &nbsp;&nbsp;<a href="/tariffs">Tariffs</a>&nbsp;&nbsp;|
          </div>
          <div>
            &nbsp;&nbsp;<a href="/legal">Legal</a>&nbsp;&nbsp;|
          </div>
          <div>
            &nbsp;&nbsp;<a href="/contact-us/get-in-touch">Feedback</a>
          </div>
        </div>
        <p>
          Copyright © 2024 Mornington Communications Co-Operative Limited. All
          Rights Reserved.&nbsp;&nbsp;|&nbsp;&nbsp;Site maintained by{" "}
          <strong>
            <a href="mailto:mark@morrisonmedia.co">
              Mark Morrison Media
            </a>
          </strong>
          .
        </p>
      </div>
    </div>
  );
}
export default Footer;
