import React, { useCallback, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "0",
      marginBottom: "0",
      listStyle: "none"
    },
    content: {
      display: "inline-block",
      textAlign: "left",
      font: "500 18px/25px Avenir",
      letterSpacing: "0",
      backgroundColor: theme.palette.white.main,
      color: theme.palette.white.contrast,
      opacity: "1",
      boxSizing: "border-box",
      padding: "20px 24px 20px 24px",
      userSelect: "none",
      transition: "0.3s",
      whiteSpace: "nowrap",
      textDecoration: "none",
      "&:hover": {
        backgroundColor: ({ variant }) => `${theme.palette.white.dark}`,
        color: ({ variant }) =>
          `${
            theme.palette[variant]
              ? theme.palette[variant].main
              : theme.palette.white.contrast
          }`,

        textDecoration: "none"
      },
      "&:focus  ": {
        boxShadow: `0 0 0 0.1rem ${theme.palette.gray.darker}`,
        // outline: "none",
        zIndex: 10
      },
      "&:active": {
        backgroundColor: ({ variant }) => `${theme.palette.white.darker}`
      }
    },
    submenu: { padding: 0 },
    activeItem: {
      font: "700 18px/25px Avenir",
      borderBottom: ({ variant }) =>
        `3px solid ${
          theme.palette[variant]
            ? theme.palette[variant].main
            : theme.palette.white.contrast
        }`
    }
  };
});

function ProductbarItem(props) {
  const classes = useStyles(props);
  const { dropDown, active, Submenu, caret, noHover, onClick } = props;
  const [isOpen, setOpen] = useState(false);
  const node = useRef(null);
  const cb = useCallback(
    function handler(e) {
      if (node.current && node.current.contains(e.target)) {
        if (node.current === e.target) setOpen(!isOpen);
        return;
      }
      setOpen(false);
    },
    [isOpen]
  );
  useEffect(
    function() {
      document.addEventListener("mousedown", cb, false);
      return function() {
        document.removeEventListener("mousedown", cb, false);
      };
    },
    [isOpen]
  );
  return (
    <li
      ref={node}
      className={classes.root}
      role="none"
      onMouseEnter={function() {
        if (!noHover) setOpen(true);
      }}
      onMouseLeave={function() {
        if (!noHover) setOpen(false);
      }}
      onClick={function(e) {
        // //console.log("CLICKEVENT");
        if (noHover) setOpen(true);

        onClick && onClick(e);
      }}
      // onFocus={function() {
      //   if (noHover) setOpen(false);
      // }}
    >
      <a
        href="#"
        className={`${classes.content} ${active ? classes.activeItem : ""}`}
      >
        {props.children}{" "}
        {dropDown && caret ? (
          isOpen ? (
            <i class="fas fa-caret-up" aria-hidden="true"></i>
          ) : (
            <i class="fas fa-caret-down" aria-hidden="true"></i>
          )
        ) : (
          ""
        )}
      </a>

      <div
        className={classes.submenu}
        style={{
          display: dropDown && isOpen && Submenu ? "block" : "none"
        }}
      >
        {dropDown && Submenu && <Submenu {...props} />}
      </div>
    </li>
  );
}
export default ProductbarItem;
