import React, { useState } from "react";
import BannerImage from "components/BannerImage";
import PhoneCard from "components/PhoneCard";
import PhoneGrid from "components/PhoneGrid";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {},
    containedComponent: {
      maxWidth: 1272,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0px 20px 0px 20px",
      boxSizing: "border-box"
    }
  };
});

const description =
  "Shop > Mobile\n\n# Phones\n\nNeed a new phone or an upgrade? Mornington has you covered.\n Check out our wide selection of phones from brands you trust.";
const familyDescription =
  "Mornington Deals\n\n### Bundle And Save!\n\nBundle discounts are available on qualified wireless, internet, TV and web hosting packages in select service areas.";
function Home(props) {
  const classes = useStyles();
  const history = useHistory();
  let path = history.location.pathname;
  let urlTail = path.split("/").filter(function (item) {
    return item;
  });
  let urlHead = urlTail.reverse().shift();
  urlTail.reverse();
  urlTail = urlTail.join(" > ");

  // const { loading, error, data } = useQuery(GET_PRODUCTS);

  // if (loading) return "Loading...";
  // if (error) return `Error! ${error.message}`;
  // //console.log(data);

  return (
    <>
      <div className={classes.root}>
        <BannerImage
          topText={urlHead}
          title={urlTail}
          markdown={description}
          imageUrl={"/assets/images/girl-on-cell-phone.png"}
          leftAlign={false}
        />
        <PhoneGrid Renderer={PhoneCard} />
        <BannerImage
          topText={"Mornington Deals"}
          header={true}
          headerText={"Looking for more than just phones?"}
          title={"Bundle and save!"}
          markdown={familyDescription}
          imageUrl={"/assets/images/family-on-devices.png"}
          ctaButton
          ctaButtonProps={{
            children: "Get yours now",
            onClick: function () {
              history.push("/bundle-and-save");
            }
          }}
          gradient
          leftAlign={true}
        />
      </div>
    </>
  );
}

export default Home;
