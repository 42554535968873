import gql from "graphql-tag";
import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import View from "./view";
import Skeleton from "components/Spinner";
const GET_IRCS = gql`
  query getICRs($where: JSON, $start: Int) {
    icrs {
      Main_banner {
        body
        image {
          src {
            url
          }
        }
      }

      Ads {
        title
        body
        button_text
        images {
          image_title
          src {
            url
          }
          alt_text
          type
        }
      }
    }

    callingRates(where: $where) {
      country
      planPrice: rate
      provider
    }

    countries(start: $start) {
      name
    }
  }
`;

function GraphContainer() {
  const [location, setLocation] = useState("");
  const locationFilter = { country: location };
  const { loading, error, data } = useQuery(GET_IRCS, {
    variables: {
      where: locationFilter
    }
  });

  return loading || error ? (
    <Skeleton />
  ) : (
    <View
      location={location}
      setLocation={function(str) {
        setLocation(str);
      }}
      data={{
        ...data.icrs[0],
        calling_rates: data.callingRates,
        countries: data.countries
      }}
    ></View>
  );
}

export default GraphContainer;
