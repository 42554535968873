import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import PaginationGridButton from "../PaginationGridButton";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      borderRadius: 4,
      display: "inline-block",
      border: "1px solid #CCCCCC",
      overflow: "hidden",
      "& > :not(:last-child)": {
        borderRight: "1px solid #CCCCCC"
      },
      display: "flex"
    }
  };
});

function PaginationGridButtonBar(props) {
  const classes = useStyles(props);
  const { buttons } = props;
  return (
    <div className={classes.root}>
      {buttons.map(function(button) {
        return <PaginationGridButton {...button} />;
      })}
    </div>
  );
}
PaginationGridButtonBar.defaultProps = {
  buttons: []
};

PaginationGridButtonBar.propTypes = {
  buttons: PropTypes.array
};

export default PaginationGridButtonBar;
