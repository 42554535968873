import { useQuery } from "@apollo/react-hooks";
import Skeleton from "components/Spinner";
import gql from "graphql-tag";
import useLocation from "hooks/useLocation";
import React from "react";
import View from "./view";

const GET_TAB = gql`
  query morningtonTabs {
    content: morningtonTabs {
      mainBanner: Banner {
        body
        button_text
        image {
          src {
            url
          }
          image_title
          alt_text
        }
      }

      Full_Text_Row: Full_text_row {
        body
      }
    }
  }
`;

function GraphContainer() {
  const { location, setLocation } = useLocation();
  const locationFilter = { locations: { name: location } };
  const { loading, error, data } = useQuery(GET_TAB, {
    variables: { where: locationFilter }
  });

  //console.log("GQL", data);
  return loading || error ? (
    <Skeleton />
  ) : (
    <View
      location={location || ""}
      setLocation={setLocation}
      data={data.content[0]}
    ></View>
  );
}

export default GraphContainer;