import React from "react";
import Markdown from "components/Markdown";
import Typography from "components/Typography";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      maxWidth: 900,
      marginRight: "auto",
      marginLeft: "auto"
      // overflow: "scroll"
    }
  };
});

function View(props) {
  const classes = useStyles(props);

  const { title, hasTitle } = props;
  return (
    <div>
      {hasTitle && (
        <h2>
          <Typography variant="heading1">{title}</Typography>
        </h2>
      )}
      <article className={classes.root}>
        <Markdown escapeHtml={false}>{props.children}</Markdown>
      </article>
    </div>
  );
}
export default View;
